import React, { Fragment, useEffect } from 'react';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import LineChart from '../Line/LineChart';
import GraphTopContent from '../Common/GraphTopContent';
import { NoRecordsFound, NoGraphsAvailable } from '../../NoRecordsFound';
import LoadingSpinner from '../../../UI/LoadingSpinner';

import {
  getHeatmapData,
  unifyArrays as unifyData,
  setHeatmapXMargins,
  uniqueIdentViewsVariables,
  getIsLineChart,
} from './HeatmapHelpers';

import { getChartAxisLabel } from '../Common/HeatmapLineHelpers';

import useWindowsDimensions from '../../../../Hooks/UseWindowsDimensions';
import useHeatmapChart from '../../graphHooks/UseHeatmapChart';

import html2canvas from 'html2canvas';

function HeatmapTooltip({ cell }) {
  const tooltipId = `${cell.serieId}, ${cell.data.x}`;
  let relatedGenes = null;

  if (typeof cell.data.genes !== 'string') {
    relatedGenes = cell.data.genes ? `${cell.data.genes.join(', ')}` : 'None';
  }

  const variableNameMap = new Map([
    ['score', 'Score'],
    ['numPMIDsupportingAssociation', 'N. Publications'],
    ['EI', 'Evidence Index'],
    ['jaccard_genes', 'Jacccard Index Genes'],
    ['jaccard_variants', 'Jacccard Index Variants'],
    ['shared_genes', 'N. Shared Genes'],
    ['shared_variants', 'N. Shared Variants'],
  ]);

  return (
    <div className="custom-tooltip-heatmap">
      <div style={{ backgroundColor: cell.color }} className="score-color"></div>
      <span>
        <em>{tooltipId}</em>
      </span>
      <br />
      <span>
        <em>{variableNameMap.get(cell.data.chartVar)}:</em> <strong>{cell.formattedValue}</strong>
      </span>
      <br />
      {relatedGenes && (
        <span>
          <em>Gene:</em> <strong>{relatedGenes}</strong>
        </span>
      )}
    </div>
  );
}

// /api/gda/summary?source=ALL&disease=UMLS_C0002395,UMLS_C0006142&gene_ncbi_id=&page_number=33&page_size=100&order_by=undefined&order=desc

function HeatmapChart({
  source,
  keySearch,
  view,
  names,
  chartInfo,
  setChartInfo,
  baseUrl,
  parent,
  heatmapSearchVariables,
}) {
  // Data necessary for using the custom hook
  // pageSize for the request
  const pageSize = parent === 'DDA' ? 50 : 100;

  // whether the top axis represents diseases or not
  const isTopAxisDiseases =
    (parent === 'GDA' && view === 'GENES') || (parent === 'VDA' && view === 'VARIANTS') || parent === 'DDA';

  // current width
  const { width } = useWindowsDimensions();

  // empty array as the initial data for the chart
  const initialChartData = [];

  // calling the useRequestMultipleFilters hook
  const {
    chartData,
    isLoading,
    isPayloadEmpty,
    legendValues,
    height: heatmapHeight,
    identReqParam,
    xMargin,
    setXMargin,
  } = useHeatmapChart({
    chartInfo,
    initialChartData,
    keySearch,
    source,
    baseUrl,
    parent,
    pageSize,
    view,
    getHeatmapData,
    unifyData,
    width,
    isTopAxisDiseases,
    uniqueIdentViewsVariables,
  });

  // true if the data got from the hook only has a length of 1
  const isLineChart = getIsLineChart(chartData);
  const isChartDataEmpty = chartData.length === 0 ? true : false;

  // adapt the top margin depending on whether the top axis represents diseases or not
  const topMargin = isTopAxisDiseases ? 180 : 110;

  // array of the types of value represented in the heatmap
  const comparedValues = uniqueIdentViewsVariables[parent]?.comparedValues?.[view];

  // adjust the X margins when the width changes
  useEffect(() => {
    if (identReqParam) {
      setXMargin(() => setHeatmapXMargins(width, identReqParam));
    }
  }, [width]);

  function downloadJSON() {
    const JSONcomplete = JSON.stringify(chartData);
    const jsonURL = `data:text/json;charset=utf-8,${encodeURIComponent(JSONcomplete)}`;

    const link = document.createElement('a');
    link.href = jsonURL;
    link.download = 'HEATMAP.JSON';
    link.click();
  }

  const downloadPNG = () => {
    const chartContainer = document.querySelector('.row-graph');
    if (chartContainer) {
      html2canvas(chartContainer).then(function (canvas) {
        const link = document.createElement('a');
        link.download = 'chart.png';
        link.href = canvas.toDataURL('image/png');
        link.click();
      });
    }
  };

  return (
    <section>
      <countainer class="container">
        {isLoading && (
          <div className="row">
            {' '}
            <LoadingSpinner />
          </div>
        )}
        {!isPayloadEmpty && !isLoading && (
          <Fragment>
            {!isLineChart && !isChartDataEmpty && (
              <Fragment>
                <GraphTopContent
                  graphSearchVariables={heatmapSearchVariables}
                  chartInfo={chartInfo}
                  setChartInfo={setChartInfo}
                  parent={parent}
                  resultsNumber={100}
                />
                <div class="container d-flex justify-content-end" style={{ width: '110%' }}>
                  <button
                    class="bg-blue-200 hover:bg-blue-300 text-gray-800 font-bold py-1 px-2 rounded inline-flex items-center"
                    onClick={downloadPNG}
                  >
                    <svg
                      class="fill-current w-3 h-3 mr-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                    </svg>
                    <span class="text-sm">Download PNG</span>
                  </button>

                  <button
                    class="bg-blue-200 hover:bg-blue-300 text-gray-800 font-bold py-1 px-2 rounded inline-flex items-center ml-2"
                    onClick={() => downloadJSON()}
                  >
                    <svg
                      class="fill-current w-3 h-3 mr-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                    </svg>
                    Download JSON
                  </button>
                </div>
                <div className={`row-graph`} style={{ minHeight: heatmapHeight, height: heatmapHeight }}>
                  <ResponsiveHeatMap
                    data={chartData}
                    width={width * 0.8}
                    height={heatmapHeight}
                    margin={{
                      top: topMargin,
                      right: xMargin + 110,
                      bottom: 80,
                      left: xMargin - 50,
                    }}
                    axisTop={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: -80,
                      legend: '',
                      legendOffset: -65,
                      renderTick: tickProperties => {
                        const labelClassNames = 'axisLegend verticalTopLegend';
                        const sliceValue = 21;
                        return getChartAxisLabel({
                          ...tickProperties,
                          labelClassNames,
                          sliceValue,
                        });
                      },
                    }}
                    axisRight={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: '',
                      renderTick: tickProperties => {
                        const labelClassNames = 'axisLegend';
                        const sliceValue = 21;
                        return getChartAxisLabel({
                          ...tickProperties,
                          labelClassNames,
                          sliceValue,
                        });
                      },
                    }}
                    axisLeft={null}
                    axisBottom={null}
                    colors={{
                      type: 'diverging',
                      scheme: 'blues',
                      divergeAt: legendValues.divergeAt,
                      minValue: legendValues.min,
                      maxValue: legendValues.max,
                    }}
                    enableLabels={false}
                    emptyColor="#555555"
                    tooltip={HeatmapTooltip}
                    legends={[
                      {
                        anchor: 'bottom',
                        translateX: 0,
                        translateY: 60,
                        length: 400,
                        thickness: 10,
                        direction: 'row',
                        tickPosition: 'after',
                        tickSize: 3,
                        tickSpacing: 4,
                        tickOverlap: false,
                        title: 'Value →',
                        titleAlign: 'start',
                        titleOffset: 4,
                      },
                    ]}
                  />
                </div>
                <div className="additional-notes">
                  <div>
                    <p className="centered-paragraph">
                      <b>NOTE:</b> White spaces represent no association between the {comparedValues[0]} and
                      the {comparedValues[1]}
                    </p>
                    {parent === 'VDA' && (
                      <div>
                        <p className="centered-paragraph">
                          <b>IMPORTANT:</b> You can view the genes associated to the variant in the tooltip of
                          each cell
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </Fragment>
            )}
            {isChartDataEmpty && (
              <Fragment>
                <GraphTopContent
                  graphSearchVariables={heatmapSearchVariables}
                  chartInfo={chartInfo}
                  setChartInfo={setChartInfo}
                  parent={parent}
                  resultsNumber={0}
                />
                <NoGraphsAvailable />
              </Fragment>
            )}
            {isLineChart && (
              <LineChart
                source={source}
                keySearch={keySearch}
                view={view}
                names={names}
                chartInfo={chartInfo}
                setChartInfo={setChartInfo}
                baseUrl={baseUrl}
                parent={parent}
                lineSearchVariables={[...heatmapSearchVariables]}
              />
            )}
          </Fragment>
        )}
        {isPayloadEmpty && !isLoading && (
          <div className="row">
            {' '}
            <NoRecordsFound />
          </div>
        )}
      </countainer>
    </section>
  );
}

export default HeatmapChart;
