import React, { Component } from 'react';

class DownloadDump extends Component {
  render() {
    return (
      <iframe
        id="iFrameDownload"
        src={`${process.env.REACT_APP_URL_API_API}dump`}
        style={{ width: '100%', height: '225px' }}
      />
    );
  }
}

export default DownloadDump;
