import { useState } from 'react';

function Feature2Home() {
  const [showChallenge, setShowChallenge] = useState(false);
  const [showSolution, setShowSolution] = useState(false);
  const [showBenefits, setShowBenefits] = useState(false);

  const toggleSection = section => {
    if (section === 'challenge') {
      setShowChallenge(!showChallenge);
    } else if (section === 'solution') {
      setShowSolution(!showSolution);
    } else if (section === 'benefits') {
      setShowBenefits(!showBenefits);
    }
  };

  return (
    <section>
      <div className="p-2">
        <h3 className="custom-home-h3 gradient-color">
          DISGENET integrates all genes and genomic variants information into a single resource
        </h3>
        <div className="box-home-div">
          <div className="box-home benefits-background">
            <div className="pb-4">
              <h4 className="">Use case: Unlock precision medicine opportunities</h4>
              <h6>
                Sequencing an individual's genome can produce a vast amount of data, with millions of genomic
                variants identified. However, pinpointing which of these variations actually contribute to
                disease remains a major hurdle in precision medicine.
              </h6>
            </div>
            <div>
              <div
                onClick={() => toggleSection('challenge')}
                className="px-3"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <img
                  src="../../../static/images/icons/iconsPink/05.svg"
                  style={{ width: '75px', marginRight: '10px' }}
                />
                <h5>Challenge</h5>
                <i className="px-4 bi bi-chevron-down" style={{ marginLeft: 'auto' }}></i>
              </div>
              {showChallenge && (
                <div onClick={() => toggleSection('challenge')} className="px-4">
                  <div className="px-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src="../../../static/images/icons/iconsPink/050.svg"
                      style={{ width: '75px', marginRight: '5px' }}
                    />
                    <ul style={{ marginBottom: '-5px' }}>
                      <li className="bullet-item">
                        <strong>Too Much Data:</strong> Accurately assessing variants involves investigating a
                        multitude of data sources, including scientific publications, demanding extensive time
                        and expertise from researchers.
                      </li>
                    </ul>
                  </div>
                  <div className="px-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src="../../../static/images/icons/iconsPink/035.svg"
                      style={{ width: '75px', marginRight: '5px' }}
                    />
                    <ul style={{ marginBottom: '-5px' }}>
                      <li className="bullet-item">
                        <strong>Keeping Up with Discoveries:</strong> New scientific discoveries are
                        constantly published, requiring continuous re-evaluation of variants based on the
                        latest findings from both pre-clinical and clinical studies.
                      </li>
                    </ul>
                  </div>
                  <div className="px-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src="../../../static/images/icons/iconsPink/054.svg"
                      style={{ width: '75px', marginRight: '5px' }}
                    />
                    <ul style={{ marginBottom: '-5px' }}>
                      <li className="bullet-item">
                        <strong> Accuracy and Efficiency:</strong> Developing bioinformatic tools capable of
                        automating various steps in the variant assessment process is essential for timely and
                        accurate analysis.
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              <hr />
              <div
                onClick={() => toggleSection('solution')}
                className="px-3"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <img
                  src="../../../static/images/icons/iconsPink/03.svg"
                  style={{ width: '75px', marginRight: '10px' }}
                />
                <h5>Solution</h5>
                <i className="px-4 bi bi-chevron-down" style={{ marginLeft: 'auto' }}></i>
              </div>
              {showSolution && (
                <div onClick={() => toggleSection('solution')} className="p-4">
                  <p>
                    Our solution leverages artificial intelligence and machine learning to automate and
                    streamline variant interpretation, a critical bottleneck in precision medicine.
                  </p>
                  <div className="px-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src="../../../static/images/icons/iconsPink/046.svg"
                      style={{ width: '75px', marginRight: '5px' }}
                    />
                    <ul style={{ marginBottom: '-5px' }}>
                      <li className="bullet-item">
                        <strong> Centralized Knowledge Base:</strong>DISGENET integrates information into a
                        single, comprehensive resource eliminating the need to search through multiple
                        sources.
                      </li>
                    </ul>
                  </div>
                  <div className="px-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src="../../../static/images/icons/iconsPink/011.svg"
                      style={{ width: '75px', marginRight: '5px' }}
                    />
                    <ul style={{ marginBottom: '-5px' }}>
                      <li className="bullet-item">
                        <strong>Automated Analysis:</strong> Our REST API to automates data analysis
                        workflows, allowing you to extract and analyze relevant data programmatically.
                      </li>
                    </ul>
                  </div>
                  <div className="px-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src="../../../static/images/icons/iconsPink/06.svg"
                      style={{ width: '75px', marginRight: '5px' }}
                    />
                    <ul style={{ marginBottom: '-5px' }}>
                      <li className="bullet-item">
                        <strong>AI-powered Insights:</strong> Advanced machine learning models uncover
                        connections and identify complex relationships between variants and disease
                        phenotypes, which are not apparent through traditional methods.
                      </li>
                    </ul>
                  </div>
                  <div className="px-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src="../../../static/images/icons/iconsPink/051.svg"
                      style={{ width: '75px', marginRight: '5px' }}
                    />
                    <ul style={{ marginBottom: '-5px' }}>
                      <li className="bullet-item">
                        <strong>Continuous Learning:</strong> Our platform incorporates the latest scientific
                        findings from ongoing research to continuously update variant interpretations.
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              <hr />
              <div
                onClick={() => toggleSection('benefits')}
                className="px-3"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <img
                  src="../../../static/images/icons/iconsPink/04.svg"
                  style={{ width: '75px', marginRight: '10px' }}
                />
                <h5>Benefits</h5>
                <i className="px-4 bi bi-chevron-down" style={{ marginLeft: 'auto' }}></i>
              </div>
              {showBenefits && (
                <div onClick={() => toggleSection('benefits')} className="p-4">
                  <div className="px-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src="../../../static/images/icons/iconsPink/057.svg"
                      style={{ width: '75px', marginRight: '5px' }}
                    />
                    <ul style={{ marginBottom: '-5px' }}>
                      <li className="bullet-item">
                        <strong> Streamline the interpretation of genomic variants</strong>
                      </li>
                    </ul>
                  </div>
                  <div className="px-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src="../../../static/images/icons/iconsPink/034.svg"
                      style={{ width: '75px', marginRight: '5px' }}
                    />
                    <ul style={{ marginBottom: '-5px' }}>
                      <li className="bullet-item">
                        <strong>Focus resources on the most impactful targets</strong>
                      </li>
                    </ul>
                  </div>
                  <div className="px-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src="../../../static/images/icons/iconsPink/Diseases/020.svg"
                      style={{ width: '75px', marginRight: '5px' }}
                    />
                    <ul style={{ marginBottom: '-5px' }}>
                      <li className="bullet-item">
                        <strong>Improved patient outcomes</strong>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4">
        <h3 className="custom-home-h3 gradient-color">
          DISGENET is a knowledge database that covers the full spectrum of human diseases so you can:
        </h3>
        <div className="col-10">
          <div className="p-3" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="../../../static/images/icons/iconsPink/049.svg"
              style={{ width: '75px', marginRight: '10px' }}
            />
            <h5>Streamline Your R&D Process</h5>
          </div>
          <h6 className="px-4">Expedite the search for relevant information</h6>
          <p className="px-4">
            DISGENET's cutting-edge AI and NLP technology extracts the most relevant information with
            exceptional accuracy, saving you time and resources during the crucial early stages of drug
            discovery.
          </p>
        </div>

        <div className="col-10">
          <div className="p-3" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="../../../static/images/icons/iconsPink/032.svg"
              style={{ width: '75px', marginRight: '10px' }}
            />
            <h5>Identify Novel Drug Targets</h5>
          </div>
          <h6 className="px-4">Leverage unseen connections</h6>
          <p className="px-4">
            DISGENET enables you to identify novel drug targets by uncovering previously hidden connections.
            Explore promising new avenues for therapeutic interventions and accelerate the development of
            life-saving treatments.
          </p>
        </div>
        <div className="col-10">
          <div className="p-3" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="../../../static/images/icons/iconsPink/045.svg"
              style={{ width: '75px', marginRight: '10px' }}
            />
            <h5>Drive Precision Medicine Forward</h5>
          </div>
          <h6 className="px-4">Advance personalized treatments</h6>
          <p className="px-4">
            DISGENET's standardized data integration unlocks new possibilities in precision medicine. Develop
            more effective and targeted therapies tailored to specific patient populations based on their
            unique genetic makeup.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Feature2Home;
