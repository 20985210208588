import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Filas from './Filas';

export default function DetailDisgenet({ values, vocabulary }) {
  const infoCardValues = vocabularyValue => {
    let value = '';
    const diseaseClasses = values.diseaseClasses;

    for (let i = 0; i < diseaseClasses.length; i++) {
      if (diseaseClasses[i].vocabulary == vocabularyValue)
        if (value != '') value += '; ' + diseaseClasses[i].name;
        else value += diseaseClasses[i].name;
    }
    return value;
  };

  const getMSH = infoCardValues('MSH');
  const getSemantic = infoCardValues('UMLS_ST');
  const getPhenotypes = infoCardValues('HPO');
  const getOntology = infoCardValues('DO');

  return (
    <Tabs>
      <TabList className="menuTabs">
        <Tab className="react-tabs__tab">General Info</Tab>
        <Tab className="react-tabs__tab">Vocabulary</Tab>
        <Tab className="react-tabs__tab">Synonyms</Tab>
      </TabList>

      <TabPanel className="tab-pane p-2">
        <table className="table">
          <tbody>
            <tr>
              <td className="text-right">
                <strong>Name:</strong>
              </td>
              <td>{values.name}</td>
            </tr>
            <tr>
              <td className="text-right">
                <strong>Type:</strong>
              </td>
              <td>{values.type}</td>
            </tr>
            <tr>
              <td className="text-right">
                <strong>MeSH Class:</strong>
              </td>
              <td> {getMSH}</td>
            </tr>
            <tr>
              <td className="text-right">
                <strong>Semantic Type:</strong>
              </td>
              <td>{getSemantic}</td>
            </tr>
            <tr>
              <td className="text-right">
                <strong>Phenotypes:</strong>
              </td>
              <td>{getPhenotypes}</td>
            </tr>
            <tr>
              <td className="text-right">
                <strong>Disease Ontology:</strong>
              </td>
              <td>{getOntology}</td>
            </tr>
          </tbody>
        </table>
      </TabPanel>

      <TabPanel className="tab-pane p-2">
        <table className="table">
          <tbody>
            {vocabulary.map((resultado, index) => {
              return (
                <Filas key={`${resultado}${index}`} resultado={resultado} lista={values.diseaseCodes}></Filas>
              );
            })}
          </tbody>
        </table>
      </TabPanel>

      <TabPanel className="tab-pane p-2">
        <table className="table">
          <tbody>
            {values.synonyms.map((synonym, index) => {
              return (
                <tr key={`DetailDisgenet${index}`}>
                  <td className="col-md-6">{synonym.name}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </TabPanel>
    </Tabs>
  );
}
