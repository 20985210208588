import React, { Fragment } from 'react';

const vocabulariesLinks = {
  ICD10: 'https://icd.codes/icd10cm/',
  MSH: 'https://meshb.nlm.nih.gov/record/ui?ui=',
  HPO: 'https://hpo.jax.org/app/browse/term/',
  NCI: 'https://ncit.nci.nih.gov/ncitbrowser/ConceptReport.jsp?dictionary=NCI_Thesaurus&ns=ncit&code=',
  OMIM: 'https://www.omim.org/entry/',
  ICD9CM: 'https://icd.codes/icd9cm/',
  DO: 'https://disease-ontology.org/term/DOID:',
  MONDO: 'https://www.ebi.ac.uk/ols/ontologies/mondo/terms?iri=http://purl.obolibrary.org/obo/MONDO_',
  EFO: 'https://www.ebi.ac.uk/ols/ontologies/efo/terms?short_form=EFO_',
};

function Filas({ resultado, lista, type = 'Disease' }) {
  let i = 0;
  return (
    <Fragment key={`${resultado}${type}${i}`}>
      {resultado === 'UMLS'
        ? null
        : lista.map((value, index) => {
            let link;
            if (type === 'Disease') {
              const cleanedUpValueCode = value.code.replaceAll('.', '');
              link = `${vocabulariesLinks[resultado]}${cleanedUpValueCode}`;
            }
            if (resultado === value.vocabulary) {
              i++;
            }
            return (
              <>
                {resultado === value.vocabulary && (
                  <tr key={`${value.code}${index}`} className={i !== 1 ? 'FilaSinBorde' : null}>
                    {i === 1 ? <td>{resultado}:</td> : <td />}
                    <td>
                      {type === 'Disease' ? (
                        <a href={link} className="mapId" target="_blank" rel="noreferrer">
                          {value.code}
                        </a>
                      ) : (
                        <span>{value.code}</span>
                      )}
                    </td>
                    <td>
                      <span>{value.name}</span>
                    </td>
                  </tr>
                )}
              </>
            );
          })}
    </Fragment>
  );
}

export default Filas;
