import React from 'react';
import CommonHeader from '../Components/Headers/CommonHeader';
import Login from '../Components/Search/Login';
import Footer from '../Components/Home/Footer';

export default function LoginPage() {
  return (
    <>
      <CommonHeader />
      <div>
        <Login />
      </div>
      <Footer />
    </>
  );
}
