import React from 'react';

function NoRecordsFound() {
  return (
    <section className="col-12 no-records">
      <h1>No records found</h1>
      <p>Please, change your chosen parameters to make a new request</p>
    </section>
  );
}

function NoGraphsAvailable() {
  return (
    <section className="col-12 no-records">
      <h1>Currently, there are no graphs to show in this page</h1>
      <p>Please, try with another view and another request</p>
    </section>
  );
}

export { NoRecordsFound, NoGraphsAvailable };
