import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

export default function DetailVariant({ values, vocabulary }) {
  return (
    <Tabs id="table-scroll">
      <TabList className="menuTabs">
        <Tab className="react-tabs__tab">General Info</Tab>
      </TabList>

      <TabPanel className="tab-pane p-2">
        <table className="table">
          <tbody>
            <tr>
              <td className="text-right">
                <strong>SNP Identifier: </strong>
              </td>
              <td>{values.strID}</td>
            </tr>
            <tr>
              <td className="text-right">
                <strong>Class: </strong>
              </td>
              <td>{values.dbsnpclass}</td>
            </tr>
            <tr>
              <td className="text-right">
                <strong>Gene Symbol: </strong>
              </td>
              <td>
                {values.variantToGenes.map(
                  (value, index, array) => ` ${value.geneSymbol} ${index === array.length - 1 ? '' : ';'}`,
                )}
              </td>
            </tr>
            <tr>
              <td className="text-right">
                <strong>Chromosome: </strong>
              </td>
              <td>{values.chromosome}</td>
            </tr>
            <tr>
              <td className="text-right">
                <strong>Consequence: </strong>
              </td>
              <td>{values.mostSevereConsequences}</td>
            </tr>
            <tr>
              <td className="text-right">
                <strong>DPI: </strong>
              </td>
              <td>{typeof values.DPI === 'number' ? values.DPI?.toFixed(2) : ''}</td>
            </tr>
            <tr>
              <td className="text-right">
                <strong>DSI: </strong>
              </td>
              <td>{typeof values.DSI === 'number' ? values.DSI?.toFixed(2) : ''}</td>
            </tr>
            <tr>
              <td className="text-right">
                <strong>Amino acid change: </strong>
              </td>
              <td>
                {/* fran */}
                {values.threeletterID.map(
                  (value, index, array) => ` ${value} ${index === array.length - 1 ? '' : ';'}`,
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </TabPanel>
    </Tabs>
  );
}
