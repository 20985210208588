import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

class Nav extends React.Component {
  render() {
    return (
      <nav id="cd-vertical-nav">
        <ul className="list-unstyled mt-5 horizontal-menu">
          <li className="nav-item">
            <Link smooth to="/FAQ#section2">
              <span className="cd-title">
                1. Can I incorporate DISGENET data in other products that I will redistribute/resell?
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link smooth to="/FAQ#section3">
              <span className="cd-title">
                2. Can I use DISGENET data for research and development within my company?
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link smooth to="/FAQ#section4">
              <span className="cd-title">3. How can I access DISGENET?</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link smooth to="/FAQ#section5">
              <span className="cd-title">4. Can I test the product before acquiring it?</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link smooth to="/FAQ#section6">
              <span className="cd-title">5. In which languages do you offer the customer support?</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link smooth to="/FAQ#section7">
              <span className="cd-title">
                6. When and for how long do you provide permission to use the database once my organization
                acquires it?
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link smooth to="/FAQ#section8">
              <span className="cd-title">
                7. After the year is passed, can my organization keep using the data?
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link smooth to="/FAQ#section9">
              <span className="cd-title">
                8. Do you provide tailored services? Can I propose you to identify data on a particular
                disease?
              </span>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Nav;
