import React from 'react';
import { useNavigate } from 'react-router-dom';

function BrowserButton() {
  const navigate = useNavigate();

  const submitButton = evt => {
    evt.preventDefault();
    navigate({
      pathname: '/search',
      search: '?view=ALL&idents=ALL&source=ALL&tab=GDA',
    });
  };

  return (
    <div className="browser">
      <button className="browser-button" onClick={submitButton}>
        BROWSER
      </button>
    </div>
  );
}

export default BrowserButton;
