import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

class Nav extends React.Component {
  render() {
    return (
      <nav id="cd-vertical-nav">
        <ul className="list-unstyled mt-4 horizontal-menu">
          <li className="nav-item">
            <Link smooth to="/Cookies-policy#section1">
              <span className="cd-title">1. What is a cookie?</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link smooth to="/Cookies-policy#section2">
              <span className="cd-title">2. How we use cookies</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link smooth to="/Cookies-policy#section3">
              <span className="cd-title">3. Which type of cookies uses the Website?</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link smooth to="/Cookies-policy#section4">
              <span className="cd-title">4. Is it possible to disable the use of cookies?</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link smooth to="/Cookies-policy#section5">
              <span className="cd-title">5. Cookies table</span>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Nav;
