const Filters = [
  { name: 'symbol', operator: 'startsWith', type: 'string' },
  { name: 'geneSymbol', operator: 'startsWith', type: 'string' },
  { name: 'geneNcbiID', operator: 'startsWith', type: 'string' },
  { name: 'diseaseUMLSCUI', operator: 'contains', type: 'string' },
  { name: 'pathwayName', operator: 'startsWith', type: 'string' },
  { name: 'pathwayID', operator: 'startsWith', type: 'string' },
  { name: 'score', operator: 'eq', type: 'number' },
  { name: 'EL', operator: 'eq', type: 'select' },
  { name: 'EI', operator: 'eq', type: 'number' },
  { name: 'geneNcbiType', operator: 'eq', type: 'select' },
  { name: 'numCTs', operator: 'eq', type: 'number' },
  {
    name: 'numPMIDsupportingAssociation',
    operator: 'eq',
    type: 'number',
    integer: true,
  },
  {
    name: 'numDBSNPsupportingAssociation',
    operator: 'eq',
    type: 'number',
    integer: true,
  },
  {
    name: 'yearInitial',
    operator: 'eq',
    type: 'number',
    integer: true,
  },
  {
    name: 'yearFinal',
    operator: 'eq',
    type: 'number',
    integer: true,
  },
  { name: 'diseaseName', operator: 'startsWith', type: 'string' },
  {
    name: 'geneDescription',
    operator: 'contains',
    type: 'string',
    dir: '',
  },
  {
    name: 'geneProteinClassNames',
    operator: 'eq',
    type: 'select',
  },
  {
    name: 'numDiseasesAssociatedToGene',
    operator: 'eq',
    type: 'number',
    integer: true,
  },
  {
    name: 'numVariantsAssociatedToGene',
    operator: 'eq',
    type: 'number',
    integer: true,
  },
  { name: 'geneDSI', operator: 'eq', type: 'number' },
  { name: 'geneDPI', operator: 'eq', type: 'number' },
  { name: 'genepLI', operator: 'eq', type: 'number' },
  { name: 'geneEnsemblIDs', operator: 'contains', type: 'string', disable: true }, // Not working in legacy
  { name: 'diseaseType', operator: 'eq', type: 'select' },
  { name: 'diseaseClassName_MSH', operator: 'eq', type: 'select' },
  {
    name: 'diseaseClassName_UMLS',
    operator: 'eq',
    type: 'select',
  },
  { name: 'diseaseClassName_DO', operator: 'eq', type: 'select' },
  { name: 'diseaseClassName_HPO', operator: 'eq', type: 'select' },
  {
    name: 'numGenesAssociatedToDisease',
    operator: 'eq',
    type: 'number',
    integer: true,
  },
  {
    name: 'numVariantsAssociatedToDisease',
    operator: 'eq',
    type: 'number',
    integer: true,
  },
  { name: 'geneProteinStrIDs', operator: 'contains', type: 'string' },
  { name: 'disease1_Name', operator: 'contains', type: 'string' },
  { name: 'disease2_Name', operator: 'contains', type: 'string' },
  { name: 'ngenes_diseaseID_1', operator: 'eq', type: 'number' },
  { name: 'nvariants_diseaseID_1', operator: 'eq', type: 'number' },
  { name: 'disease1ClassName_DO', operator: 'eq', type: 'select' },
  { name: 'disease1ClassName_HPO', operator: 'eq', type: 'select' },
  { name: 'disease1ClassName_MSH', operator: 'eq', type: 'select' },
  { name: 'disease1ClassName_UMLS', operator: 'eq', type: 'select' },
  { name: 'disease1_Type', operator: 'eq', type: 'select' },
  { name: 'disease2ClassName_DO', operator: 'eq', type: 'select' },
  { name: 'disease2ClassName_HPO', operator: 'eq', type: 'select' },
  { name: 'disease2ClassName_MSH', operator: 'eq', type: 'select' },
  { name: 'disease2ClassName_UMLS', operator: 'eq', type: 'select' },
  { name: 'disease2_Type', operator: 'eq', type: 'select' },
  { name: 'ngenes_diseaseID_2', operator: 'eq', type: 'number' },
  { name: 'nvariants_diseaseID_2', operator: 'eq', type: 'number' },
  { name: 'shared_genes', operator: 'eq', type: 'number' },
  { name: 'jaccard_genes', operator: 'eq', type: 'number' },
  { name: 'pvalue_jaccard_genes', operator: 'eq', type: 'number' },
  { name: 'shared_variants', operator: 'eq', type: 'number' },
  { name: 'jaccard_variants', operator: 'eq', type: 'number' },
  { name: 'pvalue_jaccard_variants', operator: 'eq', type: 'number' },
  { name: 'sokal', operator: 'eq', type: 'number' },
  { name: 'ddaRelation', operator: 'eq', type: 'select' },
  { name: 'name', operator: 'startsWith', type: 'string' },
  { name: 'type', operator: 'eq', type: 'select' },
  { name: 'nct_id', operator: 'startsWith', type: 'string' },
  { name: 'firstRef', operator: 'eq', type: 'number' },
  { name: 'lastRef', operator: 'eq', type: 'number' },
  { name: 'numPublications', operator: 'eq', type: 'number', integer: true },
  { name: 'numPublications_GDA', operator: 'eq', type: 'number', integer: true },
  { name: 'numPublications_VDA', operator: 'eq', type: 'number', integer: true },
  { name: 'chemUMLSCUI', operator: 'startsWith', type: 'string' },
  { name: 'chemShowName', operator: 'startsWith', type: 'string' },
  { name: 'chemNumPublications', operator: 'eq', type: 'number', integer: true },
  { name: 'chemNumGDAs', operator: 'eq', type: 'number', integer: true },
  { name: 'chemNumPublicationsGDAs', operator: 'eq', type: 'number', integer: true },
  { name: 'chemNumVDAs', operator: 'eq', type: 'number', integer: true },
  { name: 'chemNumPublicationsVDAs', operator: 'eq', type: 'number', integer: true },
  { name: 'associationType', operator: 'eq', type: 'select' },
  { name: 'sentenceHTML', operator: 'contains', type: 'string' },
  { name: 'pmid', operator: 'eq', type: 'number' }, //todo: int?
  { name: 'pmYear', operator: 'eq', type: 'number', integer: true },
  { name: 'journal_abbrv', operator: 'startsWith', type: 'string' },
  { name: 'chemUMLSCUIs', operator: 'startsWith', type: 'string' },
  { name: 'variantStrID', operator: 'startsWith', type: 'string' },
  { name: 'geneNcbiIDs', operator: 'startsWith', type: 'string' },
  { name: 'variantDSI', operator: 'eq', type: 'number' },
  { name: 'variantDPI', operator: 'eq', type: 'number' },
  {
    name: 'mostSevereConsequences',
    operator: 'contains',
    type: 'string',
  },
  {
    name: 'numDiseasesAssociatedToVariant',
    operator: 'eq',
    type: 'number',
    integer: true,
  },
  { name: 'strID', operator: 'startsWith', type: 'string' },
  { name: 'DSI', operator: 'eq', type: 'number' },
  { name: 'chromosome', operator: 'eq', type: 'string' },
  { name: 'DPI', operator: 'eq', type: 'number' },
  { name: 'DBSNPclass', operator: 'eq', type: 'select' },
  { name: 'alleles', operator: 'contains', type: 'string', disable: true }, // migrated disabled state from disgenetplus
  { name: 'gof_lof', operator: 'eq', type: 'select' },
  { name: 'riskAllele', operator: 'eq', type: 'select' },
  { name: 'or_or_beta', operator: 'eq', type: 'number' },
  { name: 'alleleFreqGenome', operator: 'eq', type: 'number', disable: true },
  { name: 'chemNumPublicationsGDA', operator: 'eq', type: 'number', integer: true },
  { name: 'chemNumPublicationsVDA', operator: 'eq', type: 'number', integer: true },
  { name: 'chemsInSentence', operator: 'startsWith', type: 'string' },
  { name: 'coord', operator: 'eq', type: 'number', disable: true },
  { name: 'description', operator: 'contains', type: 'string' },
  { name: 'polyphen_score', operator: 'eq', type: 'number' },
  { name: 'sift_score', operator: 'eq', type: 'number' },
  { name: 'ncbi_type', operator: 'eq', type: 'select' },
  { name: 'ncbiID', operator: 'startsWith', type: 'string' },
  { name: 'numberPmidsWithChemsFiltered', operator: 'eq', type: 'number', integer: true },
  {
    name: 'numberPmidsWithChemsIncludedInEvidence',
    operator: 'eq',
    type: 'number',
    integer: true,
    disable: true,
  },
  { name: 'PLI', operator: 'eq', type: 'number' },
  { name: 'proteinClassIDs', operator: 'eq', type: 'select' },
  { name: 'proteinIDs', operator: 'eq', type: 'string', disable: true }, // filtering not supported by backend, should be remapped to geneProteinStrIDs
  { name: 'pValue', operator: 'eq', type: 'number', disable: true }, // migrated disabled state from disgenetplus
  { name: 'source', operator: 'contains', type: 'string', disable: true }, // migrated disabled state from disgenetplus
  { name: 'chemical_effect', operator: 'contains', type: 'string' },
  { name: 'reference', operator: 'contains', type: 'string' },
  { name: 'reference_type', operator: 'contains', type: 'string' },
];

export default Filters;
