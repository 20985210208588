import React, { Fragment, useEffect, useCallback, useRef, useState } from 'react';
import cytoscape from 'cytoscape';

import CytoscapeActionButtons from './CytoscapeActionButtons';
import { networkGeneralSettings } from './NetworkHelpers';
import { toggleCyto } from './ActionButtonsFunctions';

export let cyto;

function CytoscapeChart({ elements, names, parent, chartInfo }) {
  const [selectedLayout, setSelectedLayout] = useState('circle');
  const networkRef = useRef(null);

  const setNetworkInfo = useCallback(() => {
    cyto = cytoscape({
      container: networkRef.current,
      elements: elements,
      layout: {
        name: selectedLayout,
      },
      ...networkGeneralSettings,
    });
  }, [elements, selectedLayout]);

  // this is important, always call the network info inside a useEffect, not in the main body
  useEffect(() => {
    setNetworkInfo();
  }, [setNetworkInfo]);

  function changeSelectedLayout(layoutTitle) {
    setSelectedLayout(layoutTitle);
  }

  return (
    <Fragment>
      <CytoscapeActionButtons
        changeSelectedLayout={changeSelectedLayout}
        selectedLayout={selectedLayout}
        names={names}
        parent={parent}
        chartInfo={chartInfo}
      />
      <div ref={networkRef} onClick={toggleCyto} className="network-graph" />
    </Fragment>
  );
}

export default CytoscapeChart;
