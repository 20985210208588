import React from 'react';

function checkYVariableValidity(chartItemData) {
  return chartItemData.data[0].y !== null && chartItemData.data[0].y !== 0;
}

function isItemNew(transformedChartData, modelDataChartId) {
  return transformedChartData.filter(chartData => chartData.id === modelDataChartId).length === 0;
}

function addNewItem(transformedChartData, chartItemData) {
  transformedChartData.push(chartItemData);
}

function isItemDuplicated(arrayToCheck, arrayIndex, itemToCheck) {
  const checkIfItemExistsArray = arrayToCheck[arrayIndex].data.map(item => {
    return item.x === itemToCheck.data[0].x;
  });

  return checkIfItemExistsArray.some(doesItExist => doesItExist);
}

function updateExistingItem(transformedChartData, modelDataChartId, chartItemData) {
  const existingChartIndex = transformedChartData.findIndex(chartData => chartData.id === modelDataChartId);

  if (!isItemDuplicated(transformedChartData, existingChartIndex, chartItemData)) {
    transformedChartData[existingChartIndex].data = transformedChartData[existingChartIndex].data.concat(
      chartItemData.data,
    );
  }
}

function getChartData(data, yVariable, mappingVariables, viewModel, chartModel) {
  let transformedChartData = [];
  data?.map(row => {
    const modelData = new viewModel(row);
    const chartItemData = new chartModel(modelData, yVariable, mappingVariables);

    const modelDataChartId = modelData[mappingVariables[0]];

    if (!checkYVariableValidity(chartItemData, yVariable)) {
      return;
    }

    if (isItemNew(transformedChartData, modelDataChartId)) {
      addNewItem(transformedChartData, chartItemData);
    } else {
      updateExistingItem(transformedChartData, modelDataChartId, chartItemData);
    }
  });

  return transformedChartData;
}

function getChartAxisLabel({
  opacity,
  textAnchor,
  textBaseline,
  textX,
  textY,
  value,
  x,
  y,
  labelClassNames,
  sliceValue,
  doesTickExist = true,
}) {
  return (
    <g transform={`translate(${x},${y})`} style={{ opacity }}>
      <text
        alignmentBaseline={textBaseline}
        textAnchor={textAnchor}
        transform={`translate(${textX},${textY})`}
        className={labelClassNames}
      >
        {`${doesTickExist ? '-' : ''} ${value?.slice(0, sliceValue)}${value?.length > sliceValue ? '...' : ''}`}
      </text>
    </g>
  );
}

export { isItemNew, addNewItem, updateExistingItem, getChartData, getChartAxisLabel };
