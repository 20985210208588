import { getTitle, getName, DATASETS } from '../Constants';
import { searchContext } from '../../Context/Context';

export default function RenderInfoLogic({ searchItems, setSearchItems, setIsInfoSidebarOpen, isMapping }) {
  const { setSelectedInfoItem } = searchContext();

  const foundTitles = Array.isArray(searchItems) ? searchItems.map(item => getTitle(item)) : [];
  const ids = Array.isArray(searchItems) ? searchItems.map(item => getName(item)) : [];

  const handleOpenNav = item => {
    setIsInfoSidebarOpen(true);
    setSelectedInfoItem(item);
  };

  const clear = index => {
    const updatedSearchItems = [...searchItems];
    updatedSearchItems.splice(index, 1);
    setSearchItems(updatedSearchItems);
  };
  const handleClearAndCloseInfoDiv = index => {
    clear(index);
    setIsInfoSidebarOpen(false);
  };

  return (
    <>
      {foundTitles.length > 0 && ids.length > 0 && (
        <div className="col-8 titCab">
          {foundTitles.length > 0 &&
            ids.length > 0 &&
            foundTitles.map((result, index) => (
              <div key={ids[index]}>
                <h4 className="titTabla d-flex">
                  {ids[index] && <span className="mr-2">{[result, ids[index]].join(', ')}</span>}
                  {result.title !== DATASETS.ALL && !isMapping && (
                    <div className="d-flex align-items-center">
                      <div className="mr-2">
                        <button onClick={() => handleOpenNav(searchItems[index])}>
                          <i className="bi-info-circle" />
                        </button>
                      </div>
                      <div>
                        <button onClick={() => handleClearAndCloseInfoDiv(index)}>
                          <i className="bi-x-circle"></i>
                        </button>
                      </div>
                    </div>
                  )}
                </h4>
              </div>
            ))}
        </div>
      )}
      <div className="col-8 titTabla"></div>
    </>
  );
}
