import React from 'react';
import { Helmet } from 'react-helmet-async';
import Logo from '../Components/Common/Logo';
import { TopRightMenu } from '../Components/NavMenus/TopNavigation';
import Footer from '../Components/Home/Footer';
import ApplyAcademicCN from '../Components/SignUpCN/ApplyAcademicPageCN';

function ApplyAcademicPageCN() {
  return (
    <React.Fragment>
      <div className="header-content">
        <Helmet>
          <title>DISGENET: Apply for Academic Access</title>
          <meta
            name="description"
            content="Accelerate Your Research With The Most Reliable & Extensive Database."
          />
          <link rel="canonical" href="/cn-academic-apply" />
        </Helmet>
        <div>
          <Logo />
        </div>
        <div>
          <TopRightMenu />
        </div>
      </div>
      <div className="d-flex justify-content-end mx-4"></div>
      <ApplyAcademicCN />
      <Footer />
    </React.Fragment>
  );
}

export default ApplyAcademicPageCN;
