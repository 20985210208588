import React from 'react';

class FAQContent extends React.Component {
  render() {
    const handleCopy = () => {
      navigator.clipboard.writeText('info@disgenet.com');
    };

    const dataTAB = [
      {
        category: 'Frequency of updates',
        disgenetCom: 'Quarterly',
        disgenetOrg: 'Annual, last update 2020',
      },
      {
        category: 'Text Mining Performance',
        disgenetCom: '92%',
        disgenetOrg: '85%',
      },
      {
        category: 'Text Mining Technology',
        disgenetCom: 'Deep learning & language models, ontologies',
        disgenetOrg: 'Traditional machine learning, ontologies',
      },
      {
        category: 'Coverage of animal models',
        disgenetCom: 'Rat, mouse, fly, dog, pig, zebrafish & others',
        disgenetOrg: 'Rat, mouse',
      },
      {
        category: 'N. Genes',
        disgenetCom: '26,484 (22%*)',
        disgenetOrg: '21,671',
      },
      {
        category: 'N. Variants',
        disgenetCom: '723,970 (272%*)',
        disgenetOrg: '194,515',
      },
      {
        category: 'N. Diseases',
        disgenetCom: '39,922 (32%*)',
        disgenetOrg: '30,170',
      },
      {
        category: 'N. Chemicals',
        disgenetCom: '4,042',
        disgenetOrg: 'Not available',
      },
      {
        category: 'N. GDAs',
        disgenetCom: '1,943,710 (71%*)',
        disgenetOrg: '1,134,942',
      },
      {
        category: 'N. VDAs',
        disgenetCom: '1,237,183 (234%*)',
        disgenetOrg: '369,554',
      },
      {
        category: 'Fine-grained annotations',
        disgenetCom: 'LoF/GoF, chemical effect, semantic relation between diseases and phenotypes, & more',
        disgenetOrg: 'Not available',
      },
    ];

    return (
      <section>
        <link rel="canonical" href="/FAQ" />
        <div className="container p-3">
          <div className="text-center p-4">
            <h1 className="gradient-color">Frequently Asked Questions</h1>
          </div>
          <div className="faq" id="accordion">
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-1"
                data-toggle="collapse"
                data-target="#faqCollapse-1"
                aria-expanded="true"
                aria-controls="faqCollapse-1"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">1</span>Why is there a new DISGENET platform?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-1"
                className="collapse"
                aria-labelledby="faqHeading-1"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    DisGeNET.org has served the academic community for over 15 years, then in 2020, we
                    launched DISGENET plus to serve our commercial users. However, maintaining and updating
                    both platforms has become increasingly challenging. By creating a single platform, we can
                    ensure long-term sustainability, high-quality data and more frequent updates, ultimately
                    providing a more comprehensive data resource for both academic and commercial users.
                  </p>
                </div>
              </div>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-2"
                data-toggle="collapse"
                data-target="#faqCollapse-2"
                aria-expanded="true"
                aria-controls="faqCollapse-2"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">2</span> What can I expect from the new look and feel of DISGENET?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-2"
                className="collapse"
                aria-labelledby="faqHeading-2"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    We're thrilled to introduce a modern update to the DISGENET brand (disgenet.com)! This
                    includes a fresh new look and feel with updated logos, fonts, and colors. Don't worry
                    though, how you interact with the data and features themselves remains the same. In
                    addition to the new look and feel, the platform has been redesigned to be more robust and
                    include new features.
                  </p>
                </div>
              </div>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-3"
                data-toggle="collapse"
                data-target="#faqCollapse-3"
                aria-expanded="true"
                aria-controls="faqCollapse-3"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">3</span> How can I cite DISGENET?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-3"
                className="collapse"
                aria-labelledby="faqHeading-3"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    If you find DISGENET useful for your research, please cite our most recent publication:
                  </p>
                  <p>
                    Janet Piñero, Juan Manuel Ramírez-Anguita, Josep Saüch-Pitarch, Francesco Ronzano, Emilio
                    Centeno, Ferran Sanz, Laura I Furlong, The DisGeNET knowledge platform for disease
                    genomics: 2019 update, Nucleic Acids Research, Volume 48, Issue D1, 08 January 2020, Pages
                    D845–D855,{' '}
                    <a href="https://doi.org/10.1093/nar/gkz1021" target="_blank" rel="noreferrer">
                      https://doi.org/10.1093/nar/gkz1021
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>

            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-4"
                data-toggle="collapse"
                data-target="#faqCollapse-4"
                aria-expanded="true"
                aria-controls="faqCollapse-4"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">4</span> What are the terms of use for DISGENET?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-4"
                className="collapse"
                aria-labelledby="faqHeading-4"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    The DISGENET database and knowledge discovery platform (“DISGENET”) available at
                    https://www.disgenet.com/ are provided by Medbioinformatics Solutions SL to advance the
                    knowledge about human diseases and their associated genes and variants.
                  </p>
                  <p>
                    License fees are applicable for the commercial use of DISGENET. Users from not-for-profit
                    organizations who plan to use DISGENET for non-commercial purposes can apply for free
                    access (through an Academic license). Users who plan to use DISGENET for commercial
                    purposes and/or belong to for-profit organizations must pay a fee to acquire a DISGENET
                    license.
                  </p>
                  <p>
                    This model ensures the continued growth and maintenance of the resource for academics and
                    industry alike.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-4">
              <h3 className="p-2">
                <i className="bi bi-caret-right-fill"></i> For DisGeNET.org Users:
              </h3>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-5"
                data-toggle="collapse"
                data-target="#faqCollapse-5"
                aria-expanded="true"
                aria-controls="faqCollapse-5"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">5</span> Is my existing DisGeNET.org account compatible with the
                    new disgenet.com platform?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-5"
                className="collapse"
                aria-labelledby="faqHeading-5"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    While your existing DisGeNET.org login won't transfer directly, obtaining access to
                    disgenet.com is a quick and easy process.
                  </p>
                  <p>
                    Apply for a free Academic License to continue enjoying DISGENET data free of charge{' '}
                    <a href="/academic-apply">by filling in this brief form.</a>
                  </p>
                  <p>
                    To see all licenses available, including advanced features suitable for both academic and
                    commercial users, go to our <a href="/plans">Plans page</a>.
                  </p>
                </div>
              </div>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-6"
                data-toggle="collapse"
                data-target="#faqCollapse-6"
                aria-expanded="true"
                aria-controls="faqCollapse-6"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">6</span>I am an academic or not-for-profit researcher. Do I need
                    to pay to access disgenet.com? Which plan should I apply for?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-6"
                className="collapse"
                aria-labelledby="faqHeading-6"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    Disgenet.com maintains free access to a wealth of curated bioinformatics data. Begin the
                    simple registration process by <a href="/academic-apply">clicking here</a> and secure your
                    Free Academic License.
                  </p>
                  <p>
                    If you wish to access the Advanced plan with text-mined data, we offer competitive
                    subscription fees tailored to academics. <a href="/plans">Click here</a> to view our
                    Plans.
                  </p>
                </div>
              </div>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-7"
                data-toggle="collapse"
                data-target="#faqCollapse-7"
                aria-expanded="true"
                aria-controls="faqCollapse-7"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">7</span>What are the advantages of disgenet.com compared to
                    DisGeNET.org?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-7"
                className="collapse"
                aria-labelledby="faqHeading-7"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    The table below summarizes the differences in statistics for the current DISGENET version
                    (v24.3).
                  </p>
                  <div>
                    <table
                      className="table panther-table"
                      style={{ float: 'middle', margin: '10px 20px 40px 10px' }}
                    >
                      <thead className="text-center">
                        <tr>
                          <th></th>
                          <th>disgenet.com</th>
                          <th>disgenet.org</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {dataTAB.map((row, index) => (
                          <tr key={index}>
                            <th>{row.category}</th>
                            <td>{row.disgenetCom}</td>
                            <td>{row.disgenetOrg}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <p>*increase in data in DISGENET (V24.3) compared to DisGeNET.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-8"
                data-toggle="collapse"
                data-target="#faqCollapse-8"
                aria-expanded="true"
                aria-controls="faqCollapse-8"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">8</span>How does the change to disgenet.com affect tools such as
                    the API, R package, and Cytoscape App?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-8"
                className="collapse"
                aria-labelledby="faqHeading-8"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    The API has been migrated to disgenet.com. As a disgenet.org user, there are two small
                    steps you will need to take.
                  </p>
                  <p>
                    Firstly, apply for your Free Academic License using{' '}
                    <a href="/academic-apply" target="_blank" rel="noreferrer">
                      this link.
                    </a>
                  </p>
                  <p>
                    Once approved, you'll need to update your configurations to point to the new base URL.
                    Click here for help documentation.
                  </p>
                  <p>
                    The R package available at <u>https://bitbucket.org/ibi_group/disgenet2r/</u> will no
                    longer be supported. There is a new version of the package available at{' '}
                    <a href="https://gitlab.com/medbio/disgenet2r" target="_blank" rel="noopener noreferrer">
                      https://gitlab.com/medbio/disgenet2r
                    </a>
                    .
                  </p>
                  <p>
                    The Cytoscape App is available in the Cytoscape{' '}
                    <a
                      href="https://apps.cytoscape.org/apps/disgenetapp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      App store.
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="p-4">
              <h3 className="p-2">
                <i className="bi bi-caret-right-fill"></i> For DISGENET plus Users:
              </h3>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-9"
                data-toggle="collapse"
                data-target="#faqCollapse-9"
                aria-expanded="true"
                aria-controls="faqCollapse-9"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">9</span>Can I log into the new disgenet.com platform using my
                    DISGENET Plus login credentials?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-9"
                className="collapse"
                aria-labelledby="faqHeading-9"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    Yes. Your DIGENET Plus account has been automatically transferred to the new platform. You
                    can access your account at <a href="disgenet.com">disgenet.com</a> with your username and
                    password, as usual.
                  </p>
                </div>
              </div>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-10"
                data-toggle="collapse"
                data-target="#faqCollapse-10"
                aria-expanded="true"
                aria-controls="faqCollapse-10"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">10</span>Will my subscription fees change?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-10"
                className="collapse"
                aria-labelledby="faqHeading-10"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    No. Your subscription agreement will continue under the agreed terms and at the current
                    price. If you wish to upgrade, <a href="/Contact-us">contact us</a>.
                  </p>
                </div>
              </div>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-11"
                data-toggle="collapse"
                data-target="#faqCollapse-11"
                aria-expanded="true"
                aria-controls="faqCollapse-11"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">11</span>Does disgenet.com have the same features and
                    functionalities as DISGENET Plus?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-11"
                className="collapse"
                aria-labelledby="faqHeading-11"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    Yes, disgenet.com will incorporate the advanced features previously available on DISGENET
                    Plus, and will incorporate new features in the future.
                  </p>
                </div>
              </div>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-12"
                data-toggle="collapse"
                data-target="#faqCollapse-12"
                aria-expanded="true"
                aria-controls="faqCollapse-12"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">12</span>Is there a new API for disgenet.com, or will the existing
                    one be updated?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-12"
                className="collapse"
                aria-labelledby="faqHeading-12"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    The DISGENET Plus API has been migrated to disgenet.com. You'll just need to update your
                    configurations to point to the new base URL (https://api.disgenet.com/) However, please
                    note that DISGENET plus REST API (https://api.disgenetplus.com/) will be available for
                    some time to enable users to transition to the new REST API.
                    <a href="/Tools#console"> Click here</a> for help documentation.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-4">
              <h3 className="p-2">
                <i className="bi bi-caret-right-fill"></i> Information & Access:
              </h3>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-13"
                data-toggle="collapse"
                data-target="#faqCollapse-13"
                aria-expanded="true"
                aria-controls="faqCollapse-13"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">13</span>Am I eligible for a free license?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-13"
                className="collapse"
                aria-labelledby="faqHeading-13"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    If you belong to a for-profit organization (commercial use), you must acquire a
                    subscription license to use DISGENET.
                  </p>
                  <p>
                    If you belong to a not-for-profit organization and plan to use DISGENET for non-commercial
                    use, you can apply for a free Academic license or acquire an Advanced license. See a
                    comparison table of the available <a href="/plans">plans here</a>.
                  </p>
                </div>
              </div>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-14"
                data-toggle="collapse"
                data-target="#faqCollapse-14"
                aria-expanded="true"
                aria-controls="faqCollapse-14"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">14</span>What is considered Non-commercial and Commercial use?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-14"
                className="collapse"
                aria-labelledby="faqHeading-14"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    <strong>Non-commercial use</strong>
                  </p>
                  <p>
                    <strong>A free access license for </strong>DISGENET is intended to be used only for
                    academic purposes, scientific non-commercial research and education, including teaching
                    activities delivered by academic institutions to students, and scientific research work
                    and/or research study based on or done with DISGENET that do not have a direct or indirect
                    known commercial purpose. If DISGENET is incorporated into other works, we ask that
                    DISGENET is properly cited (see FAQ #16), the version number of DISGENET is clearly
                    displayed, and that the derived work terms of use comply with the restrictions defined in
                    the DISGENET Subscription Agreement.
                  </p>
                  <p>
                    <strong>Commercial use</strong>
                  </p>
                  <p>
                    If you are a commercial company wishing to use DISGENET in any capacity, you require a
                    commercial use license. Other uses that require a commercial license include; conducting
                    research for a commercial product (existing or in development) or using DISGENET in or to
                    enhance a commercial product or service offering (even if free). If you are not sure,
                    please contact us at{' '}
                    <span onClick={handleCopy} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                      info@disgenet.com
                    </span>
                    .
                  </p>

                  <img className="faq-banner" src="../../static/images/faqbanner.png" alt="..." />
                </div>
              </div>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-15"
                data-toggle="collapse"
                data-target="#faqCollapse-15"
                aria-expanded="true"
                aria-controls="faqCollapse-15"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">15</span>Can I incorporate DISGENET data in other products that I
                    will redistribute or resell?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-15"
                className="collapse"
                aria-labelledby="faqHeading-15"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    Our standard licenses do not allow this special use, for the whole or a portion of the
                    database. If you are interested in this kind of repackaging, please{' '}
                    <a href="/Contact-us">contact us</a>.
                  </p>
                </div>
              </div>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-16"
                data-toggle="collapse"
                data-target="#faqCollapse-16"
                aria-expanded="true"
                aria-controls="faqCollapse-16"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">16</span>Can I disseminate the results of my research using
                    DISGENET in scientific meetings, conferences, or publications?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-16"
                className="collapse"
                aria-labelledby="faqHeading-16"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    You are free to publish results obtained from DISGENET in scientific publications provided
                    you cited us in your publication or research communication.
                  </p>
                  <p>
                    If you find DISGENET useful for your research, please cite our most recent publication:
                  </p>
                  <p>
                    Janet Piñero, Juan Manuel Ramírez-Anguita, Josep Saüch-Pitarch, Francesco Ronzano, Emilio
                    Centeno, Ferran Sanz, Laura I Furlong, The DisGeNET knowledge platform for disease
                    genomics: 2019 update, Nucleic Acids Research, Volume 48, Issue D1, 08 January 2020, Pages
                    D845–D855,{' '}
                    <a href="https://doi.org/10.1093/nar/gkz1021">https://doi.org/10.1093/nar/gkz1021</a>.
                  </p>
                </div>
              </div>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-17"
                data-toggle="collapse"
                data-target="#faqCollapse-17"
                aria-expanded="true"
                aria-controls="faqCollapse-17"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">17</span>I am doing my PhD research in a biotech company, can I
                    apply for an academic license?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-17"
                className="collapse"
                aria-labelledby="faqHeading-17"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    Please check FAQ #14 to determine if you can apply for an Academic license. If you still
                    have doubts, please reach out at{' '}
                    <span onClick={handleCopy} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                      info@disgenet.com
                    </span>
                    .
                  </p>
                </div>
              </div>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-18"
                data-toggle="collapse"
                data-target="#faqCollapse-18"
                aria-expanded="true"
                aria-controls="faqCollapse-18"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">18</span>Is there a fee for using DISGENET?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-18"
                className="collapse"
                aria-labelledby="faqHeading-18"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    License fees are applicable for the commercial use of DISGENET. Users from not-for-profit
                    organizations can apply for free access (Academic license). Check FAQ #14 to learn more
                    about what is considered commercial and not commercial use. Learn more about our{' '}
                    <a href="/plans">plans here</a>.
                  </p>
                  <p>
                    This model ensures the continued growth and maintenance of the resource for academics and
                    industry alike.
                  </p>
                </div>
              </div>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-19"
                data-toggle="collapse"
                data-target="#faqCollapse-19"
                aria-expanded="true"
                aria-controls="faqCollapse-19"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">19</span>How can I access DISGENET?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-19"
                className="collapse"
                aria-labelledby="faqHeading-19"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    DISGENET can be accessed through the web interface, the REST API, the disgenet2r package.
                    You can explore all options on our <a href="/plans">plans page</a>.
                  </p>
                </div>
              </div>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-20"
                data-toggle="collapse"
                data-target="#faqCollapse-20"
                aria-expanded="true"
                aria-controls="faqCollapse-20"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">20</span>Can I test DISGENET before acquiring it?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-20"
                className="collapse"
                aria-labelledby="faqHeading-20"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    The new DISGENET platform enables basic searches without subscription, retrieving a
                    limited number of search results, to get a sense of the data and functionalities of the
                    platform. If you want to test the functionalities offered as part of the Advanced plan,
                    you can sign up for a 7-day free trial by <a href="/free-trial-apply">clicking here</a>.
                  </p>
                </div>
              </div>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-21"
                data-toggle="collapse"
                data-target="#faqCollapse-21"
                aria-expanded="true"
                aria-controls="faqCollapse-21"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">21</span>In which languages do you offer customer support?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-21"
                className="collapse"
                aria-labelledby="faqHeading-21"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>We currently offer customer support in English and Spanish.</p>
                </div>
              </div>
            </div>
            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-22"
                data-toggle="collapse"
                data-target="#faqCollapse-22"
                aria-expanded="true"
                aria-controls="faqCollapse-22"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">22</span>How long can I access the database after I purchase a
                    license?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-22"
                className="collapse"
                aria-labelledby="faqHeading-22"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    Your access to the database starts as soon as we receive your full payment. The standard
                    licensing period is one year, but we offer options for longer access terms.
                  </p>
                </div>
              </div>
            </div>

            <div className="card-faq">
              <div
                className="card-faq-header"
                id="faqHeading-23"
                data-toggle="collapse"
                data-target="#faqCollapse-23"
                aria-expanded="true"
                aria-controls="faqCollapse-23"
              >
                <div className="mb-0 d-flex align-items-center justify-content-between">
                  <h5 className="faq-title">
                    <span className="badge">23</span>Do you offer custom data analysis services? Can you help
                    me find specific disease data?
                  </h5>
                  <i className="bi bi-caret-down-fill px-3"></i>
                </div>
              </div>
              <div
                id="faqCollapse-23"
                className="collapse"
                aria-labelledby="faqHeading-23"
                data-parent="#accordion"
              >
                <div className="card-faq-body">
                  <p>
                    Yes, at MedBioinformatics we can extract relevant information about a particular disease
                    from the vast body of scientific literature. Our team can also help you clean, organize,
                    and analyze specific data sets to meet your research needs. You can get in touch using our{' '}
                    <a href="/Contact-us">contact form</a> or{' '}
                    <a href="https://calendly.com/medbioinformatics/30min" target="_blank" rel="noreferrer">
                      book a brief call
                    </a>{' '}
                    to discuss your needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FAQContent;
