import React, { Component } from 'react';
/*
import SwaggerUi, {presets} from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';


class SwaggerInfo extends Component {
    render() {
		return <div id="swagger-info" />
    }	
}

const SwaggerInfoPlugin = (system) => {  
  return {
    wrapComponents: {
      info: (OriginalComponent) => SwaggerInfo
    }
  }
}

class SwaggerAPI extends Component {
  componentDidMount() {
    SwaggerUi({
      defaultModelsExpandDepth: -1,
      dom_id: '#swaggerContainer',
      url: 'https://api.disgenet.com/v2/api-docs',
      presets: [SwaggerUi.presets.apis],
  	  plugins: [SwaggerInfoPlugin],
    });
  }

  render() {
    return (
      <div id="swaggerContainer" />
    );
  }
}
*/

class SwaggerAPI extends Component {
  render() {
    return (
      <iframe
        id="iFrameSwaggerAPI"
        src={`${process.env.REACT_APP_URL_API_API}doc/swagger`}
        style={{ width: '100%', height: '1000px', backgroundColor: 'black' }}
      />
    );
  }
}

export default SwaggerAPI;
