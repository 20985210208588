import React, { useState } from 'react';
import ColumnList from './ColumnList';

function TableColumnsMenu({
  view,
  tab,
  setIsColumnSelectOpen,
  columnVisibilityModel,
  setColumnVisibilityModel,
}) {
  const params = { view, tab };

  const [allSelected, setAllSelected] = useState(false);

  const selectAllItems = () => {
    setColumnVisibilityModel(prevItems => {
      const updatedItems = {};
      Object.keys(prevItems).forEach(column => {
        updatedItems[column] = !allSelected;
      });
      return { ...updatedItems };
    });
    setAllSelected(!allSelected);
  };
  return (
    <div className="external-menu">
      <ul className="column-grid-list">
        <ColumnList
          params={params}
          tab={tab}
          allSelected={allSelected}
          setAllSelected={setAllSelected}
          columnVisibilityModel={columnVisibilityModel}
          setColumnVisibilityModel={setColumnVisibilityModel}
        />
      </ul>
      <div className="action-buttons">
        <button onClick={selectAllItems} className="select">
          {allSelected ? 'Deselect All' : 'Select All'}
        </button>
        <button onClick={() => setIsColumnSelectOpen(false)} className="select">
          Close
        </button>
      </div>
    </div>
  );
}

export default TableColumnsMenu;
