import React from 'react';

const NotLoginPage = () => {
  return (
    <React.Fragment>
      <div className="no-login-container  mt-4 pt-4 pb-4">
        <h5 className="text-center">You are not logged in</h5>
        <h6 className="text-center">
          <a href="/login">Login</a>{' '}
        </h6>
      </div>
    </React.Fragment>
  );
};

export default NotLoginPage;
