import React from 'react';
import ReactGA from 'react-ga4';
import CommonHeader from '../Components/Headers/CommonHeader';
import ActivationErrorContent from '../Components/AccountActivationError/ActivationErrorContent';
import Footer from '../Components/Home/Footer';
import $ from 'jquery';

export default function About() {
  $('.popover').hide();
  ReactGA.send({
    hitType: 'pageview',
    page: '/ConfirmationError',
    title: 'Account Activation',
  });

  return (
    <React.Fragment>
      <CommonHeader />
      <ActivationErrorContent />
      <Footer />
    </React.Fragment>
  );
}
