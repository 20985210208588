import React from 'react';

export default function ErrorMessage({ title, subtitle }) {
  return (
    <section className="col-12 request-failed">
      <h1>{title}</h1>
      <p>{subtitle}</p>
    </section>
  );
}
