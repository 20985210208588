import React, { Fragment, useState, useEffect } from 'react';
import { useViewChangeTracker } from '../../../Hooks/UseViewChangeTracker';

import HeatmapChart from '../Charts/Heatmap/HeatmapChart';
import LineChart from '../Charts/Line/LineChart';
import NetworkChart from '../Charts/Network/NetworkChart';

function getInitialChartInfo(selectedChart) {
  return {
    type: selectedChart,
    variable: 'jaccard_genes',
    variableName: 'JI. Genes',
    variableFullName: 'Jaccard Index Genes',
  };
}

function DDAGraphs({ source, keySearch, view, names, selectedChart }) {
  const [chartInfo, setChartInfo] = useState(() => getInitialChartInfo(selectedChart));

  useViewChangeTracker('DDAGraphs', view, 'graph');

  useEffect(() => {
    setChartInfo(prevChartInfo => ({ ...prevChartInfo, type: selectedChart }));
  }, [selectedChart]);

  // Heatmap and Line configurations:
  const heatmapLineSearchVariables = [
    {
      ident: 'jaccard_genes',
      name: 'JI. Genes',
      fullName: 'Jaccard Index Genes',
    },
    {
      ident: 'jaccard_variants',
      name: 'JI. Variants',
      fullName: 'Jaccard Index Variants',
    },
    {
      ident: 'shared_genes',
      name: 'N. Shared Genes',
      fullName: 'Number of Shared Genes',
    },
    {
      ident: 'shared_variants',
      name: 'N. Shared Variants',
      fullName: 'Number of Shared Variants',
    },
  ];

  return (
    <Fragment>
      {chartInfo.type === 'HEATMAP' && (
        <HeatmapChart
          source={source}
          keySearch={keySearch}
          view={view}
          names={names}
          chartInfo={chartInfo}
          setChartInfo={setChartInfo}
          baseUrl={'api/dda'}
          parent={'DDA'}
          heatmapSearchVariables={[...heatmapLineSearchVariables]}
        />
      )}

      {chartInfo.type === 'LINE' && (
        <LineChart
          source={source}
          keySearch={keySearch}
          view={view}
          names={names}
          chartInfo={chartInfo}
          setChartInfo={setChartInfo}
          baseUrl={'api/dda'}
          parent={'DDA'}
          lineSearchVariables={[...heatmapLineSearchVariables]}
        />
      )}

      {chartInfo.type === 'NETWORK' && (
        <NetworkChart
          source={source}
          keySearch={keySearch}
          view={view}
          names={names}
          chartInfo={chartInfo}
          setChartInfo={setChartInfo}
          baseUrl={'api/dda'}
          parent={'DDA'}
          networkSearchVariables={[...heatmapLineSearchVariables]}
        />
      )}
    </Fragment>
  );
}

export default React.memo(DDAGraphs);
