import React from 'react';
import Modal from 'react-modal';
import SpinLoader from '@inovua/reactdatagrid-community/packages/LoadMask/src/SpinLoader';

export default function DownloadFilesModal({ customStyles }) {
  return (
    <Modal isOpen={true} ariaHideApp={false} style={customStyles} shouldFocusAfterRender={false}>
      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <SpinLoader />
      </div>
      <h1>Downloading</h1>
    </Modal>
  );
}
