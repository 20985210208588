import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <Link to="/">
      <img src="../../../static/images/LogoDisgenet2.png" alt="logo disgenet" className="logo" />
    </Link>
  );
};

export default Logo;
