import { useContext, createContext } from 'react';

export const AppContext = createContext(null);

export function userAppContext() {
  const context = useContext(AppContext);

  return context;
}

export function searchContext() {
  const searchContext = useContext(AppContext);

  return searchContext;
}
