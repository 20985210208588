import React from 'react';
import { useState } from 'react';
// import { getRenderedSourceValue } from './SourceButton';
import { useNavigate, useLocation } from 'react-router-dom';
import updateParams from '../Utils/UpdateParams';

export const getRenderedSourceValue = source => {
  return source.replace('_', ' ');
};

export default function Buttons({ sourceList }) {
  const [selectedButton, setSelectedButton] = useState(0);

  const Buttons = sourceList.map((value, index) => {
    const renderedValue = getRenderedSourceValue(value);
    const buttonStyle = index === 0 ? 'source-btn-one' : 'source-btn';

    const navigate = useNavigate();
    const location = useLocation();
    const handleClick = (value, selectedIndex) => {
      setSelectedButton(selectedIndex);
      navigate({ pathname: location.pathname, search: updateParams({ source: value }) });
    };

    return (
      <button
        key={index}
        type="button"
        className={`btn-sm m-1 ${buttonStyle}`}
        data-toggle="collapse"
        data-target="#SUBMENU"
        onClick={() => handleClick(value, index)}
      >
        {renderedValue}
      </button>
    );
  });

  return (
    <div className="m-1">
      <div id="SUBMENU">
        {Buttons.map((button, index) => (
          <div key={index} style={{ display: 'inline-block' }}>
            {button}
          </div>
        ))}
      </div>
    </div>
  );
}

export function SourceButton({ source, setSourceOpen }) {
  const renderedSourceValue = getRenderedSourceValue(source);

  return (
    <>
      <button
        type="button"
        className="ml-2 btn btn-info"
        data-toggle="collapse"
        data-target="#SUBMENU"
        onClick={() => {
          setSourceOpen(true);
        }}
      >
        <i className="fa fa-sliders mr-2"></i>Source: {renderedSourceValue}
        <i className="fa fa-angle-down op-0-5 ml-2" />
      </button>
    </>
  );
}
