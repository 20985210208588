import React from 'react';
import { useLocation } from 'react-router-dom';
import CommonHeader from '../../Components/Headers/CommonHeader.jsx';
import { BlogList } from '../../Components/Blog/BlogContent.jsx';
import SummaryBlog from '../../Components/Blog/SummaryBlog.jsx';
import { posts } from '../../Components/Blog/Posts.jsx';
import Footer from '../../Components/Home/Footer.jsx';

export default function BlogListPage() {
  return (
    <React.Fragment>
      <CommonHeader />
      <div className="row p-4">
        <div className="col-12 col-md-2 order-0 order-md-0 mb-4 mb-md-0">
          <SummaryBlog />
        </div>
        <div className="col-12 col-md-8 order-1 order-md-1">
          <BlogList posts={posts} />
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}
