import React from 'react';

class TableFromFile extends React.Component {
  constructor(props) {
    super(props);
    this.filePath = props.filePath;
    this.state = {
      rows: []
    };
  }

  componentDidMount() {
    fetch(this.filePath)
      .then((res) => res.text())
      .then((txt) => {
        let fetchedRows = this.parseCsvFile(txt, '\t');
        this.setState({ rows: fetchedRows });
      });
  }

  parseCsvFile(file, separator) {
    let lines = file.split('\n');
    let rows = [];
    for (let x = 1; x < lines.length; x++) {
      if (lines[x] != null && lines[x] != '') {
        let htmlLine = [];
        lines[x].split(separator).map((value, i) => {
          htmlLine.push(<td key={i}>{value}</td>);
        });
        rows.push(htmlLine);
      }
    }
    return rows;
  }
  render() {
    return (
      <>
        {this.state.rows.map((line, i) => (
          <tr key={i}>{line}</tr>
        ))}
      </>
    );
  }
}

export { TableFromFile };
