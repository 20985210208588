import { getChartData } from '../Common/HeatmapLineHelpers';
import { GDAModel } from '../../../../Models/GDAModel';
import { VDAModel } from '../../../../Models/VDAModel';
import { DDAModel } from '../../../../Models/DDAModel';
import { wholeNumberParams } from '../Common/Constants';

// structure of an individual line item
class LineItem {
  constructor(data, yVariable, mappingVariables) {
    this.id = data[mappingVariables[0]];
    this.data = [
      {
        x: data[mappingVariables[1]],
        y: data[yVariable],
      },
    ];
  }
}

// information needed for constructing the LineChart, depending on the tab selected
const uniqueIdentViewsVariables = {
  GDA: {
    model: GDAModel,
    mappingIdents: {
      DISEASES: ['diseaseName', 'geneSymbol'],
      GENES: ['geneSymbol', 'diseaseName'],
    },
    plottedValues: {
      DISEASES: 'GENES',
      GENES: 'DISEASES',
    },
  },
  VDA: {
    model: VDAModel,
    mappingIdents: {
      DISEASES: ['diseaseName', 'variantStrID'],
      GENES: ['diseaseName', 'variantStrID'],
      VARIANTS: ['variantStrID', 'diseaseName'],
    },
    plottedValues: {
      DISEASES: 'VARIANTS',
      GENES: 'VARIANTS',
      VARIANTS: 'DISEASES',
    },
  },
  DDA: {
    model: DDAModel,
    mappingIdents: {
      DISEASES: ['disease1_Name', 'disease2_Name'],
    },
    plottedValues: {
      DISEASES: 'DISEASE 2',
    },
  },
};

// logic for mapping the data from backend into a line chart
function getLineData(data, mappingVariables, model, yVariable) {
  const transformedLineData = getChartData(data, yVariable, mappingVariables, model, LineItem);
  return transformedLineData;
}

function sortChartData(chartData) {
  chartData[0].data.sort(function (xyPair1, xyPair2) {
    const xAttribute1 = typeof xyPair1.x === 'string' ? xyPair1.x.toUpperCase() : xyPair1.x;
    const xAttribute2 = typeof xyPair2.x === 'string' ? xyPair2.x.toUpperCase() : xyPair2.x;
    if (xAttribute1 < xAttribute2) {
      return -1;
    }
    if (xAttribute1 > xAttribute2) {
      return 1;
    }
    return 0;
  });
}


function getYAxisValues(selectedAttribute, data) {
  let maxValue = 1;
  let minValue = 0;

  if (wholeNumberParams.includes(selectedAttribute)) {
    maxValue = data[0][selectedAttribute];
  }

  return { max: maxValue, min: minValue };
}

function getXAxisTitle(originalTitle, additionalData) {
  if (additionalData.numberOfFilters > 1 && additionalData.chartData.length > 0) {
    return `${originalTitle} of ${additionalData.chartData[0].id}`;
  } else {
    return `${originalTitle}`;
  }
}

function getMarginPercent(numberOfParams) {
  if (numberOfParams > 15) {
    return 0.1;
  } else if (numberOfParams > 10) {
    return 0.15;
  } else if (numberOfParams > 5) {
    return 0.25;
  } else {
    return 0.35;
  }
}

function setLineXMargins(width, totalResults) {
  const marginPercent = getMarginPercent(totalResults);
  const xMargin = width * marginPercent < 100 ? 100 : width * marginPercent;

  return xMargin;
}

const lineTheme = {
  axis: {
    legend: {
      text: {
        fontSize: 14,
        fontWeight: 600,
      },
    },
    ticks: {
      text: {
        fontSize: 12,
      },
    },
  },
};

export {
  uniqueIdentViewsVariables,
  getLineData,
  sortChartData,
  getYAxisValues,
  getXAxisTitle,
  setLineXMargins,
  lineTheme,
};
