import React from 'react';
import { useLocation, Link } from 'react-router-dom';

import SwaggerAPI from './SwaggerAPI';
import DownloadDump from './DownloadDump';
//import DownloadDump2 from './DownloadDump2';
import QueriesLimitTable from './QueriesLimitTable';
import { HashLink } from 'react-router-hash-link';
import ApiHashLinks from './ApiHashiLinks';
import { userAppContext } from '../../Context/Context';

function APIContent() {
  const location = useLocation();
  const hash = location.hash;
  const currentPath = location.pathname;
  const { user } = userAppContext();
  const userRole = user['cognito:groups'][0];
  // console.log(user['cognito:groups']);
  const rolesAllowedDownloadDump = ['ROLE_UNLIMITED', 'ROLE_DEVELOPER'];

  const commonClassNames = 'mb-1 dropdown-item text-wrap';
  const subItemClassNames = 'subitem ml-3';

  const disgenetDownloadContent = (
    <React.Fragment>
      <h5 className="mt-3">DISGENET download</h5>
      <HashLink
        to="#download"
        data-toggle="tab"
        tabIndex={0}
        data-target="#download"
        className={`${commonClassNames} ${hash === '#download' ? 'active' : ''}`}
      >
        How to download DISGENET
      </HashLink>
    </React.Fragment>
  );

  return (
    <section>
      <div className="txt-contenido">
        <div>
          <div className="tab-content">
            <div
              className={`tab-pane ${hash === '#Rinstallation' || (hash === '' && currentPath === '/Tools') ? 'active show' : ''}`}
              id="Rinstallation"
            >
              <h2>Installation</h2>
              <div className="divider" />
              <p>
                The package disgenet2r is available through GitLab at{' '}
                <a href="https://gitlab.com/medbio/disgenet2r" target="_blank" rel="noopener noreferrer">
                  https://gitlab.com/medbio/disgenet2r
                </a>
                . The package requires an R version greater than 3.5.
                <br />
                You can install disgenet2r by typing the following commands in R:
              </p>
              <code className="rpackage-code">
                <div>{`  `}library(devtools)</div>
                <div>{`  `}install_gitlab("medbio/disgenet2r")</div>
              </code>
            </div>

            <div
              className={`tab-pane ${hash === '#Rdocumentation' ? 'active show' : ''}`}
              id="Rdocumentation"
            >
              <h2>Documentation</h2>
              <div className="divider" />
              <p>
                The disgenet2r package contains a set of functions to retrieve, visualize and expand DISGENET
                data. {`\n`}
                You can consult the documentation for the disgenet2r package in{' '}
                <a href="https://medbio.gitlab.io/disgenet2r/" target="_blank" rel="noreferrer">
                  this page
                </a>
                .
              </p>
            </div>

            <div className={`tab-pane ${hash === '#RHowToUse' ? 'active show' : ''}`} id="RHowToUse">
              <h2>How to use the package</h2>
              <div className="divider" />
              <p>The steps to start using the package after installing it are described below:</p>
              <ol className="cytoscape-steps">
                <li>
                  Import the disgenet2r library in your R script.
                  <div>
                    <code className="rpackage-code">library(disgenet2r)</code>
                  </div>
                </li>
                <li>Register in Disgenet.</li>
                <li>
                  Once you have completed the registration process go to your user profile and retrieve your
                  API key (Figure 1)
                </li>
                <li>
                  After retrieving the API key, run the commands shown below so the key is available for all
                  the <strong>disgenet2r</strong> functions.
                  <code className="rpackage-code">
                    <div>
                      {`  `}api_key {String.fromCharCode(8592)} <strong>"enter your API key here"</strong>
                    </div>
                    <div>{`  `}Sys.setenv(DISGENET_API_KEY= api_key)</div>
                  </code>
                </li>
              </ol>
              <figure className="tools-section-image apikey-retrieval">
                <img src="../../static/images/user_api_key.jpg" alt="API-key-retrieval" />
                <figcaption>Figure 1: API Key retrieval</figcaption>
              </figure>

              <ul
                className="nav nav-tabs"
                style={{
                  marginTop: '2.5rem',
                }}
                id="rPackageExample"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#rPackageDis"
                    role="tab"
                    aria-controls="rPackageDis"
                    aria-selected="true"
                  >
                    Searching by Disease
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="home-tab"
                    data-toggle="tab"
                    href="#rPackageGene"
                    role="tab"
                    aria-controls="rPackageGene"
                    aria-selected="true"
                  >
                    Searching by Gene
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="home-tab"
                    data-toggle="tab"
                    href="#rPackageVar"
                    role="tab"
                    aria-controls="rPackageVar"
                    aria-selected="true"
                  >
                    Searching by Variant
                  </a>
                </li>
              </ul>
              <div
                className="tab-content"
                style={{
                  backgroundColor: 'white',
                }}
                id="rPackageExampleContent"
              >
                <div
                  className="tab-pane fade show active"
                  style={{
                    padding: '10px',
                  }}
                  id="rPackageDis"
                  role="tabpanel"
                  aria-labelledby="rPackageDiseaseExample"
                >
                  <pre>
                    <strong>
                      # ©2023 MedBioinformatics Solutions SL {'\n'}# Usage examples of R package - Searching
                      by Disease {'\n'}
                    </strong>
                    <br />
                    <div>
                      {`data1 <- disease2gene(disease  = "UMLS_C0036341", database = "CURATED", score = c( 0.8,1 ))`}
                    </div>

                    <strong>
                      # The function disease2gene produces an object DataGeNET.DGN that contains the results
                      of the query.
                    </strong>
                  </pre>
                </div>

                <div
                  className="tab-pane fade show"
                  style={{
                    padding: '10px',
                  }}
                  id="rPackageGene"
                  role="tabpanel"
                  aria-labelledby="rPackageGeneExample"
                >
                  <pre>
                    <strong>
                      # ©2023 MedBioinformatics Solutions SL {'\n'}# Usage examples of R package - Searching
                      by Gene {'\n'}
                    </strong>
                    <br />
                    <div>
                      {`data2 <- gene2disease(gene = 3953, vocabulary = "ENTREZ", database = "CURATED")`}
                    </div>
                    <strong>
                      # The function gene2disease produces an object DataGeNET.DGN that contains the results
                      of the query.
                    </strong>
                  </pre>
                </div>

                <div
                  className="tab-pane fade show"
                  style={{
                    padding: '10px',
                  }}
                  id="rPackageVar"
                  role="tabpanel"
                  aria-labelledby="rPackageVariantExample"
                >
                  <pre>
                    <strong>
                      # ©2023 MedBioinformatics Solutions SL {'\n'}# Usage examples of R package - Searching
                      by Variant {'\n'}
                    </strong>
                    <br />
                    <div>
                      {`data3 <- variant2disease(variant = "rs113488022", database = "CURATED", score = c(0.7,1))`}
                    </div>
                    <strong>
                      # The function variant2disease produces an object DataGeNET.DGN that contains the
                      results of the query.
                    </strong>
                  </pre>
                </div>
              </div>
            </div>

            <div className={`tab-pane ${hash === '#console' ? 'active show' : ''}`} id="console">
              <h2>Interactive console</h2>
              <div className="divider" />
              <p>
                In order to easily and interactively query the REST endpoints of DISGENET as well as to get a
                detailed description of the input parameters and response format of each single endpoint, you
                can access the REST API interactive documentation. To be able to query endpoints, users of the
                REST API interactive documentation need to provide their API key by clicking the "Authorize"
                button (top-right corner, next to the green padlock symbol). A popup box named "Available
                authorizations" will open enabling users to copy their API key in the input box and click on
                the "Authorize" button. From now on all the API calls generated by the REST API interactive
                documentation will be automatically authorized by means of the provided API key.
              </p>
              <div>
                <QueriesLimitTable />
                <SwaggerAPI />
              </div>
            </div>

            <div className={`tab-pane ${hash === '#howtouse' ? 'active show' : ''}`} id="howtouse">
              <h2>How to use the API</h2>
              <div className="divider" />
              <p>
                The DISGENET REST API enables users to programmatically access the DISGENET knowledge base by
                querying a collection of REST endpoints. To query the DISGENET REST API, registered users are
                assigned an API key that enables them to authenticate their queries. After accessing the
                DISGENET Web portal by providing their authentication credentials (i.e. username and
                password), users can retrieve their API key by clicking the "user detail" icon present in the
                top-right corner of the screen. In order to query the REST API of DISGENET, users should add
                to each API call an HTTP header with name equal to 'Authorization' and value equal to their
                API key. The following sections of the DISGENET REST API documentation provide more details on
                the available REST endpoints and how to query each endpoint by describing examples of client
                programs in Python and R.{' '}
              </p>
            </div>

            <div className={`tab-pane ${hash === '#endpoints' ? 'active show' : ''}`} id="endpoints">
              <h2>Endpoints</h2>
              <div className="divider" />
              <p>
                DISGENET REST API provides a set of endpoints to query information on genes and variants
                associated with human diseases. To get a detailed description of the input parameters and the
                format of the response of each REST API endpoint, access the REST API documentation in the{' '}
                <HashLink to="#console" data-target="#console" data-toggle="tab" className="js-fix-scroll">
                  Interactive console
                </HashLink>
                . From the REST API documentation, by providing your API key, you can easily and interactively
                query all REST API endpoints as well as visually inspect the results.
                <br />
                <br />
                <b>⇒ Exploit your API key in the Interactive console of the REST API:</b>: the{' '}
                <HashLink to="#console" data-target="#console" data-toggle="tab" className="js-fix-scroll">
                  Interactive console
                </HashLink>{' '}
                enables users to easily and interactively query all REST API endpoints as well as visually
                inspect the results. To this purpose, you need to provide your API key by accessing the REST
                API in the{' '}
                <HashLink to="#console" data-target="#console" data-toggle="tab" className="js-fix-scroll">
                  Interactive console
                </HashLink>{' '}
                and clicking the "Authorize" button (top-right corner, next to the green padlock symbol). A
                popup box named "Available authorizations" will open; copy your API key in the input box and
                click on the "Authorize" button. From now on all your API calls will be automatically
                authorized by means of your API key.
                <br />
                <br />
                <b>⇒ Response formats</b>:  the results of the REST API queries can be retrieved in three
                different formats: JSON, XML and TSV (tabular, tab-separated). By default, the REST API
                provides the results in JSON format. To retrieve results in a different format, add to your
                API call an HTTP header with name equal to <i>accept</i> and value equal to:{' '}
                <i>application/json</i>, <i>application/xml</i> or <i>application/csv</i>.<br />
                <br />
                <b>⇒ Common response structure</b>:  when using the JSON and XML response formats, all REST
                API endpoints have the following shared response fields:
              </p>
              <ul>
                <li>
                  <b>status</b>: the overall status of the API call execution (<i>OK</i> in case of correctly
                  retrieved results).
                </li>
                <li>
                  <b>error</b>: in case of error, details on the type of error.
                </li>
                <li>
                  <b>paging</b>: when using pagination, total number of results retrieved by the query,
                  current page number and number of results included in the current page.
                </li>
                <li>
                  <b>warnings</b>: a list of textual warning messages, if any.
                </li>
                <li>
                  <b>requestpar</b>: the API call parameters provided by the user.
                </li>
                <li>
                  <b>userinfo</b>: information concerning the user, including its profile.
                </li>
                <li>
                  <b>payload</b>: the actual results of the API call (the format depends on the specific API
                  call invoked).
                </li>
              </ul>
              <p>
                At the end of the REST API{' '}
                <HashLink to="#console" data-target="#console" data-toggle="tab" className="js-fix-scroll">
                  Interactive console
                </HashLink>
                , a detailed description of the fields of the REST API common response structure is available
                (see <i>Response</i>, <i>ResponseError</i>, <i>Paging</i> and <i>UserInfo</i> data models, in
                the Models section at the end of the REST API{' '}
                <HashLink to="#console" data-target="#console" data-toggle="tab" className="js-fix-scroll">
                  Interactive console
                </HashLink>
                ).
                <br />
                <br />
                The content of the <i>payload</i> field of each API call <i>Response</i> depends on the
                specific API endpoint invoked (i.e. /gda, /vda or /dda). In particular, at the end of the REST
                API{' '}
                <HashLink to="#console" data-target="#console" data-toggle="tab" className="js-fix-scroll">
                  Interactive console
                </HashLink>{' '}
                it is possible to access a detailed description of the fields of the <i>payloads</i>
                of each API endpoint (see <i>GeneDiseaseAssocDTO</i>, <i>VariantDiseaseAssocDTO</i> and{' '}
                <i>DiseaseDiseaseAssocDTO</i> data models, in the Models section at the end of the REST API{' '}
                <HashLink to="#console" data-target="#console" data-toggle="tab" className="js-fix-scroll">
                  Interactive console
                </HashLink>
                ).
                <br />
                <br />
                <b>⇒ Pagination</b>:  the endpoints of the REST API (i.e. /gda, /vda and /dda) support the
                pagination of search results. Given a specific configuration of query parameters, by relying
                on the pagination mechanism, it is possible to retrieve up to 10,000 search results (i.e.
                gene-disease, variant-disease or disease-disease associations). The number of search results
                present in each page is equal to 100. Pagination enables the access to the first 100 pages of
                search results (i.e. up to 10,000 search results): page numbers start from 0. The last page
                accessible is page number 99 (i.e. the 100th page).
                <br />
                <br />
                <b>⇒ Version endpoint</b>:  the REST API provides a public endpoint to obtain information on
                the current version of DISGENET data accessible by the REST API (i,e. /public/version, see the
                REST API{' '}
                <HashLink to="#console" data-target="#console" data-toggle="tab" className="js-fix-scroll">
                  Interactive console
                </HashLink>{' '}
                for more details).
              </p>
            </div>

            <div className={`tab-pane ${hash === '#examples' ? 'active show' : ''}`} id="examples">
              <h2>Code examples</h2>
              <div className="divider" />
              <p>
                Below you can find Python and R code snippets showing how to programmatically interact with
                the REST API by means of YOUR_API_KEY.
                <br />
                <br />
                <b>⇒ Query the REST API with your API key</b>:  the code below shows how to retrieve
                gene-disease associations related to the gene <i>APP amyloid beta precursor protein</i> with
                NCBI ID equal to{' '}
                <a href="https://www.ncbi.nlm.nih.gov/gene/351" target="_blank" rel="noreferrer">
                  351
                </a>{' '}
                (<i>gene_ncbi_id</i> parameter equal to 351). To this purpose we call the gene-disease
                associations endpoint (i.e. gda endpoint, see the REST API{' '}
                <HashLink to="#console" data-target="#console" data-toggle="tab" className="js-fix-scroll">
                  Interactive console
                </HashLink>{' '}
                for more details). We retrieve the top-100 gene-disease associations involving such gene,
                ordered by descending gene-disease score, by asking for the first page of results (
                <i>page_number</i> parameter equal to 0).
              </p>
              {}
              <ul
                className="nav nav-tabs"
                style={{
                  marginTop: '10px',
                }}
                id="codeExample"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#python"
                    role="tab"
                    aria-controls="python"
                    aria-selected="true"
                  >
                    Python
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#rcode"
                    role="tab"
                    aria-controls="rcode"
                    aria-selected="false"
                  >
                    R
                  </a>
                </li>
              </ul>
              <div
                className="tab-content"
                style={{
                  backgroundColor: 'white',
                }}
                id="codeExampleContent"
              >
                <div
                  className="tab-pane fade show active"
                  style={{
                    padding: '10px',
                  }}
                  id="python"
                  role="tabpanel"
                  aria-labelledby="python-tab"
                >
                  <pre>
                    import requests{'\n'}
                    import json{'\n'}
                    import time{'\n'}
                    {'\n'}
                    <b># Provide your API key</b> {'\n'}API_KEY = "YOUR_API_KEY"{'\n'}
                    {'\n'}
                    <b># Specify query parameters by means of a dictionary</b> {'\n'}params = {'{'}
                    {'}'}
                    {'\n'}
                    <b># ...retrieve disease associated to gene with NCBI ID equal to 351</b> {'\n'}
                    params["gene_ncbi_id"] = "351"{'\n'}
                    <b># ...retrieve the first page of results (page number 0)</b> {'\n'}
                    params["page_number"] = "0"{'\n'}
                    {'\n'}
                    <b># Create a dictionary with the HTTP headers of your API call</b> {'\n'}
                    HTTPheadersDict = {'{'}
                    {'}'}
                    {'\n'}
                    <b># Set the 'Authorization' HTTP header equal to API_KEY (your API key)</b> {'\n'}
                    HTTPheadersDict['Authorization'] = API_KEY{'\n'}
                    <b>
                      # Set the 'accept' HTTP header to specify the response format: one of
                      'application/json', 'application/xml', 'application/csv'
                    </b>{' '}
                    {'\n'}HTTPheadersDict['accept'] = 'application/json'
                    {'\n'}
                    {'\n'}
                    <b># Query the gda summary endpoint</b> {'\n'}response =
                    requests.get("https://api.disgenet.com/api/v1/gda/summary",\
                    {'\n'}
                    {'                        '}params=params, headers=HTTPheadersDict, verify=False)
                    {'\n'}
                    {'\n'}
                    <b>
                      # If the status code of response is 429, it means you have reached one of your query
                      limits {'\n'}# You can retrieve the time you need to wait until doing a new query in the
                      response headers
                    </b>{' '}
                    {'\n'}if not response.ok:
                    {'\n'}
                    {' '.repeat(4)}if response.status_code == 429:
                    {'\n'}
                    {' '.repeat(8)}while response.ok is False:
                    {'\n'}
                    {' '.repeat(12)}print("You have reached a query limit for your user. Please wait {'{}'}{' '}
                    seconds until next query".format(\
                    {'\n'}
                    {' '.repeat(12)}
                    response.headers['x-rate-limit-retry-after-seconds']))
                    {'\n'}
                    {' '.repeat(12)}
                    time.sleep(int(response.headers['x-rate-limit-retry-after-seconds']))
                    {'\n'}
                    {' '.repeat(12)}print("Your rate limit is now restored")
                    {'\n'}
                    {'\n'}
                    {' '.repeat(12)}
                    <b># Repeat your query</b>
                    {'\n'}
                    {' '.repeat(12)}response = requests.get("https://api.disgenet.com/api/v1/gda/summary",\
                    {'\n'}
                    {' '.repeat(36)}params=params, headers=HTTPheadersDict, verify=False)
                    {'\n'}
                    {' '.repeat(12)}if response.ok is True:
                    {'\n'}
                    {' '.repeat(16)}break
                    {'\n'}
                    {' '.repeat(12)}else:
                    {'\n'}
                    {' '.repeat(16)}continue
                    {'\n'}
                    {'\n'}
                    <b>
                      # Parse response content in JSON format since we set 'accept:application/json' as HTTP
                      header
                    </b>{' '}
                    {'\n'}response_parsed = json.loads(response.text)
                    {'\n'}print('STATUS: {'{'}
                    {'}'}'.format(response_parsed["status"])){'\n'}
                    print('TOTAL NUMBER OF RESULTS: {'{'}
                    {'}'}
                    '.format(response_parsed["paging"]["totalElements"]))
                    {'\n'}print('NUMBER OF RESULTS RETRIEVED BY CURRENT CALL (PAGE NUMBER {'{'}
                    {'}'}): {'{'}
                    {'}'}'.format(\{'\n'}
                    {'      '}
                    response_parsed["paging"]["currentPageNumber"],
                    response_parsed["paging"]["totalElementsInPage"]))
                    {'\n'}
                  </pre>
                </div>
                <div
                  className="tab-pane fade"
                  style={{
                    padding: '10px',
                  }}
                  id="rcode"
                  role="tabpanel"
                  aria-labelledby="rcode-tab"
                >
                  <pre>
                    library(httr){'\n'}library(jsonlite){'\n'}
                    {'\n'}
                    <b># Provide your API key</b> {'\n'}api_key &lt;- "YOUR_API_KEY"{'\n'}
                    {'\n'}
                    <b>
                      # Specify the response format: one of 'application/json', 'application/xml',
                      'application/csv'
                    </b>{' '}
                    {'\n'}resp_format &lt;- "application/json"{'\n'}
                    {'\n'}
                    <b># Query the gda endpoint by specifying the following parameters:</b>
                    {'\n'}
                    <b># - gene_ncbi_id=351: retrieve disease associated to gene with NCBI ID equal to 351</b>
                    {'\n'}
                    <b># - page_number=0: retrieve the first page of results (page number 0)</b>
                    {'\n'}
                    <b>
                      # and providing your API key (api_key) and the response format (resp_format) as HTTP
                      headers
                    </b>
                    {'\n'}res &lt;-
                    GET("https://api.disgenet.com/api/v1/gda/summary?gene_ncbi_id=351&page_number=0",
                    {'\n'}
                    {'           '}add_headers(.headers = c('Authorization'= api_key,{'\n'}
                    {'                                    '}'accept' = resp_format))){'\n'}
                    {'\n'}
                    <b>
                      # If the status code of response is 429, it means you have reached one of your query
                      limits
                    </b>
                    {'\n'}
                    <b>
                      # You can retrieve the time you need to wait until doing a new query in the response
                      headers
                    </b>
                    {'\n'}
                    if(res$status_code == 429) &#123;
                    {'\n'}
                    {'    '}timetoWait &lt;- res$headers$`x-rate-limit-retry-after-seconds`
                    {'\n'}
                    {'    '}print(paste0("You have reached a query limit for your user. Please wait ",
                    timetoWait, " seconds until next query"))
                    {'\n'}
                    {'    '}Sys.sleep(timetoWait)
                    {'\n'}
                    {'    '}print("Your rate limit is now restored.")
                    <b>
                      {'\n'}
                      {'    '}# Repeat your query
                    </b>
                    {'\n'}
                    {'    '}res &lt;-
                    GET("http://api.disgenet.com/api/v1/gda/summary?gene_ncbi_id=351&page_number=0",
                    {'\n'}
                    {'           '}add_headers(.headers = c('Authorization'= api_key,{'\n'}
                    {'                                    '}'accept' = resp_format)))
                    {'\n'}
                    &#125;
                    {'\n'}
                    {'\n'}
                    <b>
                      # Extract the content type of the response and parse the JSON content since we set
                      'accept:application/json' as HTTP header
                    </b>{' '}
                    {'\n'}http_type(res) <b># "application/json"{'\n'}</b>
                    {'\n'}query_result &lt;- fromJSON(rawToChar(res$content))
                    {'\n'}query_result
                    {'\n'}
                    {'    '}
                    {'\t'}
                    {'\t'}
                    {'  '}
                  </pre>
                </div>
              </div>
            </div>

            <div className={`tab-pane ${hash === '#support' ? 'active show' : ''}`} id="support">
              <h2>Information and support</h2>
              <div className="divider" />
              <p>
                For any issue concerning the REST API of DISGENET, please contact{' '}
                <a href="mailto:info@disgenet.com" title="copy">
                  info@disgenet.com
                </a>
                .
              </p>
            </div>
            <div className={`tab-pane ${hash === '#installcyto' ? 'active show' : ''}`} id="#installcyto">
              <h2>Installation</h2>
              <div className="divider" />
              <p>
                The DISGENET Cytoscape App is available from the Cytoscape App Store:
                <a href="https://apps.cytoscape.org/apps/disgenetapp" target="_blank" rel="noreferrer">
                  {' '}
                  https://apps.cytoscape.org/apps/disgenetapp
                </a>
              </p>
            </div>

            {rolesAllowedDownloadDump.includes(userRole) && (
              <div className={`tab-pane ${hash === '#download' ? 'active show' : ''}`} id="download">
                <h4>DISGENET download</h4>
                <div className="divider" />
                <p>
                  Only users with a PREMIUM profile can download the files. For information on how to obtain a
                  DISGENET license, contact us at <a href="mailto:info@disgenet.com">info@disgenet.com</a>. To
                  download DISGENET, please enter your api key.{' '}
                  <React.Fragment>
                    <DownloadDump />
                  </React.Fragment>
                </p>
                <p>Use this command for downloading the database from the command line: </p>
                <pre className="dump_code">
                  <code>
                    {' '}
                    wget --output-document=disgenet_dump.gz --post-data 'api-key=API-KEY'
                    http://api.disgenet.com/dump/download
                  </code>
                </pre>
                <p>Use these commands to verify the database was downloaded correctly:</p>
                <p>
                  <p>
                    <strong>Windows:</strong>
                  </p>
                  <pre className="dump_code">
                    <code>
                      <ol>
                        <li>
                          1. Download and install a utility that can generate MD5 hashes, such as{' '}
                          <a href="https://learn.microsoft.com/en-us/windows-server/administration/windows-commands/certutil">
                            CertUtil
                          </a>
                          .
                        </li>
                        <li>2. Open Command Prompt.</li>
                        <li>3. Navigate to the directory containing the .tar.gz file you want to check.</li>
                        <li>4. Run the following command: CertUtil -hashfile yourfile.tar.gz MD5</li>
                        <li>5. Compare the output with the expected MD5 hash.</li>
                      </ol>
                    </code>
                  </pre>
                  <p>
                    <strong>macOS and Linux:</strong>
                  </p>
                  <pre className="dump_code">
                    <code>
                      <ol>
                        <li>1. Open Terminal.</li>
                        <li>2. Navigate to the directory containing the .tar.gz file you want to check.</li>
                        <li>
                          3. Run the following command: md5 yourfile.tar.gz (macOS) or md5sum yourfile.tar.gz
                          (Linux)
                        </li>
                        <li>4. Compare the output with the expected MD5 hash.</li>
                      </ol>
                    </code>
                  </pre>
                </p>
              </div>
            )}

            <div className={`tab-pane ${hash === '#assistant' ? 'active show' : ''}`} id="download2">
              <h4>
                The DISGENET AI assistant<i class="bi bi-stars"></i> (beta version).
              </h4>
              <p>
                It combines the power of generative AI with the capabilities of the DISGENET REST API. You can
                ask questions in natural language and the assistant will translate them to structured queries
                to the database exploiting all the features available in DISGENET, and summarize the main
                results for you. The DISGENET AI assistant lowers the barrier to access DISGENET for users
                with no programming skills and no knowledge about DISGENET data schema. Try it{' '}
                <a href="/Assistant" target="_blank">
                  here
                </a>
                .
              </p>
            </div>

            {/* <div className={`tab-pane ${hash === "#installcyto" ? "active show" : ""}`} id="installcyto">
	                    <h4>Installation</h4>
	                    <div className="divider" />
	                    <p>
                        Operating System: Windows, Mac and Linux. More information on systems requirements {' '}
                        <a href="https://cytoscape.org/release_notes_3_9_0.html#system_requirements" target="_blank">here</a>.
                      </p>
                      <p>
                        The DISGENET Cytoscape App is compatible with the Cytoscape 3.x versions.
                        We recommend Cytoscape version 3.9.0 or later. 
                        The steps for downloading and installing the latest version of Cytoscape are described at {' '}
                        <a href="http://www.cytoscape.org/" target="_blank">http://www.cytoscape.org/</a>.
                      </p>

                      <ol className="cytoscape-steps">
                        <li>
                          Download the file disgenet-app.tar.gz to your computer and decompress it in a file location.
                        </li>
                        <li>
                          Launch Cytoscape.
                        </li>
                        <li>
                          In the <strong>Menu</strong>, choose the <strong>Apps</strong> tab and go to the {' '}
                          <strong>App Manager.</strong>
                        </li>
                        <li>
                          Go to <strong>Install Apps {String.fromCharCode(8594)} Install From File...</strong>
                        </li>
                        <li>
                          Choose the file DISGENET-app-1.0.0.jar from the file location where you decompressed the {' '}
                          DISGENET App files.
                        </li>
                        <li>
                          You will see the DISGENET App among the Apps currently installed.
                        </li>
                        <li>
                          <span className="underline-text">Indicate the location of the database file:</span> From the {' '}
                          <strong>Apps</strong> tab, go to <strong>DISGENET</strong> and click on <strong>Change DB folder.</strong> {' '}
                          Follow the steps to set the file location where you saved the database in step 1 (file disgenet_sqlite_v18.db). 
                        </li>
                        <li>
                          Once you provided the file location for the database, the app is ready for use. {' '}
                          From the <strong>Apps</strong> tab, go to <strong>DISGENET</strong> and click on <strong>Start DISGENET</strong>.
                        </li>
                      </ol>
	                </div> */}

            {/* <div className={`tab-pane ${hash === "#launchcyto" ? "active show" : ""}`} id="launchcyto">
	                    <h4>Launching</h4>
                      <ol className="cytoscape-steps">
                        <li>
                          Launch Cytoscape.
                        </li>
                        <li>
                          In the <strong>Menu</strong>, choose the <strong>Apps</strong> tab, then <strong>DISGENET</strong> {" "}
                          and click on <strong>Start DISGENET</strong>.
                        </li>
                        <li>
                          The App is ready to use once the DISGENET control panel is visible as shown below (Figure 2).
                        </li>
                      </ol>
                      <figure className="tools-section-image launching-image">
                        <img
                          className="launching-image-settings"
                          src="images/launching-image-resized.png"
                          alt="Launching Image Example"
                        />
                        <figcaption>Figure 2: DISGENET control panel</figcaption>
                    </figure>
	                </div> */}

            {/* <div className={`tab-pane ${hash === "#automatecyto" ? "active show" : ""}`} id="automatecyto">
	                    <h4>Automation</h4>
                      <p>
                      The Cytoscape Automation is a set of tools that allows users to create workflows executed {" "}
                      entirely within Cytoscape or by external tools (such as RStudio or Jupyter). The DISGENET {" "}
                      Automation API allows querying the DISGENET Cytoscape App from an external {" "}
                      environment such as R or Python, using REST calls.
                      </p>
                      <p>
                      The DISGENET Cytoscape App includes an automation module with a set of REST endpoints. {" "}
                      The documentation of the endpoints is available at the Swagger page of Cytoscape that can be {" "}
                      accessed by going to the Cytoscape menu and clicking {" "}
                      <strong>Help {String.fromCharCode(8594)} Automation {String.fromCharCode(8594)} CyRest API</strong>. {" "} 
                      The API is accessible directly through the Swagger user interface (Figure 3) within Cytoscape or by using any {" "}
                      REST-enabled client.
                      </p>
                      <figure className="tools-section-image automation-image">
                        <img
                          src="images/automation-image1.png"
                          alt="Automation Image Example"
                        />
                        <figcaption>Figure 3: Swagger documentation for the CyREST API</figcaption>
                      </figure>

                      <ul
                        className="nav nav-tabs"
                        style={{
                          marginTop: '2.5rem',
                        }}
                        id="automationExample"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <a
                          className="nav-link active"
                          id="home-tab"
                          data-toggle="tab"
                          href="#autoPython"
                          role="tab"
                          aria-controls="autoPython"
                          aria-selected="true"
                          >
                            Python - Automation Script
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            id="profile-tab"
                            data-toggle="tab"
                            href="#autoRCode"
                            role="tab"
                            aria-controls="autoRCode"
                            aria-selected="false"
                          >
                            R - Automation Script
                          </a>
                        </li>
                      </ul>
                      <div
                      className="tab-content"
                      style={{
                        backgroundColor: 'white',
                      }}
                      id="automationExampleContent"
                      >
                        <div
                          className="tab-pane fade show active"
                          style={{
                            padding: '10px',
                          }}
                          id="autoPython"
                          role="tabpanel"
                          aria-labelledby="python-tab-automation"
                        >
                          <pre>
                            <strong>
                              """ {'\n'}
                              2022 MedBioinformatics Solutions SL {'\n'}
                              Example script to illustrate the use of the Automation module of DISGENET  Cytoscape App {'\n'}
                              Before executing the script, ensure you have Cytoscape running and the DISGENET Cytoscape App installed {'\n'}
                              """
                            </strong>
                            <div>
                              import json {'\n'}
                              import requests {'\n'}
                            </div>
                            {'\n'}

                            <div>
                              {`def disgenetRestUrl(netType,host="127.0.0.1",port=1234,version="v18"):
    """
    We will create an object for the REST calls to the DISGENET automation module. The parameters are:
    netType: A string containing the type of the network to be created (gene-disease-net,variant-disease-net)
    host: The host of the url.
    port: The listening port, by default 1234.
    version: The version of the database, currently v18 (the version can be checked in the version endpoint).
    The function returns a string in url format (url), with the given parameters.
    """
    url = "http://"+host+":"+str(port)+"/disgenet-plus/"+version+"/"+netType
    return url
  `}
                              
                            </div>
                            {'\n'}

                            <div>
                              {`def disgenetRestCall(netType,netParams):
    """
    Next, we will create an object that will execute the REST calls to the DISGENET automation module in Cytoscape
    and retrieve the operation results. The parameters are:
    netType: A string containing the type of the network to be created (gene-disease-net,variant-disease-net)
    netParams: A list with the parameters to create the network.
    The function returns the object result, a list with the results of the operation.
    """
    url = disgenetRestUrl(netType)
    HEADERS = {'Content-Type': 'application/json'}
    restCall = requests.post(url,data=json.dumps(netParams),headers=HEADERS)
    result = restCall.json()
    return result
  `}
                              
                            </div>
                             {'\n'}

                            <div>
                              {`def printHash(hashToPrint):
    """
    Prints all the key-values found in a python dictionary(hash) with a single line for entry and the format key - value.
    @param hashToPrint hash to be printed.
    """
    for key, value in hashToPrint.items():
        print(key+" - "+value)
  `}
                              
                            </div>
                             {'\n'}

                            <div>
                              {`def printOperationResult(operationResult):
    """
    Prints the response of the REST call to the DISGENET automation module.
    @param hashToPrint hash to be printed.
    """
    message = operationResult["message"]
    print(message)
    if 'networkResult' in operationResult:
        netResult = operationResult["networkResult"]
        printHash(netResult)
    elif 'errors' in operationResult:
        errors = operationResult["errors"]
        printHash(errors)
  `}
                              
                            </div>
                            {'\n'}

                            <strong># Example of params for the gene-disease network.</strong>
                            <div>{`geneDisParams = {
    "source" : "ALL",
    "assocType" : "Genetic Variation",
    "diseaseSearch" : "Asthma",
    "geneSearch" : "",
    "initialScoreValue" : "0.0",
    "finalScoreValue" : "1.0"
    }`}
                            </div>
                            {'\n'}

                            <strong># Generate the gene-disease network, and show the resuland show the results.ts.</strong>
                            <div>printOperationResult(disgenetRestCall("gene-disease-net", geneDisParams))</div>
                            {'\n'}

                            <strong># Example of params for the variant-disase network.</strong>
                            <div>{`variantDisParams = {
    "source" : "ALL",
    "diseaseSearch" : "Allergic asthma;Adult onset asthma;IgE-mediated allergic asthma",
    "geneSearch" : " ",
    "variantSearch" : " ",
    "initialScoreValue" : "0.0",
    "finalScoreValue" : "1.0",
    "showGenes" : "false"
    }`}
                            </div>
                            {'\n'}

                            <strong># Generate the variant-disease network, and show the results.</strong>
                            <div>printOperationResult(disgenetRestCall("variant-disease-net", variantDisParams))</div>
                            {'\n'}

                          </pre>
                        </div>
                        <div
                          className="tab-pane fade"
                          style={{
                            padding: '10px',
                          }}
                          id="autoRCode"
                          role="tabpanel"
                          aria-labelledby="rcode-tab-automation"
                        >
                          <pre>
                          <strong>
                              # ©2022 MedBioinformatics Solutions SL {'\n'}
                              # Example script to illustrate the use of the Automation module of DISGENET Cytoscape App {'\n'}
                              # Before executing the script, ensure you have Cytoscape running and the DISGENET Cytoscape App installed {'\n'}
                              # Requirements: httr, RCy3 {'\n'}
                            </strong>
                              <div>
                                {`if(!"RCy3" %in% installed.packages()) {
  if (!requireNamespace("BiocManager", quietly=TRUE))
    install.packages("BiocManager")
    BiocManager::install("RCy3")
}
library(RCy3)`}     
                              </div>

                              {'\n'}
                              <strong># Install and load the library httr</strong>
                              <div>
                                install.packages("httr") {'\n'}
                                library(httr)
                              </div>

                              {'\n'}
                              <strong># Launch Cytoscape</strong>
                              <div>cytoscapePing()</div>

                              <strong>
                              # We will create an object for the REST calls to the DISGENET automation module. The parameters are: {'\n'}
                              # <u>netType</u>: A string containing the type of the network to be created (gene-disease-net,variant-disease-net) {'\n'}
                              # <u>host:</u> The host of the url. {'\n'}
                              # <u>port:</u> The listening port, by default 1234. {'\n'}
                              # <u>version:</u> The version of the database, currently v18 (the version can be checked in the version endpoint). {'\n'}
                              # The function returns a string in url format (url), with the given parameters. {'\n'}
                              </strong>
                              <div>
                                {`disgenetRestUrl<-function(netType,host="127.0.0.1",port=1234,version="v18"){
  if(is.null(netType)){
    print("Network type not specified.")
  }else{
    url<-sprintf("http://%s:%i/disgenet-plus/%s/%s",host,port,version,netType)
  }
  return (url)
}`}
                              </div>
                              {'\n'}
                              
                              <strong># Creating the url for GDAs</strong>
                              <p>disgenetRestUrl(netType = "gene-disease-net")</p>

                              <strong>
                              # Next, we will create an object that will execute the REST calls to the DISGENET automation module in {'\n'}
                              # Cytoscape and retrieve the operation results. The parameters are: {'\n'}
                              # <u>netType:</u> A string containing the type of the network to be created (gene-disease-net,variant-disease-net) {'\n'}
                              # <u>netParams:</u> A list with the parameters to create the network. {'\n'}
                              # The function returns the object result, a list with the results of the operation. {'\n'}
                              </strong>
                              <div>
                                {`disgenetRestCall<-function(netType,netParams){
  url<-disgenetRestUrl(netType)
  restCall<-POST(url, body = netParams, encode = "json")
  result<-content(restCall,"parsed")
  return(result)
}`}
                              </div>
                              {'\n'}
                              
                              <strong># Example search 1 GDA</strong>
                              <div>
                                {`geneDisParams <- list(
  source = "UNIPROT",
  assocType = "Biomarker",
  diseaseClass = "Neoplasms",
  diseaseSearch = " ",
  geneSearch = " ",
  initialScoreValue = "0.8",
  finalScoreValue = "1.0"
)`}
                              </div>
                              {'\n'}

                              <strong># Generate the gene-disease network in Cytoscape</strong>
                              <div>{`geneDisResult <- disgenetRestCall("gene-disease-net",geneDisParams)`}</div>
                              {'\n'}

                              <strong># Example search 2 GDA</strong>
                              <div>
                                {`geneDisParams <- list(
  source = "ALL",
  diseaseSearch = "Asthma",
  assocType = "Genetic Variation",
  geneSearch = " ",
  initialScoreValue = "0.0",
  finalScoreValue = "1.0"
)`}
                              </div>
                              {'\n'}

                              <strong># Generate the gene-disease network in Cytoscape</strong>
                              <div>{`geneDisResult <- disgenetRestCall("gene-disease-net",geneDisParams)`}</div>
                              {'\n'}
                              {'\n'}

                              <strong>
                                # Searching for VDA {'\n'}
                                # Example 1 VDA
                              </strong>
                              <div>
                                {`variantDisParams <- list(
  source= "ALL",
  diseaseSearch= "Allergic asthma;Adult onset asthma;IgE-mediated allergic asthma",
  initialScoreValue= "0.0",
  finalScoreValue = "1.0",
  showGenes= "false"
)`}
                              </div>
                              {'\n'}

                              <strong># Generate the variant-disease network in Cytoscape</strong>
                              <div>{`variantDisResult <- disgenetRestCall("variant-disease-net",variantDisParams)`}</div>
                              {'\n'}

                              <strong># Change the layout of the network</strong>
                              <div>{`layoutNetwork("kamada-kawai" , network = variantDisResult$networkResult$networkName)`}</div>
                              {'\n'}
                          </pre>
                        </div>
                      </div>
	                </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default APIContent;
