import { Columns } from '../../Table/Columns';

function ColumnList({ columnVisibilityModel, setColumnVisibilityModel }) {
  // displayFragment in an object the same shape as columnVisibilityModel,
  // but with the values set to corresponding 'displayName' from Columns.jsx
  const displayFragment = Object.keys(columnVisibilityModel).reduce((acc, key) => {
    const fragment = Columns.find(column => column.name === key).header;
    acc[key] = fragment;
    return acc;
  }, {});

  function handleChange(key) {
    setColumnVisibilityModel(prevItems => ({
      ...prevItems,
      [key]: !prevItems[key],
    }));
  }

  return (
    <li className="column-item">
      {Object.keys(columnVisibilityModel).map(key => (
        <label key={key} className="column-label col-3">
          <input
            type="checkbox"
            onChange={() => handleChange(key)}
            checked={columnVisibilityModel[key]}
            className="custom-checkbox"
          />{' '}
          <span>{displayFragment[key]}</span>
        </label>
      ))}
    </li>
  );
}

export default ColumnList;
