import React from 'react';
import { TopRightMenu } from '../NavMenus/TopNavigation';
import Logo from '../Common/Logo';
import SearchBar from '../Search/SearchBar';

export default function SearchHeader() {
  return (
    <section className="search-header">
      <div className="header-content">
        <div>
          <Logo />
        </div>
        <div>
          <TopRightMenu />
        </div>
      </div>
      <div className="dividerBottom dividerCab mt-3 pt-3" />
      <div className="containerCab">
        <div data-wow-delay="0.5s" className="col-12">
          <div className="webdesigntuts-workshop">
            <SearchBar />
          </div>
        </div>
      </div>
      <div className="dividerBottom dividerCab mt-5 pt-5" />
    </section>
  );
}
