import { useState } from 'react';

function UseInput(validationLogic) {
  const [inputValue, setInputValue] = useState('');
  const [isTouched, setIsTouched] = useState(false);

  function valueChangeHandler(ev) {
    setInputValue(ev.target.value);
  }

  function valueBlurHandler() {
    setIsTouched(true);
  }

  const isInputValid = validationLogic(inputValue);
  const isInputInvalid = isTouched && !isInputValid;

  const inputClassName = isInputInvalid ? 'warning-label-form' : '';

  function resetInput() {
    setInputValue('');
    setIsTouched(false);
  }

  return {
    value: inputValue,
    isInputValid,
    isInputInvalid,
    inputClassName,
    valueChangeHandler,
    valueBlurHandler,
    resetInput,
  };
}

export default UseInput;
