import { values } from 'lodash';

function getDownloadColumnsMap() {
  const keyValue = [
    // disease
    ['numVariantsAssociatedToDisease', 'num_variants'],
    ['numGenesAssociatedToDisease', 'num_genes'],
    // drugs
    ['chemUMLSCUI', 'chem_cuis'],
    ['chemShowName', 'chem_name'],
    // gda/summary
    ['chemUMLSCUIs', 'chem_cuis'],
    ['diseaseUMLSCUI', 'disease_cui'],
    ['geneNcbiID', 'gene_ncbi'],
    ['geneDSI', 'dsi'],
    ['geneDPI', 'dpi'],
    ['genepLI', 'pli'],
    ['EL', 'el'],
    ['EI', 'ei'],
    ['geneProteinClassNames', 'gp_class_names'],
    // gda/evidences
    ['associationType', 'association_type'],
    // vda/summary
    ['variantStrID', 'variant_name'],
    ['variantDSI', 'dsi'],
    ['variantDPI', 'dpi'],
    ['diseaseName', 'disease_name'],
    ['geneNcbiIDs', 'gene_ncbi'],
    ['geneEnsemblIDs', 'gene_ensembl'],
    // vda/evidences
    ['diseaseType', 'disease_type'],
    ['geneSymbol', 'gene_symbol'],
    ['mostSevereConsequences', 'v_msc'],
    ['sentenceHTML', 'sentence_html'],
    // genes/GDAs/summary
    ['diseaseClassName_MSH', 'disease_msh'],
    ['diseaseClassName_UMLS', 'disease_umls'],
    ['diseaseClassName_DO', 'disease_do'],
    ['diseaseClassName_HPO', 'disease_hpo'],
    // variants/all
    ['strID', 'variant'],
    ['DSI', 'dsi'],
    ['DPI', 'dpi'],
    ['DBSNPclass', 'class'],
    ['Alleles', 'alleles'],
    ['numDiseasesAssociatedToVariant', 'num_diseases'],
    ['numPublications', 'num_publications'],
    ['firstRef', 'first_ref'],
    ['lastRef', 'last_ref'],
    // gene/all
    ['proteins', 'protein'],
    ['PLI', 'pli'],
    ['ncbiID', 'gene_ncbi'],
    ['proteinClassIDs', 'protein_class'],
    ['pathwayName', 'pathway_name'],
    ['pathwayID', 'pathway_id'],
    // gda/all
    ['geneProteinStrIDs', 'genep_str_ids'],
    //
    ['score', 'score'],
    ['numPMIDsupportingAssociation', 'num_pmid_assoc'],
    ['numberChemsIncludedInEvidence', 'number_chem_evidence'],
    ['numberPmidsWithChemsIncludedInEvidence', 'num_pmids_evid'],
    ['numDBSNPsupportingAssociation', 'num_var_dis'],
    ['yearInitial', 'year_initial'],
    ['yearFinal', 'year_final'],
    ['ei', 'ei'],
    ['geneNcbiType', 'gene_type'],
    [' reference,  reference'],
    ['reference_type, reference_type'],
  ];

  return new Map(keyValue);
}

export default function buildDownloadEndpoint({
  extension,
  columnVisibilityModel,
  view,
  source,
  tab,
  searchItems,
  titleGrid,
}) {
  const colMap = getDownloadColumnsMap();

  const selectedColumns = Object.entries(columnVisibilityModel)
    .filter(([key, value]) => value)
    .map(([key]) => colMap.get(key) || key);

  const col_sel = selectedColumns.join(',');

  // second, map the visible columns to the backend column names

  // view mappings
  let viewType;
  if (tab !== 'DDA') {
    if (titleGrid === 'Summary') {
      viewType = '/summary';
    } else if (titleGrid === 'Evidence') {
      viewType = '';
    } else {
      viewType = '';
    }
  }

  let filterParam = null;
  switch (view) {
    case 'GENES':
      filterParam = 'gene_ncbi_id';
      break;
    case 'DISEASES':
      filterParam = 'disease';
      break;
    case 'VARIANTS':
      filterParam = 'variant';
      break;
    case 'CHEMICALS':
      filterParam = 'chem_cuis_init';
      break;
  }

  if (viewType === '/all') {
    filterParam = null;
  }

  // The backend idents value:
  const backendDiseaseVocabPrefix = viewType !== '/all' && filterParam === 'disease' ? 'UMLS_' : '';
  const filterValue = searchItems.map(item => backendDiseaseVocabPrefix + item.id).join(',');

  // tab mappings
  let tabType = tab?.toLowerCase().replace(/s$/, '');
  tabType = tabType === 'chemical' ? 'chem' : tabType;

  const paramsMap = {
    source: source,
    ...(filterParam && { [filterParam]: filterValue }),
    col_sel: col_sel,
  };

  // Construct the endpoint URL
  const queryParams = new URLSearchParams();

  Object.entries(paramsMap).forEach(([param, value]) => {
    if (value !== null && value !== undefined && value !== '') {
      queryParams.append(param, value);
    }
  });

  const baseEndpoint = tabType === 'dda' ? `api/${tabType}` : `api/${tabType}${viewType}`;
  const endPoint = `${baseEndpoint}/${extension}?${queryParams.toString().replace(/%2C/g, ',')}`;

  return endPoint;
}
/*
random patterns are still missing -> tsv?source=ALL&disease=UMLS_C0028754&min_score=0.9950&max_score=1.0050&col_sel=variant_name,gene_symbol
&min_score=0.9950&max_score=1.0050&

/api/gda/summary/tsv?source=ALL&disease=UMLS_C0028754&col_sel=disease_name
/api/gda/summary/tsv?source=ALL&disease=UMLS_C0028754&col_sel=disease_name
*/
