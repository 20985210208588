import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { userAppContext } from '../../Context/Context';
import $ from 'jquery';

function validate(input) {
  if ($(input).attr('type') == 'email' || $(input).attr('name') == 'email') {
    if (
      $(input)
        .val()
        .trim()
        .match(
          /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/,
        ) == null
    ) {
      return false;
    }
  } else {
    if ($(input).val().trim() == '') {
      return false;
    }
  }
}

function showValidate(input) {
  var thisAlert = $(input).parent();

  $(thisAlert).addClass('alert-validate');
}

function hideValidate(input) {
  var thisAlert = $(input).parent();

  $(thisAlert).removeClass('alert-validate');
}

export default function NewPassword({ setReset, name }) {
  const [errorMessage, setErrorMessage] = useState();
  const { setIsAuthenticated, setAuthorization, setGroups } = userAppContext();
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');

  const validar = () => {
    var input = $('.validate-input .input100');
    var check = true;

    for (var i = 0; i < input.length; i++) {
      if (validate(input[i]) == false) {
        showValidate(input[i]);
        check = false;
      }
    }
    if (password != rePassword) {
      setErrorMessage('Password and their confirmation do not match ');
    }

    return check && password == rePassword;
  };

  const handleSubmit = evt => {
    evt.preventDefault();
    setErrorMessage('');
    if (validar()) {
      Auth.forgotPasswordSubmit(name.trim(), code.trim(), password.trim())
        .then(user => {
          setReset(false);
        })
        .catch(err => {
          setErrorMessage(err.message);
        });
    }
  };

  useEffect(() => {
    $('.input100').each(function () {
      $(this).on('blur', function () {
        if ($(this).val().trim() != '') {
          $(this).addClass('has-val');
        } else {
          $(this).removeClass('has-val');
        }
      });
    });
    $('.validate-input .input100').each(function () {
      $(this).on('blur', function () {
        if (validate(this) == false) {
          showValidate(this);
        } else {
          $(this).parent().addClass('true-validate');
        }
      });
    });

    var input = $('.validate-input .input100');
    $('.validate-form').on('submit', function () {
      var check = true;

      for (var i = 0; i < input.length; i++) {
        if (validate(input[i]) == false) {
          showValidate(input[i]);
          check = false;
        }
      }

      return check;
    });

    $('.validate-form .input100').each(function () {
      $(this).focus(function () {
        hideValidate(this);
        $(this).parent().removeClass('true-validate');
      });
    });
  }, []);

  return (
    <>
      <div className="container txt-contenido">
        <div className="login-container">
          <div className="signin-container">
            <div className="col-12">
              <form className=" validate-form" onSubmit={handleSubmit}>
                <span className="mb-5 reset-title">Check your email for the confirmation code:</span>
                <div className="wrap-input100 validate-input" data-validate="Verification code is required">
                  <span className="label-input100" style={{ color: 'white' }}></span>
                  <input
                    style={{ color: 'white' }}
                    onChange={event => setCode(event.target.value)}
                    className="input100"
                    type="text"
                    name="name"
                    placeholder="Enter the confirmation code..."
                  />
                  <span className="focus-input100" />
                </div>

                <div className="wrap-input100 validate-input" data-validate="Password is required">
                  <span className="label-input100" style={{ color: 'white' }}></span>
                  <input
                    onChange={event => setPassword(event.target.value)}
                    name="password"
                    id="password"
                    type="password"
                    className="input100"
                    placeholder="Password"
                  />
                  <span className="focus-input100" />
                </div>
                <div className="wrap-input100 validate-input" data-validate="Password is required">
                  <span className="label-input100" style={{ color: 'white' }}></span>
                  <input
                    style={{ color: 'white' }}
                    onChange={event => setRePassword(event.target.value)}
                    name="cpassword"
                    type="password"
                    className="input100"
                    placeholder="Retype Password"
                  />
                  <span className="focus-input100" />
                </div>
                <a
                  style={{ float: 'right', marginBottom: '20px', color: 'white' }}
                  href="true"
                  onClick={evt => {
                    evt.preventDefault();
                    setReset(false);
                  }}
                >
                  Cancel
                </a>
                <div className="container-contact100-form-btn">
                  <div className="wrap-contact100-form-btn">
                    <div className="contact100-form-bgbtn" />
                    <button className="contact100-form-btn">Send</button>
                  </div>
                  {errorMessage ? <p class="text-danger text-center">{errorMessage}</p> : null}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
