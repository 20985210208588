import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import fetchEndpoint from '../../Services/fetchEndpoint';
import { userAppContext } from '../../Context/Context';

import GeneGeneralInfo from './GeneGeneralInfo';
import GeneSynonyms from './GeneSynonyms';
import PathwayDiagram from './PathwayDiagram';
import ErrorMessage from '../UI/ErrorMessage';

const noAccessTitle = <span>This content is not accessible to your current profile</span>;
const noAccessSubTitle = (
  <span>
    If you want to upgrade your account, please contact us at{' '}
    <a href="mailto:info@disgenet.com">info@disgenet.com</a>
  </span>
);

const resetGeneInfo = {
  symbol: '',
  id: null,
  name: '',
};

export default function GeneContent({ geneInfo, setGeneContent }) {
  const navigate = useNavigate();
  const [geneDetailInfo, setGeneDetailInfo] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const { symbol, id } = geneInfo;
  const {
    authorization,
    setAuthorization,
    isAuthenticated,
    setIsAuthenticated,
    setGroups,
    setAuthError,
    groups,
    restrictPathwayDisplay,
  } = userAppContext();

  const userRole = groups[0];

  useEffect(() => {
    const unauth = isAuthenticated ? '' : '?skey=unauth';

    if (id) {
      const endpoint = `api/gene/detail/${id}${unauth}`;
      fetchEndpoint(
        endpoint,
        authorization,
        setIsAuthenticated,
        setGroups,
        setAuthError,
        setAuthorization,
      ).then(response => {
        if (response?.status === 'OK') {
          setGeneDetailInfo(response.payload);
        }
      });
    }
  }, [geneInfo]);

  const handleCloseModal = () => {
    setGeneDetailInfo(null);
    setGeneContent({ isActive: false, geneInfo: resetGeneInfo });
  };

  return (
    <Fragment>
      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <div className="row">
          <TabList className="col-sm-10 menuTabs">
            <Tab className="react-tabs__tab">General Info</Tab>
            <Tab className="react-tabs__tab">Synonyms</Tab>
            <Tab className="react-tabs__tab">Pathways</Tab>
          </TabList>
          <div className="col-sm-2">
            <div className="row justify-content-end pr-3">
              <i
                className="fa fa-times-circle closeModalGene"
                aria-hidden="true"
                onClick={handleCloseModal}
              />
            </div>
          </div>
        </div>

        <TabPanel className="tab-pane gene-info-tab-content">
          {geneDetailInfo && <GeneGeneralInfo geneDetailInfo={geneDetailInfo} />}
        </TabPanel>

        <TabPanel className="tab-pane gene-info-tab-content">
          {geneDetailInfo && <GeneSynonyms geneDetailInfo={geneDetailInfo} />}
        </TabPanel>

        <TabPanel className="tab-pane gene-info-tab-content">
          {restrictPathwayDisplay ? (
            <ErrorMessage
              title={'This content is not available with your current profile'}
              subtitle={
                <a>
                  <button onClick={() => navigate('/plans')}>
                    View our <a href="/plans">plans</a>
                  </button>
                </a>
              }
            />
          ) : (
            <PathwayDiagram geneSymbol={symbol} />
          )}
        </TabPanel>
      </Tabs>
    </Fragment>
  );
}
