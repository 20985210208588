import React from 'react';

function FeatureHome() {
  return (
    <div className="feature-home">
      <FeatureItem
        imageUrl="../../static/images/icons/iconsPink/043.svg"
        text="Scattered biomedical data across various databases and repositories creates <strong>data silos</strong>, hindering accessibility for crucial research."
      />
      <FeatureItem
        imageUrl="../../static/images/icons/iconsPink/011.svg"
        text="A wealth of data on genes, variants and diseases exists, but <strong>incompatible formats and terminology</strong> create barriers to analysis."
      />
      <FeatureItem
        imageUrl="../../static/images/icons/iconsPink/050.svg"
        text="100,000+ disease genomics articles are published each year, causing <strong>slow updates and incomplete data</strong> in curated databases."
      />
    </div>
  );
}

function FeatureItem({ imageUrl, text }) {
  return (
    <div className="benefits-content benefits-background">
      <div className="benefits-img">
        <img src={imageUrl} alt="Imagem" />
      </div>
      <div className="benefits-div">
        <p className="text-left" dangerouslySetInnerHTML={{ __html: text }}></p>
      </div>
    </div>
  );
}

export default FeatureHome;
