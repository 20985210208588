import React from 'react';
import { FolderIcon, CalendarIcon } from './Icons';

const PostInfo = ({ category, date }) => {
  const categories = Array.isArray(category) ? category : [category];

  return (
    <p className="post-info">
      <span className="post-icon">
        <CalendarIcon />
      </span>
      {date}
    </p>
  );
};

export default PostInfo;
