// import React from 'react';
// import Axios from '../../Services/Axios';
// import { API_URL } from '../Constants';
// import { userAppContext } from '../../Context/Context';
import React, { useState, useRef } from 'react';

const TextInput = ({ value, setValue }) => {
  return (
    <input
      type="text"
      autoComplete="off"
      aria-autocomplete="list"
      aria-controls="react-autowhatever-source"
      placeholder="Examples: Obesity,  LEPR,  rs121913279,  streptozocin..."
      value={value}
      onChange={e => setValue(e.target.value)}
      //   onChange={handleInput}
      //   onClick={clearAllFirstTime}
    ></input>
  );
};

export default TextInput;
