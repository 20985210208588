import React from 'react';
import { getKeyName } from '../QueriedContent/DetailGene';

const formatNumberAttributes = attribute => {
  return typeof attribute === 'number' ? attribute.toFixed(2) : '';
};

function uniqueProteinClass(proteins) {
  let proteinClassArray = [];
  proteins.map(protein => {
    protein.proteinClasses.map(proteinClass => {
      if (!proteinClassArray.includes(proteinClass.name)) {
        proteinClassArray.push(proteinClass.name);
      }
    });
  });

  return proteinClassArray.join(' ');
}

export default function GeneGeneralInfo({ geneDetailInfo }) {
  const { ncbiID, symbol, proteinIDs, proteins, ensembl_ids, description, DPI, DSI, PLI } = geneDetailInfo;

  const geneLink = `http://www.ncbi.nlm.nih.gov/gene/${ncbiID}`;
  const formattedDPI = formatNumberAttributes(DPI);
  const formattedDSI = formatNumberAttributes(DSI);
  const formattedPLI = formatNumberAttributes(PLI);

  return (
    <div className="table-container" style={{ overflow: 'scroll', maxHeight: '400px' }}>
      <table className="table">
        <tbody>
          <tr>
            <td className="text-right">
              <strong>Entrez Id:</strong>
            </td>
            <td>{ncbiID}</td>
          </tr>

          <tr>
            <td className="text-right">
              <strong>Gene Symbol:</strong>
            </td>
            <td>
              <a href={geneLink} className="mapId" target="_blank" rel="noreferrer">
                {symbol} <i className="fa fa-external-link" aria-hidden="true"></i>
              </a>
            </td>
          </tr>

          <tr>
            <td className="text-right">
              <strong>Uniprot accession:</strong>
            </td>
            <td>
              {proteinIDs.map(protein => {
                const linkProtein = `https://www.uniprot.org/uniprot/${protein}`;
                return (
                  <span key={getKeyName(protein)}>
                    <a href={linkProtein} className="mapId" target="_blank" rel="noreferrer">
                      {'  '}
                      {protein} <i className="fa fa-external-link" aria-hidden="true"></i>
                    </a>
                    {'  '}
                  </span>
                );
              })}
            </td>
          </tr>

          <tr>
            <td className="text-right">
              <strong>Ensembl Gene:</strong>
            </td>
            <td>
              {ensembl_ids.map(ensemblId => {
                return <span key={getKeyName(ensemblId)}> {ensemblId}</span>;
              })}
            </td>
          </tr>

          <tr>
            <td className="text-right">
              <strong>Full name:</strong>
            </td>
            <td>{description}</td>
          </tr>

          <tr>
            <td className="text-right">
              <strong>Protein class:</strong>
            </td>
            <td>{uniqueProteinClass(proteins)}</td>
          </tr>

          <tr>
            <td className="text-right">
              <strong>DPI:</strong>
            </td>
            <td>{formattedDPI}</td>
          </tr>

          <tr>
            <td className="text-right">
              <strong>DSI:</strong>
            </td>
            <td>{formattedDSI}</td>
          </tr>

          <tr>
            <td className="text-right">
              <strong>pLi:</strong>
            </td>
            <td>{formattedPLI}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
