import React from 'react';

export default function ColumnSelect({ setIsColumnSelectOpen }) {
  return (
    <button
      type="button"
      className="ml-2 btn btn-info"
      data-toggle="collapse"
      onClick={() => {
        setIsColumnSelectOpen(prevState => !prevState);
      }}
    >
      <i className="fa fa-sliders mr-2"></i>Select columns
      <i className="fa fa-angle-down op-0-5 ml-2" />
    </button>
  );
}
