import React from 'react';
import { useNavigate } from 'react-router-dom';
import { searchContext } from '../../Context/Context';
import { patchSearchItemsId } from '../Patches/PatchSearchItems';
import { DATASETS } from '../Constants.jsx';

const SubmitButton = ({ setValue, className, onClick, children }) => {
  const navigate = useNavigate();
  const { searchItems } = searchContext();

  let idents;
  let view;
  if (searchItems.length > 0) {
    // make idents param from searchItems
    idents = searchItems.map(item => item.id).join('-');

    // make view param from searchItems
    view = searchItems[0].dataset;
  }

  const handleSubmit = evt => {
    evt.preventDefault();

    if (searchItems.length === 0) {
      navigate({
        pathname: '/search',
        search: '?view=ALL&idents=ALL&source=ALL&tab=GDA',
      });
    } else {
      const tab = view === DATASETS.VARIANTS ? 'VDA' : 'GDA'; // Variants is the only one that starts on VDA tab
      navigate({
        pathname: '/search',
        search: `?view=${view}&idents=${idents}&source=ALL&tab=${tab}`,
      });
    }

    setValue('');
    if (onClick) onClick(); // Call onClick prop if provided
  };

  if (children) {
    // If children are provided, render them as the button content
    return (
      <button className={className} onClick={handleSubmit}>
        {children}
      </button>
    );
  } else {
    return (
      <img
        src="../../static/images/button-1000x.png"
        // src="../../static/images/BOTON-DISGENET.svg"
        alt="Search Button"
        className={className}
        onClick={handleSubmit}
      />
    );
  }
};

const FirstSearchButton = ({ setValue }) => {
  return <SubmitButton setValue={setValue} id="first-button-id" className="first-button-class" />;
};

const SecondSearchButton = ({ setValue }) => {
  return (
    <SubmitButton
      setValue={setValue}
      id="second-button-id"
      className="BtnAccion btnSelectSection custom-btn-search"
    >
      Search
    </SubmitButton>
  );
};

export { FirstSearchButton, SecondSearchButton };
