import React from 'react';

function HeatmapLineDashboard(props) {
  const { chartVariables, chartInfo, setChartInfo } = props;

  function changeChartInfoHandler(newVariable) {
    setChartInfo(prevChartInfo => {
      return {
        ...prevChartInfo,
        variable: newVariable.ident,
        variableName: newVariable.name,
        variableFullName: newVariable.fullName,
      };
    });
  }

  return (
    <div className="graph-dashboard">
      <p>Select by:</p>
      <ul className="list-group list-group-horizontal" aria-label="attributes to change graph">
        {chartVariables.map(variable => {
          return (
            <li
              key={variable.ident}
              title={variable.fullName}
              onClick={() => changeChartInfoHandler(variable)}
              className={`list-group-item ${variable.ident === chartInfo.variable ? 'active' : ''}`}
            >
              {variable.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default HeatmapLineDashboard;
