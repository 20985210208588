import { DATASETS } from '../Components/Constants';

class GDAModel {
  constructor(data) {
    this.geneNcbiID = data.geneNcbiID;
    this.geneSymbol = data.geneSymbol;
    this.geneDescription = data.geneDescription;
    this.geneProteinStrIDs = data.geneProteinStrIDs;
    this.diseaseName = data.diseaseName;
    this.diseaseUMLSCUI = data.diseaseUMLSCUI;
    this.numDiseasesAssociatedToGene = data.numDiseasesAssociatedToGene;
    this.numVariantsAssociatedToGene = data.numVariantsAssociatedToGene;
    this.numGenesAssociatedToDisease = data.numGenesAssociatedToDisease;
    this.numVariantsAssociatedToDisease = data.numVariantsAssociatedToDisease;
    this.numPMIDsupportingAssociation = data.numPMIDsupportingAssociation;
    this.numDBSNPsupportingAssociation = data.numDBSNPsupportingAssociation;
    this.pmid = data.pmid;
    this.score = data.score;
    this.EI = this.transformEIValue(data.EI);
    this.numPmidsWithChemsEvidence = data.numberPmidsWithChemsIncludedInEvidence;
    this.numChemsEvidence = data.numberChemsIncludedInEvidence;
    this.numPmidsWithChemsFiltered = data.numberPmidsWithChemsFiltered;
    this.geneEnsemblIDs = data.geneEnsemblIDs;
  }

  transformEIValue(eiValue) {
    return eiValue !== null ? Number(eiValue.toFixed(2)) : null;
  }
}

class GDATable extends GDAModel {
  constructor(data) {
    super(data);
  }

  get valueGene() {
    return [this.geneNcbiID];
  }

  get valueDisease() {
    return `UMLS_${this.diseaseUMLSCUI}`;
  }

  getNameGene() {
    const nameGene = [
      {
        name: `${this.geneSymbol}, ${this.geneDescription}`,
        value: this.geneNcbiID,
        title: DATASETS.GENES,
      },
    ];

    return nameGene;
  }

  getNameDisease() {
    const nameDisease = [
      {
        name: `${this.diseaseName}, ${this.diseaseUMLSCUI}`,
        value: this.valueDisease,
        title: DATASETS.DISEASES,
      },
    ];

    return nameDisease;
  }

  getNameDG() {
    const nameGene = this.getNameGene();
    const nameDisease = this.getNameDisease();

    const nameDG = nameGene.concat(nameDisease);
    return nameDG;
  }
}

export { GDAModel, GDATable };
