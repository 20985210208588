import React from 'react';
import { getKeyName } from '../QueriedContent/DetailGene';

export default function GeneSynonyms({ geneDetailInfo }) {
  const { synonyms } = geneDetailInfo;
  return (
    <div className="table-container" style={{ overflow: 'scroll', maxHeight: '400px' }}>
      <table className="table table-condensed table-condenseddg">
        <tbody>
          {synonyms.map(synonym => {
            if (!synonym.isOfficial) {
              return (
                <tr key={getKeyName(synonym.name)}>
                  <td className="col-md-6">{synonym.name}</td>
                </tr>
              );
            }
            return <tr key={Math.random().toString()} />;
          })}
        </tbody>
      </table>
    </div>
  );
}
