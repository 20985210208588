import React, { useState, useCallback, useEffect } from 'react';
import fetchEndpoint from '../../../Services/fetchEndpoint';
import { sortChartData, getYAxisValues, setLineXMargins } from '../Charts/Line/LineHelpers';

import { userAppContext, searchContext } from '../../../Context/Context';
import buildGraphEndpoint from '../../Utils/buildGraphEndpoint';

function useLineChart({
  chartInfo,
  initialChartData,
  keySearch,
  source,
  baseUrl,
  parent,
  view,
  getLineData,
  width,
  uniqueIdentViewsVariables,
}) {
  const [chartData, setChartData] = useState(initialChartData);
  const [yAxisValue, setYAxisValues] = useState({ max: 1, min: 0 });
  const [isPayloadEmpty, setIsPayloadEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [xMargin, setXMargin] = useState(null);

  const { authorization, setIsAuthenticated, setGroups, setAuthError, setAuthorization } =
    userAppContext() || {};
  //const { tab, searchItems } = searchContext();
  const { tab, searchItems } = searchContext() || { tab: null, searchItems: [] };


  const setLineChartData = useCallback(() => {
    // set loading to true
    setIsLoading(true);

    // construct orderByParam variable for url
    const orderByParam =
      chartInfo.variable === 'numPMIDsupportingAssociation'
        ? `${chartInfo.variable}_${source}`
        : chartInfo.variable;

    // Linechart properties
    const pageNum = 0;
    const pageSize = 20;
    const order = 'desc';
    const url = buildGraphEndpoint(view, source, tab, searchItems, pageNum, pageSize, orderByParam, order);
    return fetchEndpoint(
      url,
      authorization,
      setIsAuthenticated,
      setGroups,
      setAuthError,
      setAuthorization,
    ).then(responseData => {
      // check if request failed. If it did, quit the function
      if (responseData === undefined) {
        return;
      }

      // get back the data
      const data = responseData.payload;

      // if payload is empty, show payloadEmpty screen
      if (data?.length === 0) {
        setIsPayloadEmpty(true);
        setIsLoading(false);
        return;
      } else {
        setIsPayloadEmpty(false);
      }

      // get the model and the variables to use in the mapping
      const mappingVariables = uniqueIdentViewsVariables[parent].mappingIdents[view];
      const model = uniqueIdentViewsVariables[parent].model;

      // transform the data into the format the Line component needs it
      const transformedLineData = getLineData(data, mappingVariables, model, chartInfo.variable);

      if (transformedLineData.length === 0) {
        setChartData([]);
        setIsLoading(false);
        return;
      }

      // set the value for the Y Axis
      const newYAxisValues = getYAxisValues(chartInfo.variable, data);
      setYAxisValues(newYAxisValues);

      // set the xMargins
      const totalResults = transformedLineData[0].data.length;
      setXMargin(() => setLineXMargins(width, totalResults));

      // sort alphabetically the transformedLineData
      sortChartData(transformedLineData);

      // set the chart's data and loading to false
      setChartData(transformedLineData);
      setIsLoading(false);
    });
  }, [source, tab, baseUrl, keySearch, view, parent, chartInfo]);

  useEffect(() => {
    setLineChartData();
  }, [setLineChartData]);

  return {
    chartData,
    yAxisValue,
    isPayloadEmpty,
    isLoading,
    xMargin,
    setXMargin,
  };
}

export default useLineChart;
