import React from 'react';
import { AppContext, userAppContext } from '../../Context/Context';
import ProfileData from './ProfileData';
import ProfileApiKey from './ProfileApiKey';
//import ProfileFavorites from "./ProfileFavorites";
import ProfileHashLinks from './ProfileHashLinks';
import { useLocation } from 'react-router-dom';
import APIContent from '../API/APIContent';
import ApiHashLinks from '../API/ApiHashiLinks';

const ProfileAreaContent = () => {
  const { user } = userAppContext();

  const location = useLocation();
  let hash = location.hash;
  hash = hash === '' ? '#profileData' : location.hash;

  const divContent = id => {
    const divClass = `tab-pane ${hash === '' || hash === '#' + id ? 'active show' : ''} `;
    return divClass;
  };

  const profileDataClass = divContent('profileData');
  const apiKeyClass = divContent('apiKey');

  // const favoritesClass = divContent('favorites');

  return (
    <React.Fragment>
      <div className="container txt-contenido">
        <span
          style={{
            lineHeight: 1,
          }}
        >
          <h1>Welcome, {user['custom:name']}</h1>
        </span>

        <div className="row txt-contenido mt-4 border-top border-bottom pt-4 pb-4">
          <div className="col-12 col-md-3 order-0 order-md-0 mb-4 mb-md-0">
            <ProfileHashLinks hash={hash} />
            <ApiHashLinks hash={hash} />
          </div>

          <div className="col-12 col-md-8 order-1 order-md-1">
            <div className="tab-content ml-5" id="dbInfoTabbedPane">
              <ProfileData profileDataClass={profileDataClass} />
              <ProfileApiKey apiKeyClass={apiKeyClass} />
              <APIContent />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProfileAreaContent;
