import React from 'react';
import {
  showAllEdgeLabels,
  hideAllEdgeLabels,
  resetZoom,
  downloadCytoJSON,
  downloadCytoPNG,
} from './ActionButtonsFunctions';

const possibleCytoLayouts = ['circle', 'concentric', 'grid', 'breadthfirst', 'random', 'cose'];

export default function CytoscapeActionButtons({
  changeSelectedLayout,
  selectedLayout,
  names,
  parent,
  chartInfo,
}) {
  return (
    <div className="cytoscape-buttons">
      <button onClick={showAllEdgeLabels}>Show edge labels</button>
      <button onClick={hideAllEdgeLabels}>Hide edge labels</button>
      <button onClick={resetZoom}>Reset Zoom</button>
      <button onClick={() => downloadCytoJSON({ names, parent, chartInfo })} download>
        Download JSON
      </button>
      <button onClick={() => downloadCytoPNG({ names, parent, chartInfo })} download>
        Download PNG
      </button>
      <div className="dropdown">
        <button
          type="button"
          className="dropdown-toggle select-layout"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          Select layout:
          <i className="fa fa-angle-down op-0-5 ml-2" />
        </button>
        <div className="dropdown-menu dropdown-menu-right">
          {possibleCytoLayouts.map(buttonTitle => {
            return (
              <a
                key={buttonTitle}
                className={`dropdown-item select-layout-options ${
                  selectedLayout === buttonTitle ? 'active' : ''
                }`}
                onClick={() => changeSelectedLayout(buttonTitle)}
              >
                {buttonTitle}
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}
