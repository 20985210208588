import * as $ from 'jquery';

export default function getApi(userId = {}) {
  var CryptoJS = require('crypto-js');

  const request_headers = {
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Max-Age': '180',
  };

  const secret = 'ca179b30-6daf-4026-bb2d-a8feecb3a9a6';
  const hash = CryptoJS.MD5(userId + secret);
  const url = `${process.env.REACT_APP_URL_API_API}api/v1/akuser/get?user_id=${userId}&secret=${hash}`;

  return fetch(url, { request_headers })
    .then(response => response.json())
    .then(result => {
      return result;
    })
    .catch(error => console.log('error', error));
}
