import React from 'react';

class CookieContent extends React.Component {
  render() {
    return (
      <section>
        <link rel="canonical" href="/Cookies-policy" />
        <div className="container">
          <div className="row">
            {/* data-wow-delay="0.3s"  wow fadeIn*/}
            <div className="col-12">
              <h2>Cookies Policy</h2>
              <p>
                <span
                  style={{
                    color: '#336575',
                  }}
                >
                  This policy describes the cookies used by MedBioinformatics Solutions S.L (hereinafter,
                  “MedBio”, the “Company”, or “we/us”), in its websites at{' '}
                  <a href="https://disgenet.com/" target="_blank" rel="noreferrer">
                    disgenet.com
                  </a>{' '}
                  and{' '}
                  <a href="https://api.disgenet.com/" target="_blank" rel="noreferrer">
                    api.disgenet
                  </a>{' '}
                  (together, the “Website”), in compliance with Spanish Law 34/2002{' '}
                  <em>
                    (Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio
                    electrónico).
                  </em>
                  <br /> You can choose the categories of cookies you want us to install in the cookie banner
                  that pops up the first time you access our Website. Please note that technical cookies are
                  strictly necessary for the Website to properly work and cannot be rejected.{' '}
                </span>
              </p>
              <div id="section1" className="cd-section mb-3">
                <div className="p-3">
                  <h5>1. What is a cookie?</h5>
                  <p>
                    A cookie is a small text file which is stored on your computer or other device such as
                    mobile phones or tablet by a website. Cookies allow a website to remember your preferences
                    whilst visiting the website in order to provide you with a more personalized experience.
                    They help to make our Website work efficiently and can allow us to track how the site is
                    used for making improvements.
                  </p>
                </div>
              </div>
              <div id="section2" className="cd-section mb-3">
                <div className="p-3">
                  <h5>2. How we use cookies</h5>
                  <p>
                    In brief, we use cookies to track how our Website is being used so we can optimize its
                    functioning. For users who have created an account, the cookies save their website
                    preferences.
                  </p>
                </div>
              </div>
              <div id="section3" className="cd-section mb-3">
                <div className="p-3">
                  <h5>3. Which type of cookies uses the Website?</h5>
                  <p>In our website we use both our own cookies and third-party cookies:</p>
                  <ul>
                    <li>Own cookies are cookies sent to your device by our domain.</li>
                    <li>
                      Third-party cookies are sent to your device by domains that are not managed by us, but
                      by another entity that processes the data collected by means of the cookies.
                    </li>
                  </ul>
                  <p>
                    In accordance with the purpose of the cookies, the cookies we use can be divided into the
                    following categories:
                  </p>
                  <ul>
                    <li>
                      <b>TECHNICAL COOKIES (necessary)</b>: cookies necessary for browsing and for the proper
                      functioning of the website. Its use allows basic functions of the website as access to
                      pages of the website with restricted access, make processes of verification, security,
                      control of traffic and communication of data. The legal basis that allows us to collect
                      your data through these cookies is our legitime interest in order to manage our Website.
                      See the cookies table below for more details on these cookies.
                    </li>
                    <li>
                      <b>PREFERENCES COOKIES</b>: they allow the Website to remember information that changes
                      the way the site itself behaves or looks, such as your preferred language or the region
                      in which you are located. The legitimate basis that allows us to collect your data
                      through cookies is our legitimate interest to adapt our site to your preferences. See
                      the cookies table below for more details on these cookies.
                    </li>
                    <li>
                      <b>ANALYTIC COOKIES</b>: allow to monitor and analyse the behaviour of web users. The
                      information collected through this type of cookies is used for measuring the activity of
                      the website, application or platform and for the elaboration of navigation profiles of
                      the users of those websites, applications and platforms, in order to improve the website
                      and our service offerings. The legal basis that allows us to collect your data through
                      these cookies is your consent. See the cookie table below for the details of these
                      cookies.
                    </li>
                  </ul>
                </div>
              </div>
              <div id="section4" className="cd-section mb-3">
                <div className="p-3">
                  <h5>4. Is it possible to disable the use of cookies?</h5>
                  <p>
                    You can restrict or limit the placement of cookies by adjusting the settings of your
                    browser. However, if you select this setting you may be unable to access certain parts of
                    our website, resulting in less efficient browsing and maybe you cannot take advantage of
                    some of our services. Therefore, it is recommended that you do not disable them.
                    <br />
                    If you want to disable cookies, go to the “preferences” menu of the browser or browser
                    settings and find the privacy section. As an example, you can follow the instructions
                    below:
                  </p>
                  <ul>
                    <li>
                      <a
                        href="https://support.microsoft.com/es-es/topic/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10"
                        target="_blank"
                        rel="noreferrer"
                        data-et-has-event-already="true"
                      >
                        Internet Explorer
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://support.google.com/chrome/answer/95647"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Google Chrome
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://support.mozilla.org/es/kb/Borrar%20cookies?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Firefox
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://support.apple.com/es-es/guide/safari/sfri11471/mac"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Safari
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="section5" className="cd-section mb-3">
                <div className="p-3">
                  <h5>5. Cookies table</h5>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Type</th>
                          <th scope="col">Provider</th>
                          <th scope="col">Purpose</th>
                          <th scope="col">Duration</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">JSESSIONID</th>
                          <td>Technical (strictly necessary)</td>
                          <td>Own cookie</td>
                          <td>Necessary for browsing and for the proper functioning of the website</td>
                          <td>When the browsing session ends</td>
                        </tr>
                        <tr>
                          <th scope="row">AWSELB</th>
                          <td>Technical (strictly necessary)</td>
                          <td>Amazon</td>
                          <td>Necessary to properly map the session to an AWS instance.</td>
                          <td>When the browsing session ends or at most one day after creation</td>
                        </tr>
                        <tr>
                          <th scope="row">AWSELBCORS</th>
                          <td>Technical (strictly necessary)</td>
                          <td>Amazon</td>
                          <td>Necessary to properly map the session to an AWS instance.</td>
                          <td>When the browsing session ends or at most one day after creation</td>
                        </tr>
                        <tr>
                          <th scope="row">acceptdgnplusgrt_1</th>
                          <td>Preference (strictly necessary)</td>
                          <td>Own cookie</td>
                          <td>
                            Necessary to remember if a user has accepted or not the cookie banner and privacy
                            policy
                          </td>
                          <td>90 days after creation</td>
                        </tr>
                        <tr>
                          <th scope="row">_ga</th>
                          <td>Analytic (optional)</td>
                          <td>Google</td>
                          <td>Use it to distinguish the users.</td>
                          <td>2 years</td>
                        </tr>
                        <tr>
                          <th scope="row">_gid</th>
                          <td>Analytic (optional)</td>
                          <td>Google</td>
                          <td>Use it to distinguish the users.</td>
                          <td>24 hours</td>
                        </tr>
                        <tr>
                          <th scope="row">_gat</th>
                          <td>Analytic (optional)</td>
                          <td>Google</td>
                          <td>
                            Use it to limit the percentage of requests. If Google Analytics has been
                            implemented using Google Tag Manager, this cookie will be called
                            _dc_gtm_&lt;property-id&gt;
                          </td>
                          <td />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <p
                style={{
                  textAlign: 'left',
                }}
              >
                <strong>Version 1.0: 05 of May 2024</strong>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CookieContent;
