import { cyto } from './CytoscapeChart';

// IMPORTANT: leave the counter implementation as it is, otherwise the toggling won't work in network edges
let counter = 0;

function toggleCyto(event) {
  counter = 0;

  cyto.on('tap', 'edge', evt => {
    if (counter === 0) {
      const clickedEdge = evt.target;
      clickedEdge.toggleClass('hasLabel');
      counter++;
    }
  });
}

function downloadCytoJSON({ names, parent, chartInfo }) {
  const cytoJSONcomplete = JSON.stringify(cyto.json());
  const jsonURL = `data:text/json;charset=utf-8,${encodeURIComponent(cytoJSONcomplete)}`;

  const fileName = `cytoscape_${names.map(el => el.value).join('&')}_${parent}_${chartInfo.variable}.json`;

  const link = document.createElement('a');
  link.href = jsonURL;
  link.setAttribute('download', fileName);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
}

async function downloadCytoPNG({ names, parent, chartInfo }) {
  const image = await cyto.png({
    output: 'blob-promise',
    full: true,
    bg: 'white',
  });

  const url = window.URL.createObjectURL(new Blob([image]));
  const fileName = `cytoscape_${names.map(el => el.value).join('&')}_${parent}_${chartInfo.variable}.png`;

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
}

function showAllEdgeLabels() {
  const cytoEdges = cyto.edges();
  cytoEdges.addClass('hasLabel');
}

function hideAllEdgeLabels() {
  const cytoEdges = cyto.edges();
  cytoEdges.removeClass('hasLabel');
}

function resetZoom() {
  cyto.zoom({
    level: 1.0,
  });
  cyto.center();
}

export { toggleCyto, downloadCytoJSON, downloadCytoPNG, showAllEdgeLabels, hideAllEdgeLabels, resetZoom };
