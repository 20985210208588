import { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import debounce from 'debounce-fn';

class Axios extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    method: PropTypes.string,
    params: PropTypes.object,
    authorization: PropTypes.string,
    data: PropTypes.object,
    children: PropTypes.func.isRequired,
  };

  state = {
    data: undefined,
    loading: false,
    error: false,
  };

  cancelToken = axios.CancelToken.source();

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (!(prevProps, this.props)) {
      this.fetchData();
    } else if (JSON.stringify(prevProps.params) !== JSON.stringify(this.props.params)) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.cancelToken.cancel('Component is unmounting');
  }

  makeNetworkRequest = debounce(async () => {
    const { url, method = 'get', params, authorization, data } = this.props;
    const headers = {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: authorization,
    };

    this.setState({ loading: true, error: false });

    try {
      const response = await axios({
        url,
        method,
        params,
        headers,
        data,
        cancelToken: this.cancelToken.token,
      });

      this.setState({
        data: response.data.payload,
        loading: false,
        error: false,
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }

      this.setState({ data: undefined, error: error.message, loading: false });
      console.error(error);
    }
  }, 200);

  fetchData = () => {
    this.cancelToken.cancel('Fetching new data');
    this.cancelToken = axios.CancelToken.source();
    this.makeNetworkRequest();
  };

  render() {
    const { children } = this.props;

    if (typeof children !== 'function') {
      return null;
    }

    const { data, loading, error } = this.state;

    return children({
      data,
      loading,
      error,
      refetch: this.fetchData,
    });
  }
}

export default Axios;
