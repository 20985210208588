import { useState, useEffect } from 'react';
import { TopRightMenu } from '../NavMenus/TopNavigation.jsx';
import Logo from '../Common/Logo.jsx';

export default function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [scrolled]);

  return (
    <section className="home-header">
      {!isMobile && (
        <div className="background-video-container">
          <video autoPlay loop muted className="background-video">
            <source src="../../../static/images/DisgenetKeyVisual1920X600.mp4" type="video/mp4" />
            Your browser does not support HTML5 videos.
          </video>
        </div>
      )}
      <div className="background-image-container">
        <img
          src="../../../static/images/KeyVisuals/1920x300DISGENET.jpg"
          alt="Background mobile"
          style={{ height: '80%', width: '100%', paddingBottom: '50px' }}
        />
      </div>

      <div className="overlay-content">
        <div
          className={`header-content ${scrolled ? 'header-gradient' : ''}`}
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            zIndex: '1000',
            backgroundColor: scrolled ? '#ffffff' : 'transparent',
          }}
        >
          <Logo />
          <div>
            <nav>
              <TopRightMenu />
            </nav>
          </div>
        </div>
        <h1 className="custom-slogan gradient-color text-center">DATA FOR TOMORROW’S HEALTH</h1>
      </div>
    </section>
  );
}
