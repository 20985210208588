// todo: delete, already refactored
const filterTypes = {
  string: {
    type: 'string',
    emptyValue: '',
    operators: [
      {
        name: 'eq',
      },
    ],
  },
  number: {
    type: 'number',
    operators: [{ name: 'eq' }, { name: 'gt' }, { name: 'lt' }, { name: 'lte' }, { name: 'gte' }],
  },
  select: {
    type: 'select',
    operators: [{ name: 'eq' }],
  },
};

function getSizePage({ totalCount, limit, page }) {
  let pageTotal = Math.ceil(totalCount / limit) - 1;
  let numElements = page == pageTotal ? totalCount % limit : limit;
  return numElements > 10 ? 10 : numElements;
}

function getSizes({ columns, datos }) {
  let sizes = columns.map(column => {
    return column.header.props.children.length + 2;
  });

  datos.payload.map(fila => {
    columns.map((column, index) => {
      if (column.name == 'sentenceHTML') {
        let restaSpan = 0;
        if (fila[column.name] != null) {
          if (fila[column.name].match(/span/g) == null) restaSpan = 0;
          else restaSpan = fila[column.name].match(/span/g).length * 25;

          sizes[index] =
            sizes[index] > fila[column.name].toString().length
              ? sizes[index]
              : fila[column.name].toString().length - restaSpan;
          if (sizes[index] > 150) sizes[index] = 100;
        }
      } else if (fila[column.name] != undefined && fila[column.name] != null) {
        sizes[index] =
          sizes[index] > fila[column.name].toString().length
            ? sizes[index]
            : fila[column.name].toString().length;
      }
    });
  });

  return sizes;
}

// todo: this can be safely deleted:
function setSizesColumns({ columns, sizes }) {
  let factor = 9;
  let width = 25;

  if (columns.length < 6) {
    factor = 19;
    width = 45;
  } else if (columns.length < 10) {
    factor = 11;
    width = 45;
  }

  return columns.map((column, index) => {
    if (column.columnSize != null) {
      sizes[index] = column.columnSize;
    } else if (sizes[index] < 1) {
      sizes[index] = column.header.props.children.length + 2;
    }

    column.defaultWidth = sizes[index] * factor + width;
  });
}

export { filterTypes, getSizePage, getSizes, setSizesColumns };
