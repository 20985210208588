import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

function PrivacyPopUp({ onClose, onAcceptTerms }) {
  const popupRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="privacy-popup-container" ref={popupRef}>
      <div className="privacy-popup-content">
        <div id="printCopy" className="container txt-contenido">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  <h2>Privacy Policy</h2>
                </div>
              </div>
            </div>
            <p>
              MedBioinformatic Solutions SL (“MedBio”, the “Company”, or “we/us”), is committed to protecting
              the privacy of all users (“You/r”) of our website and services. This Privacy Policy explains our
              practices regarding the use of personal data collected and processed through our website,
              contacts with third parties and the provision of services.
            </p>
            <div id="section1" className="cd-section mb-3">
              <div className="p-3">
                <h5>1. Data Controller</h5>
                <p>
                  The entity responsible for Your personal data is MedBioinformatics Solutions S.L, located at
                  Rambla de Catalunya, 14, 7º, 1ª, 08007, Barcelona and with Tax ID (NIF): ESB67590950. All
                  communications regarding the processing of Your personal data must be directed to the
                  following email address:{' '}
                  <a href="mailto:dataprotection@disgenet.com">dataprotection@disgenet.com</a>
                </p>
              </div>
            </div>
            <div id="section2" className="cd-section mb-3">
              <div className="p-3">
                <h5>2. Data collected and its purposes</h5>
                <p>
                  We collect and process the following data:
                  <br />
                  a) <strong>Navigation data.</strong> Due to the standards of communications on the Internet,
                  when You visit our website we automatically receive the URL of the site from which You come
                  and the site You visit when You leave our website. We also receive the internet protocol
                  (“IP”) address of Your computer and the type of browser You are using. We use this
                  information to analyze global trends to improve the service. This information is not shared
                  with third parties without Your consent. Except for the above, and what is stated in our{' '}
                  <Link to="/Cookies-policy">Cookies Policy</Link>, we do not collect any type of personal
                  data if You are only browsing the website.
                  <br />
                  b) <strong>Contact Form.</strong> If You complete any web form in order to contact us, we
                  collect the following personal data: Your name and surname, the company where You work and
                  Your email address. These personal data are mandatory for processing Your request and
                  contacting You.
                  <br />
                  c) <strong>Registration data (trial version or full version).</strong> When registering for
                  our services, we will collect the following personal data: name, surnames, company and email
                  address. These data are required and if they are not provided, we cannot create Your
                  account.
                  <br />
                  d) <strong>Electronic communications data.</strong> We collect and process the personal
                  data, about You or a third party, you provide us during any communications via electronic
                  means, such us emails.
                  <br />
                  e) <strong>Commercial communications and newsletter.</strong> If you so wish, we will
                  collect and process your email address for sending you commercial communications and
                  newsletter.
                </p>
                <p>
                  <strong>Purposes.</strong> We collect and process the data mentioned above for the following
                  purposes:
                </p>
                <ul>
                  <li>Providing access to registered users to the full or trial version of our service.</li>
                  <li>Contacting You, if so requested.</li>
                  <li>Managing and improving our website and service.</li>
                  <li>Communicating with you .</li>
                  <li>Sending you commercial communications and newsletter, if you so wish.</li>
                </ul>
                <p>
                  <strong>Prohibited data.</strong> It is forbidden to submit to us any data that contain data
                  of special categories indicated in article 9 on the General Data Protection Regulation
                  679/2016 (“GDPR”).
                </p>
                <p>
                  <strong>Commercial Communications.</strong> If you tick the corresponding box, you consent
                  to receiving commercial communications and newsletters from us regarding our services. If,
                  later on, you don’t wish to receive commercial information about us anymore, you can
                  expressly opt out by sending a notification to{' '}
                  <a href="mailto:dataprotection@disgenet.com">dataprotection@disgenet.com</a> or by clicking
                  the unsubscribe link in our email communications.
                  <br />
                  You are responsible for the accuracy of the data You provide, and MedBio reserves the right
                  to exclude from the services any user who has provided false or inaccurate information,
                  without prejudice to other available resources.
                </p>
              </div>
            </div>
            <div id="section3" className="cd-section mb-3">
              <div className="p-3">
                <h5>3. Legal Basis</h5>
                <p>
                  The legal basis that allow us collecting and processing Your personal data are the ones set
                  out below:
                </p>
                <p>
                  <strong> a) Navigation data:</strong> the legal basis for processing these data is our
                  legitimate interest and, when applicable, your consent. Please read our{' '}
                  <Link to="/Cookies-policy">Cookies Policy</Link> for further information.
                  <br />
                  <strong>b) Contact Form:</strong> the legal basis for processing the data You provide us
                  when filling in a contact form is Your consent.
                  <br />
                  <strong>c) Registration data (trial version or full version):</strong> the legal basis for
                  processing registration data is the performance of our contract for accessing the service.
                  <br />
                  <strong>d) Electronic Communications data:</strong> the legal basis for processing such data
                  is our legitimate interest in communicating with You.
                  <br />
                  <strong>e) Commercial communications:</strong> if you are a customer of ours, the legal
                  basis for processing your mail for sending commercial communications is our legitimate
                  interest. If you are not a customer, the legal basis for sending commercial communications
                  is your consent.
                </p>
              </div>
            </div>
            <div id="section4" className="cd-section mb-3">
              <div className="p-3">
                <h5>4. Data Disclosure</h5>
                <p>
                  We process Your personal data with strict confidentiality in accordance with applicable law.
                  Unless otherwise stated, Your personal data will not be provided to third parties. We do not
                  sell or assign to third parties lists with personal data, nor of any other type.
                  Nonetheless, we may disclose Your data as follows:
                </p>
                <ul>
                  <li>
                    We can make personal data available to any company interested in buying or buying MedBio
                    or a part of your business and, consequently, give access to any national or international
                    auditors to carry out your “due diligence”.
                  </li>
                  <li>
                    We may give access to Your personal data to our service providers under contracts for the
                    provision of services in favor of the Company. We require that all third parties respect
                    the security of their personal data and treat them in accordance with the law. We do not
                    allow our external service providers to use your personal data for their own purposes and
                    we only allow them to process your personal data for specific purposes and in accordance
                    with our instructions.
                  </li>
                </ul>
              </div>
            </div>
            <div id="section5" className="cd-section mb-3">
              <div className="p-3">
                <h5>5. INTERNATIONAL TRANSFER</h5>
                <p>
                  MedBio is headquartered in Spain. We use technology services from third parties who can
                  process Your data in the course of providing us with their services. These entities may be
                  in jurisdictions that generally do not provide adequate guarantees in relation to the
                  processing of personal data. However, we have entered into contracts with those entities
                  that do include the safeguards required by applicable privacy laws, including the so called
                  “standard model clauses”. For more information, contact us at dataprotection (at) disgenet
                  (dot) com
                </p>
              </div>
            </div>
            <div id="section6" className="cd-section mb-3">
              <div className="p-3">
                <h5>6. DATA RETENTION</h5>
                <p>
                  We will only retain your personal data for as long as necessary to fulfil the purposes we
                  collected it for, including for the purposes of satisfying any legal, accounting, or
                  reporting requirements. To determine the appropriate retention period for personal data, we
                  consider the amount, nature and sensitivity of the personal data, the potential risk of harm
                  from unauthorized use or disclosure of Your personal data, the purposes for which we process
                  Your data personal and if we can achieve those purposes through other means and the
                  applicable legal requirements. After that period, the data will be retained (in a blocked
                  and secure manner) for the required period of legal and administrative liability and to
                  comply with legal obligations and other purposes set out above, which may be up to 10 years
                  according to current applicable law. We may also retain anonymized data after the period
                  mentioned above, for statistical purposes.{' '}
                </p>
              </div>
            </div>
            <div id="section7" className="cd-section mb-3">
              <div className="p-3">
                <h5>7. SECURITY MEASURES</h5>
                <p>
                  We implement security measures and personal data protection schemes as required by the
                  applicable data protection law to maintain the confidentiality and integrity of Your data
                  and protection against unauthorized access, modification or destruction.{' '}
                </p>
              </div>
            </div>
            <div id="section8" className="cd-section mb-3">
              <div className="p-3">
                <h5>8. Your Rights</h5>
                <p>
                  You have the following rights under the data protection laws, in relation to Your personal
                  data: the right to request access to Your personal data (commonly known as a “data subject
                  access request”); request the correction of the personal data we have about You; request the
                  erasure of Your personal data; objecting to the processing of Your personal data when we are
                  relying on a legitimate interest (or those of a third party); request the restriction of
                  processing Your personal data; request the transfer of Your personal data to You or to a
                  third party (right to data portability); withdraw Your consent at any time where we are
                  relying on consent to process Your personal data. You can make the aforementioned rights
                  effective by contacting us at dataprotection (at) disgenet (dot) com. You also have the
                  right to file a complaint with the competent authority, in this case, the Spanish Agency for
                  Data Protection (Agencia Española de Protección de Datos), at Calle Jorge Juan, 6, 28001
                  Madrid, Spain.{' '}
                </p>
              </div>
            </div>
            <div id="section9" className="cd-section mb-3">
              <div className="p-3">
                <h5>9. General</h5>
                <p>
                  We reserve the right to modify the terms of this Privacy Policy and will notify You by clear
                  notice of these changes by email, on our website, and in this Privacy Policy. If You
                  continue to use our website and services after such update, You will be deemed to accept the
                  new terms. If You do not accept the update, let us know and we will cancel Your account and
                  will delete Your personal data (except when it is necessary to keep them for legal purposes)
                  and You will not be able to continue using our services. Unless a specific local regulation
                  sets forth to the contrary, the Privacy Policy is governed by the laws of Spain.{' '}
                </p>
              </div>
            </div>
          </div>
          <p className="text-left mb-3">
            <strong>Version 1.0: 05 of May 2024</strong>
          </p>
        </div>
        <div className="mb-3">
          <button onClick={onClose}>Close</button>
        </div>
        {/* <div>
          <button onClick={(onAcceptTerms, onClose)}>I accept</button>
        </div> */}
      </div>
    </div>
  );
}

export default PrivacyPopUp;
