import React from 'react';
import ReactGA from 'react-ga4';
import CommonHeader from '../Components/Headers/CommonHeader';
import CookieContent from '../Components/Cookies/CookieContent';
import Nav from '../Components/Cookies/Nav';
import Footer from '../Components/Home/Footer';
import $ from 'jquery';

export default function CookiesPolicy() {
  $('.popover').hide();
  ReactGA.send({
    hitType: 'pageview',
    page: '/Cookies-policy',
    title: 'Cookies policy',
  });

  return (
    <React.Fragment>
      <CommonHeader />
      <Nav />
      <CookieContent />
      <Footer />
    </React.Fragment>
  );
}
