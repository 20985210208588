const dirObj = {
  1: 'asc',
  '-1': 'desc',
};

// todo: not used, can delete, integrated into buildResultEndpoint
function getSort(sortGridInfo) {
  let sort = '';
  let sortInfo = sortGridInfo[0];

  const dirInfo = dirObj[sortInfo.dir.toString()];
  if (sortInfo.dir) {
    if (sortInfo.name.startsWith('diseaseClassName')) {
      sort += `&order_by=${sortInfo.name.replaceAll('_', '')}&order=${dirInfo}`;
    } else if (sortInfo.name.startsWith('ddaRelation')) {
      sort += `&order_by=rela`;
    } else if (sortInfo.name === 'symbol') {
      sort += `&order_by=symbol_keyword&order=${dirInfo}`;
    } else {
      sort += `&order_by=${sortInfo.name}&order=${dirInfo}`;
    }
  }

  return sort;
}

// Filter helper functions:

// GetFiltersMap helper function:
function getFiltersMap() {
  const keyValue = [
    // disease
    ['numVariantsAssociatedToDisease', 'num_variants'],
    ['numGenesAssociatedToDisease', 'num_genes'],
    ['ddaRelation', 'rela'],
    // drugs
    ['chemUMLSCUI', 'chem_cuis_filter'],
    ['chemShowName', 'chem_name'],
    // gda/summary
    ['chemUMLSCUIs', 'chem_cuis_filter'],
    ['diseaseUMLSCUI', 'disease_filter'],
    ['geneNcbiID', 'gene_ncbi_id_filter'],
    ['geneDSI', 'dsi'],
    ['geneDPI', 'dpi'],
    ['genepLI', 'pli'],
    ['EL', 'el'],
    ['EI', 'ei'],
    ['geneProteinClassNames', 'gene_protein_class_names'],
    // gda/evidences
    ['associationType', 'association_type'],
    // vda/summary
    ['variantStrID', 'variant_name'],
    ['variantDSI', 'dsi'],
    ['variantDPI', 'dpi'],
    ['diseaseName', 'disease_name'],
    ['geneNcbiIDs', 'gene_ncbi_id_filter'],
    ['geneEnsemblIDs', 'gene_ensembl_id'],

    // vda/evidences
    ['diseaseType', 'disease_type'],
    ['geneSymbol', 'gene_symbol'],
    ['mostSevereConsequences', 'most_severe_consequences'],
    ['sentenceHTML', 'sentence_html'],

    // genes/GDAs/summary
    ['diseaseClassName_MSH', 'disease_class_name_MSH'],
    ['diseaseClassName_UMLS', 'disease_class_name_UMLS'],
    ['diseaseClassName_DO', 'disease_class_name_DO'],
    ['diseaseClassName_HPO', 'disease_class_name_HPO'],
    // variants/all
    ['strID', 'variant'],
    ['DSI', 'dsi'],
    ['DPI', 'dpi'],
    ['DBSNPclass', 'class'],
    ['Alleles', 'alleles'],
    ['numDiseasesAssociatedToVariant', 'num_diseases'],
    ['numPublications', 'num_publications'],
    ['firstRef', 'first_ref'],
    ['lastRef', 'last_ref'],
    ['polyphen_score', 'polyphen'],
    ['sift_score', 'sift'],
    // gene/all
    ['proteins', 'protein'],
    ['PLI', 'pli'],
    ['ncbiID', 'gene_ncbi_id_filter'],
    ['proteinClassIDs', 'protein_class'],
    ['pathwayName', 'pathway_name'],
    ['pathwayID', 'pathway_id'],
    // gda/all
    ['geneProteinStrIDs', 'gene_protein_str_i_ds'],
    ['chemsInSentence', 'chem_name'],
  ];

  return new Map(keyValue);
}

// Number Filter Helper functions:
const wholeNumbersAttributeNames = [
  'numPublications',
  'numPublications_GDA',
  'numPublications_VDA',
  'numPMIDsupportingAssociation',
  'firstRef',
  'lastRef',
  'yearInitial',
  'yearFinal',
  'pmYear',
  'numDBSNPsupportingAssociation',
  'numDiseasesAssociatedToVariant',
  'numDiseasesAssociatedToGene',
  'numVariantsAssociatedToGene',
  'numVariantsAssociatedToDisease',
  'numGenesAssociatedToDisease',
  'numberChemsIncludedInEvidence',
  'chemNumPublications',
  'chemNumPublicationsGDAs',
  'chemNumPublicationsGDA',
  'chemNumGDAs',
  'chemNumPublicationsVDAs',
  'chemNumPublicationsVDA',
  'chemNumVDAs',
  'ngenes_diseaseID_1',
  'nvariants_diseaseID_1',
  'ngenes_diseaseID_2',
  'nvariants_diseaseID_2',
  'shared_genes',
  'shared_variants',
];

const notEqualOperators = ['lt', 'gt'];

function getMinFilterString(filterName, filterParam) {
  return `&min_${filterName}=${filterParam}`;
}

function getMaxFilterString(filterName, filterParam) {
  return `&max_${filterName}=${filterParam}`;
}

function getEqualFilterString(filterName, minValue, maxValue) {
  let filter = getMinFilterString(filterName, minValue);
  filter += getMaxFilterString(filterName, maxValue);

  return filter;
}

function setMinValue(filterParam, valueName, operator) {
  const minValueModifier = notEqualOperators.includes(operator) ? -0.005 : 0.005;

  let minValue = (filterParam - minValueModifier).toFixed(4);
  if (wholeNumbersAttributeNames.includes(valueName)) {
    if (!notEqualOperators.includes(operator)) return filterParam;
    return filterParam + 1;
  }

  return minValue;
}

function setMaxValue(filterParam, valueName, operator) {
  const maxValueModifier = notEqualOperators.includes(operator) ? -0.005 : 0.005;

  let maxValue = (filterParam + maxValueModifier).toFixed(4);

  if (wholeNumbersAttributeNames.includes(valueName)) {
    if (!notEqualOperators.includes(operator)) return filterParam;
    return filterParam - 1;
  }

  return maxValue;
}

// Get StringFilter helper function
function getStringFilter(currFilter, value) {
  if (currFilter === 'chromosome') value = value.toUpperCase();
  return `&${currFilter}=${value}`;
}

// Get SelectFilter helper function
function getSelectFilter(operator, currFilter, value) {
  if (operator === 'eq') {
    return `&${currFilter}=${value}`;
  }

  return '';
}

// Get NumberFilter helper function
function getNumberFilter(operator, currFilter, value, name) {
  const numFilter = parseFloat(value);

  if (
    Number.isNaN(numFilter) ||
    value.match(/[^0-9.]/) ||
    (wholeNumbersAttributeNames.includes(name) && numFilter % 1 !== 0)
  )
    return '';

  let minValue = setMinValue(numFilter, name, operator);
  let maxValue = setMaxValue(numFilter, name, operator);

  if (operator === 'eq') {
    return getEqualFilterString(currFilter, minValue, maxValue);
  } else if (operator === 'gt' || operator === 'gte') {
    return getMinFilterString(currFilter, minValue);
  } else if (operator === 'lt' || operator === 'lte') {
    return getMaxFilterString(currFilter, maxValue);
  }
}

// getFilters complete function
function getFilters({ filterValue }) {
  const filtersMap = getFiltersMap();
  let totalFilter = '';

  filterValue.forEach(filter => {
    const { value, type, name, operator } = filter;
    const currFilter = filtersMap.has(name) ? filtersMap.get(name) : name;

    if (value === '' || value === null || value === undefined) {
      return;
    }

    if (type === 'string') {
      totalFilter += getStringFilter(currFilter, value);
    }
    if (type === 'select') {
      totalFilter += getSelectFilter(operator, currFilter, value);
    }
    if (type === 'number') {
      totalFilter += getNumberFilter(operator, currFilter, value, name);
    }
  });

  return totalFilter;
}

export { getFilters, getSort, getFiltersMap, getMinFilterString, getMaxFilterString, getNumberFilter };
