import React from 'react';
import ReactGA from 'react-ga4';
import Footer from '../Components/Home/Footer';
import CommonHeader from '../Components/Headers/CommonHeader';
import ProfileAreaContent from '../Components/ProfileArea/ProfileAreaContent';
import { userAppContext } from '../Context/Context';
import NotLoginPage from '../Components/ProfileArea/NotLoginPage';

const ProfileArea = () => {
  // ReactGA.send({
  //   hitType: 'pageview',
  //   page: '/Profile-area',
  //   title: 'Profile Area',
  // });
  const { authorization, isAuthenticated } = userAppContext();

  return (
    <React.Fragment>
      <CommonHeader />
      {!isAuthenticated ? <NotLoginPage /> : <ProfileAreaContent />}
      <Footer />
    </React.Fragment>
  );
};

export default ProfileArea;
