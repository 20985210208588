import React from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import CommonHeader from '../Components/Headers/CommonHeader';
import APIContent from '../Components/API/APIContent';
import Footer from '../Components/Home/Footer';
import Login from '../Components/Search/Login';
import { userAppContext } from '../Context/Context';
import Modal from 'react-modal';
import $ from 'jquery';
import ApiHashLinks from '../Components/API/ApiHashiLinks';

export default function Api() {
  $('.popover').hide();

  const location = useLocation();
  const hash = location.hash;

  const { isAuthenticated, authError, setAuthError } = userAppContext();

  ReactGA.send({ hitType: 'pageview', page: '/Tools', title: 'Tools' });

  const customStyles = {
    content: {
      position: 'relative',
      top: '50%',
      transform: 'translateY(-57%)',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '600px',
      background: 'transparent',
      boxSizing: 'border-box',
      border: null,
      padding: null,
    },
  };

  return isAuthenticated ? (
    <React.Fragment>
      <title> DISGENET: Our API and database tools </title>

      <meta
        name="description"
        content="DISGENET offers different tools to acces the database."
        data-rh="true"
      />

      <link rel="canonical" href="/Tools" />

      <CommonHeader />
      <div className="container txt-contenido">
        <h1 className="firstHiddenTitle">DISGENET offers different tools to acces the database.</h1>
        <div className="row p-4">
          <div className="col-12 col-md-3 order-0 order-md-0 mb-4 mb-md-0">
            <ApiHashLinks hash={hash} />
          </div>
          <div className="col-12 col-md-8 order-1 order-md-1">
            <APIContent />
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  ) : (
    <>
      <title> DISGENET - Our Tools </title>

      <meta
        name="description"
        content="DISGENET offers different tools to acces the database."
        data-rh="true"
      />

      <link rel="canonical" href="/Tools" />

      <CommonHeader />
      <Modal isOpen={true} ariaHideApp={false} style={customStyles} shouldFocusAfterRender={false}>
        {authError ? (
          <>
            <h1>Error: {authError}</h1>

            <div className="container-contact100-form-btn">
              <div className="wrap-contact100-form-btn">
                <div className="contact100-form-bgbtn" />
                <button
                  className="contact100-form-btn"
                  onClick={evt => {
                    setAuthError('');
                  }}
                >
                  Login
                </button>
              </div>{' '}
            </div>
          </>
        ) : (
          <Login />
        )}
      </Modal>
    </>
  );
}
