import React from 'react';
import { HashLink } from 'react-router-hash-link';

const ProfileHashLinks = ({ hash }) => {
  const hashlinkBootstrapClasses = 'mb-1 dropdown-item text-wrap';

  const hashLinkContent = (id, HashLinkName) => {
    const hashLink = (
      <HashLink
        to={`${'#' + id}`}
        data-toggle="tab"
        data-target={`${'#' + id}`}
        className={`${hashlinkBootstrapClasses} ${hash === '' || hash === '#' + id ? 'active' : ''}`}
      >
        {' '}
        {HashLinkName}{' '}
      </HashLink>
    );

    return hashLink;
  };

  const profileDataHashLink = hashLinkContent('profileData', 'Profile Data');
  const apiKeyHashLink = hashLinkContent('apiKey', 'API Key');
  const favoritesHashLink = hashLinkContent('favorites', 'Favorites');

  return (
    <React.Fragment>
      <div className="nav p-0 dropdown-menu-inline dropdown-menu-rounded dropdown-menu-hover-primary">
        {profileDataHashLink}

        {apiKeyHashLink}

        {/* {favoritesHashLink} */}
      </div>
    </React.Fragment>
  );
};

export default ProfileHashLinks;
