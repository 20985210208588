import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { userAppContext } from '../../Context/Context';
import $ from 'jquery';
import { Link } from 'react-router-dom';

function validate(input) {
  if ($(input).attr('type') == 'email' || $(input).attr('name') == 'email') {
    if (
      $(input)
        .val()
        .trim()
        .match(
          /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/,
        ) == null
    ) {
      return false;
    }
  } else {
    if ($(input).val().trim() == '') {
      return false;
    }
  }
}

function showValidate(input) {
  var thisAlert = $(input).parent();

  $(thisAlert).addClass('alert-validate');
}

function hideValidate(input) {
  var thisAlert = $(input).parent();

  $(thisAlert).removeClass('alert-validate');
}

export default function ChangePassword() {
  const [errorMessage, setErrorMessage] = useState();
  const { setIsAuthenticated, setAuthorization, setGroups } = userAppContext();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isPassVisible, setIsPassVisible] = useState({
    oldPass: false,
    newPass: false,
  });

  const togglePasswordVisibility = inputId => {
    setIsPassVisible(prevState => ({
      ...prevState,
      [inputId]: !prevState[inputId],
    }));
  };

  const validar = () => {
    var input = $('.validate-input .input100');
    var check = true;

    for (var i = 0; i < input.length; i++) {
      if (validate(input[i]) === false) {
        showValidate(input[i]);
        check = false;
      }
    }

    if (oldPassword === newPassword) {
      setErrorMessage('Old Password and New Password match');
      check = false;
    }

    return check && oldPassword !== newPassword;
  };

  const handleSubmit = evt => {
    evt.preventDefault();
    setErrorMessage('');

    if (validar()) {
      Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(user, oldPassword.trim(), newPassword.trim())
            .then(user => {
              window.location.href = '/';
            })
            .catch(err => {
              setErrorMessage(err.message);
            });
        })
        .catch(err => {
          setErrorMessage(err.message);
        });
    }
  };

  useEffect(() => {
    $('.input100').each(function () {
      $(this).on('blur', function () {
        if ($(this).val().trim() !== '') {
          $(this).addClass('has-val');
        } else {
          $(this).removeClass('has-val');
        }
      });
    });

    $('.validate-input .input100').each(function () {
      $(this).on('blur', function () {
        if (validate(this) === false) {
          showValidate(this);
        } else {
          $(this).parent().addClass('true-validate');
        }
      });
    });

    var input = $('.validate-input .input100');
    $('.validate-form').on('submit', function () {
      var check = true;

      for (var i = 0; i < input.length; i++) {
        if (validate(input[i]) === false) {
          showValidate(input[i]);
          check = false;
        }
      }

      return check;
    });

    $('.validate-form .input100').each(function () {
      $(this).focus(function () {
        hideValidate(this);
        $(this).parent().removeClass('true-validate');
      });
    });
  }, []);

  return (
    <>
      <div className="modal-dialog dialogReg modal-dialog-centered" role="document">
        <div className="modal-content register-container">
          <div className="border2-container p-3">
            <form className="validate-form" onSubmit={handleSubmit}>
              <div className="wrap-input100 validate-input" data-validate="Old Password is required">
                <div className="password-input">
                  <span className="label-input100">Old Password</span>
                  <span className="eye-icon" onClick={() => togglePasswordVisibility('oldPass')}>
                    <i
                      className={`fa ${isPassVisible['oldPass'] ? 'fa-eye' : 'fa-eye-slash'}`}
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <input
                  onChange={event => setOldPassword(event.target.value)}
                  name="password"
                  id="password"
                  type={`${isPassVisible['oldPass'] ? 'text' : 'password'}`}
                  className="input100"
                  placeholder="Old Password"
                />
                <span className="focus-input100" />
              </div>
              <div className="wrap-input100 validate-input" data-validate="New Password is required">
                <div className="password-input">
                  <span className="label-input100">New Password</span>
                  <span className="eye-icon" onClick={() => togglePasswordVisibility('newPass')}>
                    <i
                      className={`fa ${isPassVisible['newPass'] ? 'fa-eye' : 'fa-eye-slash'}`}
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <input
                  onChange={event => setNewPassword(event.target.value)}
                  name="cpassword"
                  type={`${isPassVisible['newPass'] ? 'text' : 'password'}`}
                  className="input100"
                  placeholder="New Password"
                />
                <span className="focus-input100" />
              </div>
              <Link style={{ float: 'right', marginBottom: '20px' }} to="/Profile-area">
                Cancel
              </Link>
              <div className="container-contact100-form-btn">
                <div className="wrap-contact100-form-btn">
                  <div className="contact100-form-bgbtn" />
                  <button className="contact100-form-btn">Send</button>
                </div>
              </div>
              {errorMessage ? <p class="text-danger text-center">{errorMessage}</p> : null}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
