import React from 'react';
import diseaseBaseLink from '../../Services/GenerateLinkForIdentifier';
import noSentenceSupportingAssoc from '../../Services/NoSentenceForAssociation';
import Tooltip from './Tooltip.jsx';

function arrangeColumns(
  index,
  columnName,
  isFilteringOrSorting,
  isColumnVisible,
  names,
  Columns,
  openFromMenu,
  tableIdent = '',
) {
  let tableColumns = Columns[index].columns.slice();

  // this fires only if the number of filters is greater than one and the tableColumns are NOT recreated
  // by the users actions (AKA: filtering or sorting a column and opening the external menu), with the exception
  // of making a new search
  if (names && names.length > 1 && columnName === '' && !openFromMenu && !isFilteringOrSorting) {
    tableColumns[0].visible = true;
    if (tableIdent === 'DDA') {
      tableColumns[1].visible = true;
      tableColumns[2].visible = true;
    }
  }

  // this fires only if the number of filters is exactly one and the tableColumns are NOT recreated
  // by the users actions (AKA: filtering or sorting a column and opening the external menu), with the exception
  // of erasing filters from the table
  if (names && names.length === 1 && columnName === '' && !openFromMenu && !isFilteringOrSorting) {
    if (tableIdent === 'CHEMICALS') tableColumns[0].visible = true;
    else tableColumns[0].visible = false;

    if (tableIdent === 'DDA') {
      tableColumns[1].visible = false;
      tableColumns[2].visible = false;
    }
  }

  // change the visible state of the chosen column
  if (columnName !== '') {
    tableColumns.forEach(column => {
      if (column.name === columnName) {
        column.visible = isColumnVisible;
      }
    });
  }

  return tableColumns;
}

function renderJaccard(instance, type) {
  const jcrdType = type === 'GENE' ? 'jaccard_genes' : 'jaccard_variants';
  let jcrd = instance.data[jcrdType] || null;
  if (jcrd != null) {
    if (jcrd < 0.01) {
      return jcrd?.toExponential(2);
    } else {
      return jcrd?.toFixed(2);
    }
  } else {
    return null;
  }
}

function renderAlleleDataVDA(instance, alleleType) {
  let alleleFreq = instance.data[`${alleleType}`] || [];
  let freqList = alleleFreq?.map(el => {
    let result;
    if (el === -1) {
      result = '-';
    } else if (el < 0.01) {
      result = el?.toExponential(2);
    } else {
      result = el?.toFixed(2);
    }
    return result;
  });
  return freqList.join('; ');
}

function renderAlleleDataVariant(instance, alleleType) {
  let alleles = instance.data.alleles;
  let allelicFrequencies = instance.data.frequencies;
  let results = [];

  // We don't always have AF for all the alternatives alleles, so cross reference the two fields for the order of the values.
  for (let allele of alleles) {
    let result = null;
    for (let freq of allelicFrequencies) {
      if (freq.alt === allele.alt) {
        if (freq.type === alleleType) {
          const alleleFreqValue = freq.AF;
          if (alleleFreqValue === -1) {
            result = null;
          } else if (alleleFreqValue < 0.01) {
            result = alleleFreqValue?.toExponential(2);
          } else {
            result = alleleFreqValue?.toFixed(2);
          }
        }
      }
    }
    results.push(result);
  }
  // Check that not all values are null, and format the string.
  if (results.filter(val => val !== null).length) {
    results = results?.map(val => (val === null ? '-' : val));
    results = results.join('; ');
  } else {
    results = null;
  }
  return results;
}

function renderVariantLink(instance) {
  const baseUrl = 'https://www.ncbi.nlm.nih.gov/snp/';
  const variant = instance.data.variantStrID;

  const variantUrl = `${baseUrl}${variant}`;
  return (
    <a href={variantUrl} target="_blank" rel="noreferrer">
      {variant} <i className="fa fa-external-link" aria-hidden="true"></i>
    </a>
  );
}

function renderDiseaseNameDDA(instance, DDADiseaseIdent) {
  let link = diseaseBaseLink(instance.data[`disease${DDADiseaseIdent}_UMLSCUI`]);
  let value = instance.data[`disease${DDADiseaseIdent}_Name`];
  return (
    <a href={link} title={value} target="_blank" rel="noreferrer">
      {value} <i className="fa fa-external-link" aria-hidden="true"></i>
    </a>
  );
}

function renderSentenceHTML(instance) {
  if (instance.data.sentenceHTML) {
    return <Tooltip value={instance.data.sentenceHTML} />;
  } else {
    return noSentenceSupportingAssoc();
  }
}

function renderRoundedValues(instance, attribute) {
  if (instance.data[attribute] !== null) {
    return instance.data[attribute]?.toFixed(2);
  } else {
    return null;
  }
}

function renderDiseaseClassesAttributes(value) {
  if (value) {
    let transformedValue = '';
    for (let i = 0; i < value.length; i++)
      if (i < value.length - 1) transformedValue += value[i] + '|';
      else transformedValue += value[i];
    return <span title={transformedValue}>{transformedValue}</span>;
  } else return '';
}

function renderNCTid(instance) {
  const nctIdArray = instance.data.nct_id;
  if (nctIdArray.length !== 0) {
    const baseUrl = 'https://clinicaltrials.gov/search?id=';

    return (
      <span>
        {nctIdArray.map((id, index) => {
          const link = `${baseUrl}${id}`;
          return (
            <span key={id}>
              <a href={link} target="_blank" rel="noreferrer">
                {id} <i className="fa fa-external-link" aria-hidden="true"></i>
              </a>
              {index < nctIdArray.length - 1 && ' | '}
            </span>
          );
        })}
      </span>
    );
  } else return '';
}

function renderDecimalValues(value) {
  if (value === null) return null;
  if (value >= 0.01) return value?.toFixed(2);
  if (value !== 0 && value < 0.01) return value?.toExponential(2);
  if (value === 0) return 0;
}

function renderListsFromObjects(instance, instanceAttribute, attribute) {
  const listOfInterest = instance.data[instanceAttribute];
  if (!listOfInterest) return '';
  if (listOfInterest.length === 0) return '';

  const itemsNames = listOfInterest?.map(item => item[attribute]);
  const itemsNamesStr = itemsNames.join(' | ');

  return <span>{itemsNamesStr}</span>;
}

export {
  arrangeColumns,
  renderAlleleDataVDA,
  renderAlleleDataVariant,
  renderVariantLink,
  renderDiseaseNameDDA,
  renderSentenceHTML,
  renderRoundedValues,
  renderDiseaseClassesAttributes,
  renderNCTid,
  renderDecimalValues,
  renderListsFromObjects,
  renderJaccard,
};
