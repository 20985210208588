import React from 'react';
import ReactGA from 'react-ga4';
import CommonHeader from '../Components/Headers/CommonHeader';
import ActivationContent from '../Components/AccountActivation/ActivationContent';
import Footer from '../Components/Home/Footer';
import $ from 'jquery';

export default function About() {
  $('.popover').hide();
  ReactGA.send({
    hitType: 'pageview',
    page: '/Activate',
    title: 'Account Activation',
  });

  return (
    <React.Fragment>
      <CommonHeader />
      <ActivationContent />
      <Footer />
    </React.Fragment>
  );
}
