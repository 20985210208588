import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import GeneGeneralInfo from '../GeneContent/GeneGeneralInfo';
import GeneSynonyms from '../GeneContent/GeneSynonyms';

export function getKeyName(baseName, number = '') {
  return `${baseName}${number}`;
}

export default function DetailGene({ values }) {
  return (
    <Tabs id="table-scroll">
      <TabList className="menuTabs">
        <Tab className="react-tabs__tab">General Info</Tab>
        <Tab className="react-tabs__tab">Synonyms</Tab>
      </TabList>

      <TabPanel className="tab-pane p-2">
        <GeneGeneralInfo geneDetailInfo={values} />
      </TabPanel>

      <TabPanel className="tab-pane p-2">
        <GeneSynonyms geneDetailInfo={values} />
      </TabPanel>
    </Tabs>
  );
}
