import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { userAppContext } from '../../Context/Context';

function Container() {
  const [homeInfo, setHomeInfo] = useState([
    '32K+ disease genes',
    '50M+ associations',
    '40K+ diseases & traits',
    '700K+ disease variants',
  ]);
  const [currentInfoIndex, setCurrentInfoIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(false);
  const paragraphRef = useRef(null);

  useEffect(() => {
    updateHomeInfo();
    const interval = setInterval(updateHomeInfo, 5000);
    return () => clearInterval(interval);
  }, []);

  const updateHomeInfo = () => {
    setCurrentInfoIndex(prevIndex => (prevIndex + 1) % homeInfo.length);
    setFadeIn(true);
    setTimeout(() => {
      setFadeIn(false);
    }, 4000);
  };

  const currentText = homeInfo[currentInfoIndex];
  const navigate = useNavigate();

  const handleBrowseAllClick = () => {
    navigate({
      pathname: '/search',
      search: '?view=ALL&idents=ALL&source=ALL&tab=GDA',
    });
  };

  const handleAskQuestionClick = () => {
    navigate({
      pathname: '/Assistant',
    });
  };

  return (
    <section style={{ textAlign: 'center' }}>
      <div className="row">
        <div className="col">
          {/* <h1 className="custom-slogan" style={{ letterSpacing: '2px' }}>
              Data for tomorrow’s health
            </h1> */}
          <h3 className="custom-home-h3 gradient-color" style={{ letterSpacing: '1px' }}>
            Unleash your research with the world's most reliable and extensive gene-disease association
            network.
          </h3>
          <div>
            <p className={`custom-home-p`} style={{ letterSpacing: '1px' }}>
              F-score 92% {' | '}
              <span className={`fade-in ${fadeIn ? 'fade-in-active' : ''}`}>{currentText}</span>
              {' | '}Cited in 6.500+ papers
              {/* {' | '}Est. 2010{' | '}
              <Link to={linkUrl}>
                Browse All
                <i className="px-1 bi-arrow-right"></i>
              </Link> */}
            </p>
          </div>
          <div>
            <button className="btn-gradient-pink" onClick={handleBrowseAllClick}>
              Browse All <i className="px-1 bi-arrow-right"></i>
            </button>
            <button className="btn-gradient-pink" onClick={handleAskQuestionClick}>
              AI Assistant <i class="bi bi-stars"></i> <i className="px-1 bi-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Container;
