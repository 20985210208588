import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

export function useViewChangeTracker(tableView, identifier, viewType = 'table', isUserUnauth = false) {
  const location = useLocation();
  const bubbleIdentifiers = ['ALL', 'VDA', 'GDA', 'DDA'];
  const menuIdentifiers = ['DISEASES', 'VARIANTS', 'GENES', 'DRUGS'];

  useEffect(() => {
    const ident = bubbleIdentifiers.includes(identifier) ? 'BOLAS' : 'MENU';
    let eventLabel;

    if (menuIdentifiers.includes(identifier)) {
      eventLabel = `${tableView} - ${ident} (${identifier})`;
    } else {
      eventLabel = `${tableView} - ${ident}`;
    }

    if (isUserUnauth) eventLabel += ' /examples';

    ReactGA.event({
      category: `${viewType}_view`,
      action: `view_${viewType}`,
      label: eventLabel,
    });
    ReactGA.send({ hitType: 'pageview', page: '/search', title: 'Search' });
  }, [location.pathname, tableView, identifier]);

  return '';
}
