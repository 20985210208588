import React from 'react';
import ReactGA from 'react-ga4';
import CommonHeader from '../Components/Headers/CommonHeader';
import PrivacyContent from '../Components/Privacy/PrivacyContent';
import Nav from '../Components/Privacy/Nav';
import Footer from '../Components/Home/Footer';
import $ from 'jquery';

export default function Privacy() {
  $('.popover').hide();
  ReactGA.send({
    hitType: 'pageview',
    page: '/Privacy-policy',
    title: 'Privacy Policy',
  });

  return (
    <React.Fragment>
      <CommonHeader />
      <Nav />
      <PrivacyContent />
      <Footer />
    </React.Fragment>
  );
}
