import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { userAppContext } from '../../Context/Context';

import NewPassword from './NewPassword';

export default function Reset({ setReset }) {
  const [errorMessage, setErrorMessage] = useState();
  const { setIsAuthenticated, setAuthorization, setGroups } = userAppContext();
  const [name, setName] = useState('');
  const [newPassword, setNewPassword] = useState(false);

  const handleSubmit = evt => {
    evt.preventDefault();
    try {
      Auth.forgotPassword(name)
        .then(data => {
          setNewPassword(true);
        })
        .catch(err => {
          setErrorMessage(err.message);
        });
    } catch (ex) {
      setErrorMessage(ex.message);
    }
  };

  const handleChangeName = event => {
    setName(event.target.value);
  };

  useEffect(() => {
    const validateInput = input => {
      if (input.type === 'email' || input.name === 'email') {
        return (
          input.value
            .trim()
            .match(
              /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/,
            ) !== null
        );
      } else {
        return input.value.trim() !== '';
      }
    };

    const showValidateInput = input => {
      const thisAlert = input.parentElement;
      thisAlert.classList.add('alert-validate');
    };

    const hideValidateInput = input => {
      const thisAlert = input.parentElement;
      thisAlert.classList.remove('alert-validate');
    };

    const inputs = document.querySelectorAll('.validate-input .input100');

    const validateForm = () => {
      let check = true;
      for (let i = 0; i < inputs.length; i++) {
        if (!validateInput(inputs[i])) {
          showValidateInput(inputs[i]);
          check = false;
        }
      }
      return check;
    };

    const handleBlur = event => {
      const input = event.target;
      if (validateInput(input)) {
        input.parentElement.classList.add('true-validate');
      } else {
        showValidateInput(input);
      }
    };

    inputs.forEach(input => {
      input.addEventListener('blur', handleBlur);
    });

    const form = document.querySelector('.validate-form');

    form.addEventListener('submit', event => {
      event.preventDefault();
      if (validateForm()) {
        handleSubmit(event);
      }
    });

    return () => {
      inputs.forEach(input => {
        input.removeEventListener('blur', handleBlur);
      });
      form.removeEventListener('submit', handleSubmit);
    };
  }, []);

  return newPassword ? (
    <NewPassword setReset={setReset} name={name} />
  ) : (
    <>
      <div className="txt-contenido">
        <div className="login-container mb-5">
          <div className="signin-container">
            <div className="col-12">
              <form className=" validate-form" onSubmit={handleSubmit}>
                <h3 style={{ color: 'white' }} className="reset-title">
                  E-MAIL ADDRESS
                </h3>
                <div className="wrap-input100 validate-input" data-validate="User Name is required">
                  <span className="label-input100" style={{ color: 'white' }}></span>
                  <input
                    style={{ color: 'white' }}
                    onChange={handleChangeName}
                    className="input100"
                    type="text"
                    name="name"
                    placeholder="Email..."
                  />
                </div>

                <a
                  style={{ float: 'right', marginBottom: '20px', color: 'white' }}
                  href="true"
                  onClick={evt => {
                    evt.preventDefault();
                    setReset(false);
                  }}
                >
                  Cancel
                </a>
                <div className="container-contact100-form-btn">
                  <div className="wrap-contact100-form-btn">
                    <div className="contact100-form-bgbtn" />
                    <button className="contact100-form-btn">Send</button>
                  </div>
                  <div className="mt-3">
                    {errorMessage ? <p className="text-danger text-center">{errorMessage}</p> : null}
                  </div>
                  <p className="mt-3" style={{ color: 'white' }}>
                    We will send the conformation code to your email
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
