import React from 'react';
import Logo from '../Common/Logo';
import SignUpForm from './SignUpForm';
import Testimony from './Testimony';
import Feature from './ Feature';

function ApplyTrial() {
  return (
    <React.Fragment>
      <div className="container trial-page">
        <div className="apply-txt pt-4">
          <div className="apply-div">
            <h1 className="pb-2">Dive Deeper with Millions of Data Points Using DISGENET Advanced</h1>
            <h6>
              <strong>START YOUR 7-DAY FREE TRIAL</strong>
            </h6>
            <h6 className="mt-4">
              Explore the world's most reliable and extensive gene-disease association network and unlock all
              advanced features from the Advanced plan, including our API,
              <strong> for 7 days absolutely free.</strong>
            </h6>
            <div>
              <img
                className=""
                src="../../../static/images/disgenetpc.png"
                alt="logo disgenet"
                style={{
                  width: '650px',
                }}
              />
            </div>
          </div>
          <div>
            <SignUpForm />
          </div>
        </div>
        <div className="testimony-div">
          <Testimony />
        </div>
        <div className="benefits">
          <Feature />
        </div>
      </div>
    </React.Fragment>
  );
}

export default ApplyTrial;
