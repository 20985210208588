import React, { useState } from 'react';
import SideBarContent from '../QueriedContent/SideBarContent';
import { getTitle, getId, getName } from '../Constants';
import { searchContext } from '../../Context/Context';

export default function InfoDiv({ searchItems, view, setIsInfoSidebarOpen }) {
  const { selectedInfoItem } = searchContext();

  const id = getId(selectedInfoItem);
  const name = getName(selectedInfoItem);
  const title = getTitle(selectedInfoItem);

  const handleCloseNav = () => {
    setIsInfoSidebarOpen(false);
  };

  return (
    <section className="section-info mt-5">
      <div className="row p-2">
        <h6 className="ml-2" key={id}>{`${title}, ${name}`}</h6>{' '}
        <button className="ml-2 d-flex justify-content" onClick={handleCloseNav}>
          <a className="closebtn bi-arrow-left-circle"></a>
        </button>
      </div>

      <div>
        <SideBarContent searchItems={searchItems} selectedInfoItem={selectedInfoItem} view={view} />
      </div>
    </section>
  );
}
