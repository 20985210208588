import React from 'react';
import { DATASETS } from '../Constants.jsx';
import SectionItem from './SectionItem.jsx';

function Section({ data, title }) {
  return (
    <div className="react-dropdown__section-container">
      <div className="react-dropdown__section-title">
        <strong>{title}</strong>
      </div>
      <ul role="listbox" className="react-dropdown-list">
        {data.map((item, index) => {
          return <SectionItem key={item + index} item={item} />;
        })}
      </ul>
    </div>
  );
}

export default Section;
