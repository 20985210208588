import React, { Fragment, useEffect, useState } from 'react';

import {
  getGenePathways,
  onReactomeDiagramReady,
  highlightPathway,
  resetHighlightPathway,
} from './PathwayDiagramHelpers';

import PathwayDiagramContent from './PathwayDiagramContent';
import LoadingSpinner from '../UI/LoadingSpinner';
import ErrorMessage from '../UI/ErrorMessage';

export default function PathwayDiagram({ geneSymbol }) {
  const [availablePathways, setAvailablePathways] = useState([]);
  const [selectedPathway, setSelectedPathway] = useState({
    name: '',
    identifier: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState({
    code: null,
    status: false,
    title: null,
    subTitle: null,
  });
  const [isDiagramLoaded, setIsDiagramLoaded] = useState(false);

  useEffect(() => {
    getGenePathways({
      geneSymbol,
      setAvailablePathways,
      setSelectedPathway,
      setIsLoading,
      setIsError,
    });
  }, [geneSymbol]);

  useEffect(() => {
    setIsDiagramLoaded(false);
    if (selectedPathway.identifier) {
      onReactomeDiagramReady({
        diagramToLoad: selectedPathway.identifier,
        setIsDiagramLoaded,
        setIsError,
      });
    }
  }, [selectedPathway.identifier]);

  useEffect(() => {
    if (isDiagramLoaded) {
      resetHighlightPathway(geneSymbol);
    }
  }, [isDiagramLoaded]);

  const onChangePathway = pathway => {
    setSelectedPathway(pathway);
  };

  return (
    <Fragment>
      {isLoading && (
        <div className="spinner-container">
          <LoadingSpinner spinnerType={'extra-content'} />
        </div>
      )}
      {!isLoading && isError.status && <ErrorMessage title={isError.title} subtitle={isError.subTitle} />}
      {!isLoading && !isError.status && (
        <PathwayDiagramContent
          geneSymbol={geneSymbol}
          onChangePathway={onChangePathway}
          selectedPathway={selectedPathway}
          highlightPathway={highlightPathway}
          availablePathways={availablePathways}
        />
      )}
    </Fragment>
  );
}
