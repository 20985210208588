/* global Reactome */
import axios from 'axios';

class Pathway {
  constructor(data) {
    this.name = data.displayName;
    this.identifier = data.stId;
  }
}

const fallback = {
  title: 'Could not fetch pathways',
  subTitle: 'Please try again later',
};

const getErrorMessage = (errorCode, geneSymbol) => {
  const errorMessageObj = {
    404: {
      title: `No pathways found for ${geneSymbol}`,
      subTitle: 'Please, try searching a different gene',
    },
    500: {
      title: 'Could not fetch pathways',
      subTitle: 'Please try again later',
    },
  };

  if (!errorCode || !Object.keys(errorMessageObj).includes(errorCode.toString())) {
    return { title: null, subTitle: null };
  }

  return errorMessageObj[errorCode];
};

// If the reactome ContentService crashes, take the chance to improve this error handling code.
const setErrorState = (error, setIsError, geneSymbol) => {
  const {
    response: { data },
  } = error;
  const { title, subTitle } = getErrorMessage(data.code, geneSymbol);
  setIsError({
    code: data.code,
    status: true,
    title: title ? title : fallback.title,
    subTitle: subTitle ? subTitle : fallback.subTitle,
  });
};

export const getGenePathways = async ({
  geneSymbol,
  setAvailablePathways,
  setSelectedPathway,
  setIsLoading,
  setIsError,
}) => {
  try {
    const resource = 'UniProt';
    const response = await axios({
      method: 'get',
      // With the content service API
      url: `https://reactome.org/ContentService/data/mapping/${resource}/${geneSymbol}/pathways`,
      params: {
        // Human taxId
        species: '9606',
      },
    });

    const genePathways = response.data.map(pathway => new Pathway(pathway));

    setIsLoading(false);
    setIsError({ code: null, status: false, title: null, subTitle: null });
    setAvailablePathways(genePathways);
    setSelectedPathway(genePathways[0]);
  } catch (e) {
    setIsLoading(false);
    setErrorState(e, setIsError, geneSymbol);
  }
};

let diagram;

export const onReactomeDiagramReady = ({ diagramToLoad, setIsDiagramLoaded, setIsError }) => {
  // This function is automatically called when the widget code is ready to be used
  try {
    let counter = 0;
    diagram = Reactome.Diagram.create({
      proxyPrefix: 'https://reactome.org',
      placeHolder: 'diagramHolder',
      width: 950, // minimum recommended width
      height: 500,
    });

    // Initialising it to the selected pathway
    diagram.loadDiagram(diagramToLoad);
    setIsError({ code: null, status: false, title: null, subTitle: null });
    diagram.onDiagramLoaded(() => {
      // this check avoids the callback in onDiagramLoaded to be called more times than it needs to
      if (counter === 0) {
        setIsDiagramLoaded(true);
        counter++;
      }
    });
  } catch (e) {
    setIsError({
      code: 404,
      status: true,
      title: 'Error when loading Reactome',
      subTitle: 'Please, refresh Disgenet and try again',
    });
  }
};

export const highlightPathway = geneSymbol => {
  // DO NOT erase: the line above fixes issues with bug in highlight functionality
  diagram.g.k = true;
  diagram.flagItems(geneSymbol);
};

export const resetHighlightPathway = geneSymbol => {
  // DO NOT erase: the line above fixes issues with bug in highlight functionality
  diagram.g.k = true;
  diagram.resetFlaggedItems();
  diagram.flagItems(geneSymbol);
};
