import React, { Fragment } from 'react';

import LoadingSpinner from '../../../UI/LoadingSpinner';
import CytoscapeChart from './CytoscapeChart';
import GraphTopContent from '../Common/GraphTopContent';
import { NoRecordsFound, NoGraphsAvailable } from '../../NoRecordsFound';

import { uniqueIdentViewsVariables, getNetworkData, unifyNetworkObj as unifyData } from './NetworkHelpers';

import useNetworkChart from '../../graphHooks/UseNetworkChart';

function NetworkChart({
  source,
  keySearch,
  view,
  names,
  chartInfo,
  setChartInfo,
  baseUrl,
  parent,
  networkSearchVariables,
}) {
  // Data necessary for using the custom hook
  const pageSize = 20;

  // empty nodes and edges arrays as the initial data for the chart
  const initialChartData = { nodes: [], edges: [] };

  // calling the useRequestMultipleFilters hook
  const {
    chartData: networkData,
    isLoading,
    isPayloadEmpty,
  } = useNetworkChart({
    chartInfo,
    initialChartData,
    keySearch,
    source,
    baseUrl,
    parent,
    pageSize,
    view,
    getNetworkData,
    unifyData,
    uniqueIdentViewsVariables,
    names,
  });

  // if the nodes array is empty, the chart is empty
  const isChartDataEmpty = networkData.nodes.length === 0 ? true : false;

  return (
    <section>
      {isLoading && (
        <div className="row">
          {' '}
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && !isPayloadEmpty && (
        <Fragment>
          {!isChartDataEmpty && (
            <Fragment>
              <GraphTopContent
                graphSearchVariables={networkSearchVariables}
                chartInfo={chartInfo}
                setChartInfo={setChartInfo}
                parent={parent}
                resultsNumber={20}
              />
              <section className="row network-container">
                <CytoscapeChart elements={networkData} names={names} parent={parent} chartInfo={chartInfo} />
              </section>
            </Fragment>
          )}
          {isChartDataEmpty && (
            <Fragment>
              <GraphTopContent
                graphSearchVariables={networkSearchVariables}
                chartInfo={chartInfo}
                setChartInfo={setChartInfo}
                parent={parent}
                resultsNumber={0}
              />
              <NoGraphsAvailable />
            </Fragment>
          )}
        </Fragment>
      )}
      {isPayloadEmpty && !isLoading && (
        <div className="row">
          {' '}
          <NoRecordsFound />
        </div>
      )}
    </section>
  );
}

export default NetworkChart;
