// Using commonJS module export instead of ES6 export for compatibility with serverless
// update serverless function with changes to this file
// serverless/dgn-cognito-preSignup

const whitelist = [
  'tseeley4@comcast.net',
  'drpathak125@gmail.com',
  'ipsita.bst@gmail.com',
  'manju.ajanal@gmail.com',
  'ivorivetta@gmail.com', // just for testing
];

module.exports = whitelist;
