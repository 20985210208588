const Color = {
  background: '#cad8dd',
};

function getResultados(resultados) {
  let keys = resultados.join(',');
  return keys;
}

export { getResultados, Color };
