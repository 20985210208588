import React from 'react';
import CommonHeader from '../Components/Headers/CommonHeader.jsx';
import ChatBot from '../Components/ChatBot/ChatBot.jsx';
import Footer from '../Components/Home/Footer.jsx';

export default function ChatBotPage() {
  return (
    <React.Fragment>
      <CommonHeader />
      <ChatBot />
      <Footer />
    </React.Fragment>
  );
}
