import React from 'react';
import { Helmet } from 'react-helmet-async';
import { TopRightMenu } from '../Components/NavMenus/TopNavigation';
import Footer from '../Components/Home/Footer';
import ApplyTrialCN from '../Components/SignUpCN/ApplyTrialPageCN';
import Logo from '../Components/Common/Logo';

function ApplyTrialPageCN() {
  return (
    <React.Fragment>
      <div className="header-content">
        <Helmet>
          <title>DISGENET: Apply for FREE TRIAL</title>
          <meta name="description" content="使用 DISGENET Advanced 深入挖掘数百万个数据点" />
          <link rel="canonical" href="/free-trial-apply" />
        </Helmet>

        <div>
          <Logo />
        </div>
        <div>
          <TopRightMenu />
        </div>
      </div>
      <div className="d-flex justify-content-end mx-4"></div>
      <ApplyTrialCN />
      <Footer />
    </React.Fragment>
  );
}

export default ApplyTrialPageCN;
