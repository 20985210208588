import React from 'react';

function LoadingSpinner({ spinnerType = 'graph' }) {
  return (
    <div className={`loading-spinner ${spinnerType}`}>
      <div className="spinner-border text-info" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <p>Loading...</p>
    </div>
  );
}

export default LoadingSpinner;
