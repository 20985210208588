import { DATASETS } from '../Components/Constants';

class DDAModel {
  constructor(data) {
    this.disease1_Name = data.disease1_Name;
    this.disease1_UMLSCUI = data.disease1_UMLSCUI;
    this.disease2_Name = data.disease2_Name;
    this.disease2_UMLSCUI = data.disease2_UMLSCUI;
    this.ngenes_diseaseID_1 = data.ngenes_diseaseID_1;
    this.ngenes_diseaseID_2 = data.ngenes_diseaseID_2;
    this.nvariants_diseaseID_1 = data.nvariants_diseaseID_1;
    this.nvariants_diseaseID_2 = data.nvariants_diseaseID_2;
    this.shared_genes = data.shared_genes;
    this.shared_variants = data.shared_variants;
    this.jaccard_genes = this.renderJaccardValues(data.jaccard_genes);
    this.jaccard_variants = this.renderJaccardValues(data.jaccard_variants);
    this.pValue_JG = this.renderJaccardValues(data.pvalue_jaccard_genes);
    this.pValue_JV = this.renderJaccardValues(data.pvalue_jaccard_variants);
  }

  renderJaccardValues(value) {
    if (value === null) return null;
    if (value >= 0.01) return value.toFixed(2);
    if (value !== 0 && value < 0.01) return value.toExponential(2);
    if (value === 0) return 0;
  }
}

class DDATable extends DDAModel {
  constructor(data) {
    super(data);
  }

  get valueDisease1() {
    return `UMLS_${this.disease1_UMLSCUI}`;
  }

  get valueDisease2() {
    return `UMLS_${this.disease2_UMLSCUI}`;
  }

  get combinedValueDisease() {
    return [this.valueDisease2, this.valueDisease1];
  }

  getNames() {
    const names = [
      {
        name: `${this.disease2_Name}, ${this.disease2_UMLSCUI}`,
        value: this.valueDisease2,
        title: DATASETS.DISEASES,
      },
      {
        name: `${this.disease1_Name}, ${this.disease1_UMLSCUI}`,
        value: this.valueDisease1,
        title: DATASETS.DISEASES,
      },
    ];

    return names;
  }
}

export { DDAModel, DDATable };
