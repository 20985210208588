const listSourceAcademic = {
  CURATED: ['CURATED'],
};

// sources GDA and DDA
const listSourceGDAs_DDAs = {
  CURATED: ['CURATED', 'CLINGEN', 'CLINVAR', 'MGD_HUMAN', 'ORPHANET', 'PSYGENET', 'RGD_HUMAN', 'UNIPROT'],
  CLINICALTRIALS: ['CLINICALTRIALS'],
  INFERRED: ['INFERRED', 'HPO', 'GWASCAT', 'PHEWASCAT'],
  MODELS: ['MODELS', 'MGD_MOUSE', 'TEXTMINING_MODELS', 'RGD_RAT'],
  TEXTMINING: ['TEXTMINING_HUMAN'],
  ALL: ['ALL'],
};

// sources Variant and VDAs
const listSourceVariantAll_VDAs = [
  'ALL',
  'CLINVAR',
  'CURATED',
  'GWASCAT',
  'PHEWASCAT',
  'UNIPROT',
  'TEXTMINING_HUMAN',
];

// sources Disease and Gene
const listSourceDiseaseAll_GeneALL = {
  CURATED: ['CURATED', 'CLINGEN', 'CLINVAR', 'MGD_HUMAN', 'ORPHANET', 'PSYGENET', 'RGD_HUMAN', 'UNIPROT'],
  CLINICALTRIALS: ['CLINICALTRIALS'],
  INFERRED: ['INFERRED', 'HPO', 'PHEWASCAT'],
  MODELS: ['MODELS', 'MGD_MOUSE', 'TEXTMINING_MODELS', 'RGD_RAT'],
  GWASCAT: ['GWASCAT'],
  TEXTMINING: ['TEXTMINING_HUMAN'],
  ALL: ['ALL'],
};

export { listSourceGDAs_DDAs, listSourceVariantAll_VDAs, listSourceDiseaseAll_GeneALL, listSourceAcademic };
