import React from 'react';
import PropTypes from 'prop-types';

import HeatmapLineDashboard from './HeatmapLineDashboard';

function GraphTitle({ chartVariable, parent, resultsNumber }) {
  return (
    <h1 className="graph-title">
      Top {resultsNumber} {parent}'s results selected by {chartVariable}
    </h1>
  );
}

// Define prop types for GraphTitle
GraphTitle.propTypes = {
  chartVariable: PropTypes.string.isRequired,
  parent: PropTypes.string.isRequired,
  resultsNumber: PropTypes.number.isRequired,
};

function GraphTopContent({ graphSearchVariables, chartInfo, setChartInfo, parent, resultsNumber }) {
  return (
    <div className="graph-top-content">
      <div className="graph-title-container">
        {resultsNumber !== 0 && (
          <GraphTitle
            chartVariable={chartInfo.variableFullName}
            parent={parent}
            resultsNumber={resultsNumber}
          />
        )}
      </div>
      <div className="heatmap-line-dashboard-container">
        <HeatmapLineDashboard
          chartVariables={graphSearchVariables}
          chartInfo={chartInfo}
          setChartInfo={setChartInfo}
        />
      </div>
    </div>
  );
}

// Define prop types for GraphTopContent
GraphTopContent.propTypes = {
  graphSearchVariables: PropTypes.array.isRequired, // Adjust the prop type accordingly
  chartInfo: PropTypes.object.isRequired,
  setChartInfo: PropTypes.func.isRequired,
  parent: PropTypes.string.isRequired,
  resultsNumber: PropTypes.number.isRequired,
};

export default GraphTopContent;
