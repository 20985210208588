import React, { useEffect } from 'react';
import { userAppContext } from '../Context/Context';
import { set } from 'lodash';

const ResetApiKey = ({ userResetApiKey, setApiKeyRequest }) => {
  const { setUserApiKey } = userAppContext();

  const userName = userResetApiKey[0];
  const userPass = userResetApiKey[1];

  // const url = `${process.env.REACT_APP_URL_API_API}/api/v1/public/retrievekey?username=${userName}&password=${userPass}&updateKey=true`;
  const url = `${process.env.REACT_APP_URL_API_API}api/v1/public/resetkey`;

  useEffect(() => {
    if (userName !== undefined) {
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userName,
          password: userPass,
        }),
      })
        .then(response => response.json())
        .then(result => {
          setUserApiKey(result.payload?.apiKey);
          setApiKeyRequest(result);
        })
        .catch(error => console.log('error', error));
    }
  }, [userName]);

  return <span>{''}</span>;
};

export default ResetApiKey;
