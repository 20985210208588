import { useState } from 'react';

export const patchUseSearchItems = () => {
  const [searchItems, setSearchItems] = useState([]);

  const patchSetSearchItems = newItems => {
    // preserve updater function funcionality
    if (typeof newItems === 'function') {
      setSearchItems(newItems);
      return;
    }
    // intializing with empty argument:
    if (newItems?.length === 0) {
      setSearchItems([]);
      return;
    }

    // when a nextState object is provided, patch and return:
    const patchedItems = patchItems(newItems);
    // Then call setSearchItems with the patched items
    setSearchItems(patchedItems);
  };

  return [searchItems, patchSetSearchItems];
};

export const patchItems = items => {
  // standalone function to patch items outside of setSearchItems
  // for example whn using updater functions with setFunction
  let patchedItems = items;
  // if it's a single object, patch it
  if (typeof items === 'object' && !Array.isArray(items)) {
    patchedItems = patchDataset(patchedItems);
    patchedItems = patchId(patchedItems);
    return patchedItems;
  } else if (Array.isArray(items)) {
    // if it's an array, patch each item
    patchedItems = items.map(item => {
      item = patchDataset(item);
      item = patchId(item);
      return item;
    });
    return patchedItems;
  }
};

export const patchDataset = item => {
  let dataset;
  if (item?.length === 0) return item;
  else if (item.diseaseUMLSCUI) dataset = 'DISEASES';
  else if (item.geneID) dataset = 'GENES';
  else if (item.strID) dataset = 'VARIANTS';
  else if (item.chemUMLSCUI) dataset = 'CHEMICALS';

  return {
    ...item,
    dataset: dataset,
  };
};

export const patchId = item => {
  // add the .id parameter to each searchItem
  let key;
  if (item?.length === 0) return item;
  else if (item.diseaseUMLSCUI) key = 'diseaseUMLSCUI';
  else if (item.geneID) key = 'geneID';
  else if (item.strID) key = 'strID';
  else if (item.chemUMLSCUI) key = 'chemUMLSCUI';

  return {
    ...item,
    id: item[key],
  };
};
