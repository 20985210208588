import React from 'react';

class EvidenceSentenceLegend extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="pb-5">
        <ul className="evidence-legend">
          <li>
            <span className="gene"></span> GENE
          </li>
          <li>
            <span className="variant"></span> VARIANT
          </li>
          <li>
            <span className="disease"></span> DISEASE
          </li>
          <li>
            <span className="negation_marker"></span> NEGATION MARKER
          </li>
          <li>
            <span className="chemical"></span> CHEMICALS
          </li>
        </ul>
      </div>
    );
  }
}

export { EvidenceSentenceLegend };
