import React from 'react';
import { areSearchesMultiple } from './ManageGraph';

const multipleSearchesButtons = ['Heatmap', 'Network'];
const singleSearchesButtons = ['Line', 'Network'];

function getChartsButtons(names, title, ident) {
  return areSearchesMultiple(names, title, ident) ? multipleSearchesButtons : singleSearchesButtons;
}

export default function SelectGraphButton({ selectedChart, setSelectedChart, names, sectionTitle, title }) {
  return (
    <div className="dropdown">
      <button
        type="button"
        className="ml-2 btn dropdown-toggle select-chart"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="fa fa-bar-chart mr-2" />
        Select graph: {selectedChart}
        <i className="fa fa-angle-down op-0-5 ml-2" />
      </button>
      <div className="dropdown-menu dropdown-menu-right">
        {getChartsButtons(names, sectionTitle, title).map(buttonTitle => {
          return (
            <a
              key={buttonTitle}
              className={`dropdown-item select-chart-options
                              ${selectedChart === buttonTitle.toUpperCase() ? 'active' : ''}
                            `}
              onClick={() => setSelectedChart(buttonTitle.toUpperCase())}
            >
              {buttonTitle}
            </a>
          );
        })}
      </div>
    </div>
  );
}
