import React from 'react';
import { Link } from 'react-router-dom';
import CommonHeader from '../Components/Headers/CommonHeader';
import Footer from '../Components/Home/Footer';

function NotFoundContent() {
  return (
    <div>
      <CommonHeader />
      <div className="notFoundPage">
        <h1>404</h1>
        <h2>This Page Not Found</h2>
        <p>
          Please return to <Link to="/">Home</Link>
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default NotFoundContent;
