import React, { useState, useEffect } from 'react';
import Dropdown from './Dropdown';
import TextInput from './TextInput';
import { FirstSearchButton } from './SubmitButton';
import { searchContext } from '../../Context/Context';
import Tag from './Tag';
import AdvancedSearch from './AdvancedSearch';

const SearchBar = () => {
  const { searchItems, setSearchItems } = searchContext();
  const [value, setValue] = useState(''); // text input in search box
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Clear searchbox on navigation changes
    setValue('');
  }, [location.pathname, location.search]);

  const handleDropdownToggle = isOpen => {
    setIsDropdownOpen(isOpen);
  };

  const clearAll = evt => {
    evt.preventDefault();
    setSearchItems([]);
  };

  const handleSearchMore = () => {
    window.open('/advanced-search', '_blank');
  };

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  return (
    <div className="row">
      <div data-wow-delay="0.1s" className="col-12  wow fadeIn">
        <div className="search-input">
          <div id="app">
            <div id="tags" className="row">
              <div className="col-11 etiquetas etpeq">
                {searchItems &&
                  searchItems.map((item, index) => (
                    <Tag key={index} item={item} setSearchItems={setSearchItems} />
                  ))}
              </div>
              {searchItems.length > 0 && (
                <div className="col-1 text-center my-2 m-0 p-0" onClick={clearAll}>
                  <a className="clearBtn text-nowrap">Clear All</a>
                </div>
              )}
            </div>
            <div>
              <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                <TextInput value={value} setValue={setValue} />
                <div style={{ position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)' }}>
                  <FirstSearchButton setValue={setValue} />
                </div>
              </div>
              {value.trim().length >= 3 && (
                <Dropdown
                  value={value}
                  setValue={setValue}
                  searchItems={searchItems}
                  setSearchItems={setSearchItems}
                  onDropdownToggle={handleDropdownToggle}
                />
              )}
              {/* {!isDropdownOpen && (
                <div className="d-flex justify-content-end pr-1">
                  <button className="btn-search-more" onClick={() => setShowPopup(prev => !prev)}>
                    {showPopup ? 'Close' : 'Advanced Search'}
                  </button>
                </div>
              )} */}
            </div>
          </div>
        </div>
        {/* {showPopup && <AdvancedSearch showPopup={showPopup} setShowPopup={setShowPopup} />} */}
      </div>
    </div>
  );
};

export default SearchBar;

// {notFound.length > 0 && (
//   <div className="p-2">
//     <h4>Not Found:</h4>
//     <ul>
//       {notFound.map(item => (
//         <li key={item}>
//           <strong>{item}</strong>
//         </li>
//       ))}
//     </ul>
//   </div>
// )}
