import React, { useRef, useEffect } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'animate.css';
import ClipboardJS from 'clipboard';

export default function Tooltip({ value }) {
  const toCopy = useRef(null);

  const handleCopyClick = () => {
    const copyIcon = document.querySelector('.bi-copy');
    copyIcon.classList.add('animate__flipInX');
  };

  useEffect(() => {
    const clipboard = new ClipboardJS('.bi-copy', {
      text: function (trigger) {
        const textToCopy = toCopy.current;
        if (textToCopy) {
          return textToCopy.textContent;
        }
        return '';
      },
    });

    clipboard.on('success', function (e) {
      handleCopyClick();
      e.clearSelection();
    });

    return () => {
      clipboard.destroy();
    };
  }, []);

  const popover = (
    <Popover>
      <div
        ref={toCopy}
        dangerouslySetInnerHTML={{ __html: value }}
        className="text-secondary font-weight-bold p-2"
      ></div>
      <div className="cursor-pointer d-flex flex-row-reverse">
        <button className="bi-copy mt-0 p-2 animate__animated"> Copy</button>
      </div>
    </Popover>
  );

  return (
    <OverlayTrigger trigger={['click', 'focus']} placement="left" overlay={popover} rootClose>
      <span dangerouslySetInnerHTML={{ __html: value }} className="cursor-pointer" title="click"></span>
    </OverlayTrigger>
  );
}
