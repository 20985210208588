import React from 'react';
import CommonHeader from '../Components/Headers/CommonHeader';
import Footer from '../Components/Home/Footer';

function BadParamsContent() {
  const params = new URLSearchParams(window.location.search);
  return (
    <div>
      <CommonHeader />
      <div className="notFoundPage">
        {/* <h1>404</h1> */}
        <h2>Page Not Found</h2>
        <p>Invalid search parameters</p>
        <p>Check the spelling of your shared link and try again</p>
      </div>
      {/* <div className="notFoundPage">
        <h1>Error</h1>
        <h2>Error: Invalid Parametersss</h2>
        <p>
          {location}
          {params &&
            Array.from(params.keys()).map((key, index) => (
              <span key={index}>
                {key}: {params.get(key)}
                <br />
              </span>
            ))}
        </p>
      </div> */}
      <Footer />
    </div>
  );
}

export default BadParamsContent;
