import React, { useState, useEffect } from 'react';
import { userAppContext } from '../../Context/Context';
import fetchEndpoint from '../../Services/fetchEndpoint';
import { DATASETS } from '../Constants';
import DetailGene from './DetailGene';
import DetailDisgenet from './DetailDisgenet';
import DetailVariant from './DetailVariant';
import DetailDrug from './DetailDrug';
import { getId } from '../Constants';

function SideBarContent({ selectedInfoItem, view }) {
  const { authorization, isAuthenticated, setIsAuthenticated, setGroups, setAuthError, setAuthorization } =
    userAppContext();
  const unauth = isAuthenticated ? '' : '?skey=unauth';
  const [dataSource, setDataSource] = useState([]);
  const [vocabulary, setvocabulary] = useState([]);
  const [tipo, setTipo] = useState('');
  const [link, setLink] = useState('');
  const viewId = view;
  const id = getId(selectedInfoItem);

  useEffect(() => {
    setTipo('');
    if (viewId === DATASETS.DISEASES) {
      fetchEndpoint(
        `api/disease/detail/${id}${unauth}`,
        authorization,
        setIsAuthenticated,
        setGroups,
        setAuthError,
        setAuthorization,
      ).then(result => {
        if (result?.status == 'OK') {
          setDataSource(result);
          let linkUMLSCUI = 'http://linkedlifedata.com/resource/umls/id/' + result.payload.diseaseUMLSCUI;
          setLink(linkUMLSCUI);

          let vocabularies = [];
          result.payload.diseaseCodes.map(value => {
            if (!vocabularies.includes(value.vocabulary)) vocabularies.push(value.vocabulary);
          });
          setvocabulary(vocabularies);
          setTipo(view);
        }
      });
    } else if (viewId === DATASETS.GENES) {
      fetchEndpoint(
        `api/gene/detail/${id}${unauth}`,
        authorization,
        setIsAuthenticated,
        setGroups,
        setAuthError,
        setAuthorization,
      ).then(result => {
        if (result?.status == 'OK') {
          setDataSource(result);
          let linkGene = 'http://www.ncbi.nlm.nih.gov/gene/' + result.payload.geneID;
          setLink(linkGene);
          setTipo(viewId);
        }
      });
    } else if (viewId == DATASETS.VARIANTS) {
      fetchEndpoint(
        `api/variant/detail/${id}${unauth}`,
        authorization,
        setIsAuthenticated,
        setGroups,
        setAuthError,
        setAuthorization,
      ).then(result => {
        if (result?.status === 'OK') {
          setDataSource(result);
          setTipo(viewId);
        }
      });
    } else if (viewId == DATASETS.CHEMICALS) {
      fetchEndpoint(
        `api/chem/detail/${id}${unauth}`,
        authorization,
        setIsAuthenticated,
        setGroups,
        setAuthError,
        setAuthorization,
      ).then(result => {
        if (result?.status === 'OK') {
          setDataSource(result.payload);
          setTipo(viewId);
        }
      });
    }
  }, [selectedInfoItem, viewId, id]);

  return (
    <>
      {tipo == DATASETS.DISEASES && (
        <DetailDisgenet values={dataSource.payload} vocabulary={vocabulary} link={link} />
      )}
      {tipo == DATASETS.GENES && (
        <DetailGene values={dataSource.payload} vocabulary={vocabulary} link={link} />
      )}
      {tipo == DATASETS.VARIANTS && <DetailVariant values={dataSource.payload} vocabulary={vocabulary} />}
      {tipo == DATASETS.CHEMICALS && <DetailDrug values={dataSource} />}
    </>
  );
}

export default SideBarContent;
