import { DATASETS } from '../Components/Constants';

class VDAModel {
  constructor(data) {
    this.variantStrID = data.variantStrID;
    this.geneSymbol = data.geneSymbol;
    this.geneDescription = data.geneDescription;
    this.geneNcbiIDs = data.geneNcbiIDs;
    this.numGenesAssociatedToDisease = data.numGenesAssociatedToDisease;
    this.numDiseasesAssociatedToVariant = data.numDiseasesAssociatedToVariant
      ? data.numDiseasesAssociatedToVariant
      : data.numDiseasesAssociatedToVariant_ALL;
    this.numVariantsAssociatedToDisease = data.numVariantsAssociatedToDisease;
    this.numPMIDsupportingAssociation = data.numPMIDsupportingAssociation;
    this.alleles_ref = data.alleles_ref;
    this.alleles_alt = data.alleles_alt;
    this.diseaseUMLSCUI = data.diseaseUMLSCUI;
    this.diseaseName = data.diseaseName;
    this.pmid = data.pmid;
    this.score = data.score;
    this.EI = this.transformEIValue(data.EI);
    this.numPmidsWithChemsEvidence = data.numberPmidsWithChemsIncludedInEvidence;
    this.numChemsEvidence = data.numberChemsIncludedInEvidence;
    this.numPmidsWithChemsFiltered = data.numberPmidsWithChemsFiltered;
    this.coord = data.coord;
  }

  transformEIValue(eiValue) {
    return eiValue !== null && eiValue !== undefined ? Number(eiValue.toFixed(2)) : null;
  }
}

class VDATable extends VDAModel {
  constructor(data) {
    super(data);
  }

  get valueDisease() {
    return `UMLS_${this.diseaseUMLSCUI}`;
  }

  get valueVariant() {
    return [this.variantStrID];
  }

  get valueGene() {
    return this.geneNcbiIDs;
  }

  get alleles() {
    return this.alleles_ref + '/' + this.alleles_alt.join(',');
  }

  getName() {
    const name = [
      {
        name: this.diseaseName + ', ' + this.diseaseUMLSCUI,
        value: this.valueDisease,
        title: DATASETS.DISEASES,
      },
    ];

    return name;
  }

  getNameDV() {
    const nameDV = [
      {
        name: `${this.variantStrID}${this.geneSymbol ? `, ${this.geneSymbol}` : ''}`,
        value: this.variantStrID,
        title: DATASETS.VARIANTS,
      },
      {
        name: this.diseaseName + ', ' + this.diseaseUMLSCUI,
        value: this.valueDisease,
        title: DATASETS.DISEASES,
      },
    ];

    return nameDV;
  }

  getNameDVG(identifier) {
    const nameDV = [
      {
        name: `${this.variantStrID}${this.geneSymbol ? `, ${this.geneSymbol}` : ''}`,
        value: this.variantStrID,
        title: DATASETS.VARIANTS,
      },
      {
        name: this.diseaseName + ', ' + this.diseaseUMLSCUI,
        value: this.valueDisease,
        title: DATASETS.DISEASES,
      },
    ];

    if (identifier === DATASETS.GENES) {
      nameDV.push({
        name: this.geneSymbol + ', ' + this.geneDescription,
        value: this.geneNcbiIDs,
        title: DATASETS.GENES,
      });
    }

    return nameDV;
  }

  getNameVG(identifier) {
    const nameVG = [
      {
        name: `${this.variantStrID}${this.geneSymbol ? `, ${this.geneSymbol}` : ''}`,
        value: this.variantStrID,
        title: DATASETS.VARIANTS,
      },
    ];

    if (identifier === DATASETS.GENES) {
      nameVG.push({
        name: this.geneSymbol + ', ' + this.geneDescription,
        value: this.geneNcbiIDs.length > 1 ? this.geneNcbiIDs : this.geneNcbiIDs[0],
        title: DATASETS.GENES,
      });
    }

    return nameVG;
  }
}

export { VDAModel, VDATable };
