function updateParams({ view = null, searchItems = null, source = null, tab = null }) {
  const searchParams = new URLSearchParams(location.search);

  if (view) searchParams.set('view', view);
  if (source) searchParams.set('source', source);
  if (tab) searchParams.set('tab', tab);
  if (searchItems) searchParams.set('idents', searchItems.map(item => item.id).join('-'));
  return searchParams.toString();
}

export default updateParams;
