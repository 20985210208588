import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

class Nav extends React.Component {
  render() {
    return (
      <nav id="cd-vertical-nav">
        <ul className="list-unstyled mt-4 horizontal-menu">
          <li className="nav-item">
            <Link smooth to="/Privacy-policy#section1">
              <span className="cd-title">1. Data crontoller</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link smooth to="/Privacy-policy#section2">
              <span className="cd-title">2. Data collected and its purposes</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link smooth to="/Privacy-policy#section3">
              <span className="cd-title">3. Legal Basis</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link smooth to="/Privacy-policy#section4">
              <span className="cd-title">4. Data Disclosure</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link smooth to="/Privacy-policy#section5">
              <span className="cd-title">5. International Transfer</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link smooth to="/Privacy-policy#section6">
              <span className="cd-title">6. Data Retention</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link smooth to="/Privacy-policy#section7">
              <span className="cd-title">7. Security Measures</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link smooth to="/Privacy-policy#section8">
              <span className="cd-title">8. Your Rights</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link smooth to="/Privacy-policy#section9">
              <span className="cd-title">9. General</span>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Nav;
