import { API_URL } from '../Components/Constants';

export default function fetchDownload(endpoint, authorization) {
  const headers = {
    accept: 'application/csv',
    Authorization: authorization,
  };
  const url = `${API_URL}${endpoint}`;

  return fetch(url, { headers }).then(response => response.blob());
}
