// todo: delete
const examplesKey = 'unauth';

const DATASETS = {
  DISEASES: 'DISEASES',
  GENES: 'GENES',
  VARIANTS: 'VARIANTS',
  CHEMICALS: 'CHEMICALS',
  ALL: 'ALL', //todo: used?
  DDA: 'DDA',
  GDA: 'GDA',
  VDA: 'VDA',
};

const API_URL = process.env.REACT_APP_URL_BACKEND_API;

// AWS Role: Role Displayed
const USER_ROLES_AWS_MAP = new Map([
  ['ROLE_TRIAL', 'TRIAL'],
  ['ROLE_ACADEMIC', 'ACADEMIC'],
  ['ROLE_STANDARD', 'STANDARD'],
  ['ROLE_PROFESSIONAL', 'ADVANCED'],
  ['ROLE_UNLIMITED', 'PREMIUM'],
  ['ROLE_DEVELOPER', 'DEVELOPER'],
  // We leave ACADEMIC_REVIEW out of the map,
  // it is only used by cognito to track the review process
  // those users use the app as either TRIAL or ACADEMIC
]);

// todo: refactor away, but still used in Modals
const CUSTOM_STYLE = {
  content: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-57%)',
    marginLeft: 'auto',
    marginRight: 'auto',
    // width: '600px',
    maxWidth: '600px',
    background: 'transparent',
    boxSizing: 'border-box',
    border: null,
    padding: null,
  },
};

// These were initially used to check (in a test) that every grid had a column list defined
// with the addition of custom mappings, this type of test was retired
// const GRID_VIEWS = [
//   'GENES_ALL',
//   'GENES_GDA_SUMMARY',
//   'GENES_GDA_EVIDENCE',
//   'GENES_VDA_SUMMARY',
//   'GENES_VDA_EVIDENCE',
//   'DISEASES_ALL',
//   'DISEASES_GDA_SUMMARY',
//   'DISEASES_GDA_EVIDENCE',
//   'DISEASES_VDA_SUMMARY',
//   'DISEASES_VDA_EVIDENCE',
//   'DISEASES_DDA_SUMMARY', // only association view without evidences
//   'VARIANTS_ALL',
//   'VARIANTS_VDA_SUMMARY',
//   'VARIANTS_VDA_EVIDENCE',
//   'CHEMICALS_ALL',
//   'CHEMICALS_GDA_SUMMARY',
//   'CHEMICALS_GDA_EVIDENCE',
//   'CHEMICALS_VDA_SUMMARY',
//   'CHEMICALS_VDA_EVIDENCE',
//   'GDA_ALL_SUMMARY',
//   'GDA_ALL_EVIDENCE',
//   'VDA_ALL_SUMMARY',
//   'VDA_ALL_EVIDENCE',
// ];

export const getTitle = foundTitle => {
  return getProperty(foundTitle, ['name', 'symbol', 'strID', 'chemShowName']);
};

export const getName = foundTitle => {
  return getProperty(foundTitle, ['diseaseUMLSCUI', 'description', 'geneSymbol', 'id']);
};

export const getId = foundTitle => {
  return getProperty(foundTitle, ['id', 'geneID', 'strID', 'id']);
};

const getProperty = (foundTitle, properties) => {
  const foundProperty = properties.find(prop => foundTitle && foundTitle[prop]);
  return foundProperty ? foundTitle[foundProperty] : '';
};

export { examplesKey, DATASETS, API_URL, USER_ROLES_AWS_MAP, CUSTOM_STYLE };
