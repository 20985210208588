import { GDAModel } from '../../../../Models/GDAModel';
import { VDAModel } from '../../../../Models/VDAModel';
import { DDAModel } from '../../../../Models/DDAModel';
import { wholeNumberParams } from '../Common/Constants';

// information needed for constructing the NetworkChart, depending on the tab selected
const uniqueIdentViewsVariables = {
  GDA: {
    model: GDAModel,
    mappingIdents: {
      DISEASES: {
        main: ['diseaseUMLSCUI', 'diseaseName'],
        secondary: ['geneNcbiID', 'geneSymbol'],
      },
      GENES: {
        main: ['geneNcbiID', 'geneSymbol'],
        secondary: ['diseaseUMLSCUI', 'diseaseName'],
      },
    },
    identList: {
      DISEASES: ['geneNcbiID'],
      GENES: ['diseaseUMLSCUI'],
    },
    secondReqIdent: {
      DISEASES: 'gene_ncbi_id',
      GENES: 'disease',
    },
  },
  VDA: {
    model: VDAModel,
    mappingIdents: {
      DISEASES: {
        main: ['diseaseUMLSCUI', 'diseaseName'],
        secondary: ['variantStrID', 'variantStrID'],
      },
      GENES: {
        main: ['diseaseUMLSCUI', 'diseaseName'],
        secondary: ['variantStrID', 'variantStrID'],
      },
      VARIANTS: {
        main: ['variantStrID', 'variantStrID'],
        secondary: ['diseaseUMLSCUI', 'diseaseName'],
      },
    },
    identList: {
      DISEASES: ['variantStrID'],
      GENES: ['variantStrID', 'diseaseUMLSCUI'],
      VARIANTS: ['diseaseUMLSCUI'],
    },
    secondReqIdent: {
      DISEASES: 'variant',
      GENES: ['variant', 'disease'],
      VARIANTS: 'disease',
    },
  },
  DDA: {
    model: DDAModel,
    mappingIdents: {
      DISEASES: {
        main: ['disease1_UMLSCUI', 'disease1_Name'],
        secondary: ['disease2_UMLSCUI', 'disease2_Name'],
      },
    },
    identList: {
      DISEASES: ['disease2_UMLSCUI'],
    },
    secondReqIdent: {
      DISEASES: 'disease2',
    },
  },
};

// general settings for network chart
const networkGeneralSettings = {
  minZoom: 0.5,
  maxZoom: 3,
  style: [
    {
      selector: '.hasLabel',
      css: {
        label: ele => {
          return ele.data('label');
        },
        color: 'rgb(190,0,128)',
        fontSize: '18px',
        'font-weight': 'bold',
        'z-index': '15',
      },
    },
    {
      selector: '.disease',
      style: {
        'background-color': 'rgb(50,63,87)',
      },
    },
    {
      selector: '.gene',
      style: {
        'background-color': 'rgb(190,0,128)',
      },
    },
    {
      selector: '.variant',
      style: {
        'background-color': 'rgb(190,0,128)',
      },
    },
    {
      selector: 'node',
      style: {
        label: 'data(label)',
        'z-index': '10',
        // Text props
        color: 'black',
        fontSize: '14px',
        'font-weight': 'bold',
      },
    },
    {
      selector: "node[type='main-node']",
      style: {
        width: 50,
        height: 50,
      },
    },
    {
      selector: "node[type='secondary-node']",
      style: {
        width: 25,
        height: 25,
      },
    },
    {
      selector: 'edge',
      style: {
        width: 1,
        'curve-style': 'bezier',
        'line-cap': 'round',
      },
    },
  ],
};

// structure of a cytoscape node
class CytoscapeNode {
  constructor(nodeData, classes) {
    this.data = {
      id: nodeData.id,
      label: nodeData.label,
      type: nodeData.type,
    };
    this.classes = classes;
  }
}

// structure of a cytoscape edge
class CytoscapeEdge {
  constructor(edgeData, styleInfo) {
    this.data = {
      source: edgeData.source,
      target: edgeData.target,
      id: edgeData.id,
      label: edgeData.label,
    };
    this.style = {
      width: styleInfo.width,
    };
  }
}

// logic for mapping the data from backend into a cytoscape chart
function getReversedCurrEdge(currEdge) {
  const currEdgeArray = currEdge.split('_');
  return `${currEdgeArray[1]}_${currEdgeArray[0]}`;
}

function checkIfRelationExists(cyEdges, currEdge) {
  const filteredEdges = cyEdges.filter(edge => {
    const edgeIdent = edge.data.id;
    const reverseCurrEdge = getReversedCurrEdge(currEdge);

    return edgeIdent === currEdge || edgeIdent === reverseCurrEdge;
  });

  return filteredEdges;
}

function getMaxEdgeThickness(
  data,
  isAttributeWholeNumber,
  sortingVariable,
  pagingInfo,
  setMaxEdgeThickness,
  currMaxEdgeThickness,
) {
  if (pagingInfo.currentPageNumber === 0) {
    const maxEdgeThickness = isAttributeWholeNumber ? data[0][sortingVariable] : 1;

    setMaxEdgeThickness(maxEdgeThickness);
    return maxEdgeThickness;
  } else {
    return currMaxEdgeThickness;
  }
}

function getEdgeWidth(sortingVariable, value, maxThickness) {
  if (value === null || value === 0) {
    return null;
  }

  if (!wholeNumberParams.includes(sortingVariable)) {
    return `${value * 5}px`;
  } else {
    return `${Math.ceil((value / maxThickness) * 5)}px`;
  }
}

const variableNameMap = new Map([
  ['score', 'Score'],
  ['numPMIDsupportingAssociation', 'N. PMID'],
  ['EI', 'EI'],
  ['jaccard_genes', 'JI genes'],
  ['jaccard_variants', 'JI variants'],
  ['shared_genes', 'N. Shared Genes'],
  ['shared_variants', 'N. Shared Variants'],
]);

function getNodeClasses(identInfo) {
  let nodeClass = '';

  if (identInfo.includes('disease')) nodeClass = 'disease';
  if (identInfo.includes('gene')) nodeClass = 'gene';
  if (identInfo.includes('variant')) nodeClass = 'variant';

  return nodeClass;
}

function getNetworkMappingValues(modelData, mappingVariables, sortingVariable, maxEdgeThickness) {
  const mainNodeIdent = modelData[mappingVariables.main[0]].toString();
  const mainNodeLabel = modelData[mappingVariables.main[1]];
  const mainNodeClasses = getNodeClasses(mappingVariables.main[0]);
  const secondaryNodeIdent = modelData[mappingVariables.secondary[0]].toString();
  const secondaryNodeLabel = modelData[mappingVariables.secondary[1]];
  const secondaryNodeClasses = getNodeClasses(mappingVariables.secondary[0]);
  const sortingVariableValue = modelData[sortingVariable];
  const edgeWidth = getEdgeWidth(sortingVariable, sortingVariableValue, maxEdgeThickness);
  const edgeId = `${mainNodeIdent}_${secondaryNodeIdent}`;
  const edgeLabel = `${variableNameMap.get(sortingVariable)}: ${sortingVariableValue}`;

  return {
    mainNodeIdent,
    mainNodeLabel,
    mainNodeClasses,
    secondaryNodeIdent,
    secondaryNodeLabel,
    secondaryNodeClasses,
    edgeWidth,
    edgeId,
    edgeLabel,
  };
}

function getNetworkData(
  data,
  mappingVariables,
  viewModel,
  sortingVariable,
  currPagingInfo,
  setMaxEdgeThickness,
  currMaxEdgeThickness,
  names,
  parent,
) {
  const cytoscapeNodes = [];
  const cytoscapeEdges = [];

  const isAttributeWholeNumber = wholeNumberParams.includes(sortingVariable);
  const maxEdgeThickness = getMaxEdgeThickness(
    data,
    isAttributeWholeNumber,
    sortingVariable,
    currPagingInfo,
    setMaxEdgeThickness,
    currMaxEdgeThickness,
  );
  const mainNodeIds = names.map(name => {
    if (name.title === 'DISEASES') return name.value.replace('UMLS_', '');
    if (name.title === 'GENES' || name.title === 'VARIANTS') return name.value;
  });

  data.map(row => {
    const modelData = new viewModel(row);
    const {
      mainNodeIdent,
      mainNodeLabel,
      mainNodeClasses,
      secondaryNodeIdent,
      secondaryNodeLabel,
      secondaryNodeClasses,
      edgeWidth,
      edgeId,
      edgeLabel,
    } = getNetworkMappingValues(modelData, mappingVariables, sortingVariable, maxEdgeThickness);

    if (!edgeWidth) {
      return;
    }

    // check if mainNode exists and create it if it doesn't
    let isMainNode = true;
    // if (parent === 'DDA') {
    //   isMainNode = mainNodeIds.includes(mainNodeIdent);
    // }

    if (isMainNode) {
      const currentMainNode = cytoscapeNodes.filter(node => node.data.id === mainNodeIdent);
      if (currentMainNode.length === 0) {
        cytoscapeNodes.push(
          new CytoscapeNode({ id: mainNodeIdent, label: mainNodeLabel, type: 'main-node' }, mainNodeClasses),
        );
      }
    }

    // check if secondaryNode exists and create it if it doesn't
    let isSecondaryNode = true;
    // if (parent === 'DDA') {
    //   isSecondaryNode = !mainNodeIds.includes(secondaryNodeIdent);
    // }

    if (isSecondaryNode) {
      const currentSecondaryNode = cytoscapeNodes.filter(node => node.data.id === secondaryNodeIdent);
      if (currentSecondaryNode.length === 0) {
        cytoscapeNodes.push(
          new CytoscapeNode(
            {
              id: secondaryNodeIdent,
              label: secondaryNodeLabel,
              type: 'secondary-node',
            },
            secondaryNodeClasses,
          ),
        );
      }
    }

    // create edge relation between disease and gene
    const currentEdge = new CytoscapeEdge(
      {
        source: mainNodeIdent,
        target: secondaryNodeIdent,
        id: edgeId,
        label: edgeLabel,
      },
      { width: edgeWidth },
    );
    if (checkIfRelationExists(cytoscapeEdges, edgeId).length === 0) {
      cytoscapeEdges.push(currentEdge);
    }
  });

  const cytoscapeData = { nodes: cytoscapeNodes, edges: cytoscapeEdges };

  return cytoscapeData;
}

function unifyNetworkObj(ogNetworkObj, newNetworkObj) {
  if (ogNetworkObj.nodes.length === 0) {
    return newNetworkObj;
  }

  const newNodes = [...ogNetworkObj.nodes, ...newNetworkObj.nodes];
  const filteredNewNetworkEdges = newNetworkObj.edges.filter(edge => {
    const filteredEdges = checkIfRelationExists(ogNetworkObj.edges, edge.data.id);

    return filteredEdges.length === 0 && edge;
  });
  const newEdges = [...ogNetworkObj.edges, ...filteredNewNetworkEdges];

  return { nodes: newNodes, edges: newEdges };
}

export { uniqueIdentViewsVariables, getNetworkData, networkGeneralSettings, unifyNetworkObj };
