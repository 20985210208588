import React, { useState, useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import { searchContext, userAppContext } from '../../Context/Context.jsx';
import ManageGrid from '../Search/ManageGrid.jsx';

import { Columns } from '../Table/Columns.jsx';

function MappingResults({ mappingContent }) {
  const { view, source, tab, searchItems, setSearchItems } = searchContext();
  const { restrictGraphDisplay } = userAppContext();
  const [isInfoSidebarOpen, setIsInfoSidebarOpen] = useState(false);

  // metadata configuration
  const title = Columns.find(c => c.name === mappingContent.name)?.header;

  // Grid view items
  // Mapping results are hardcoded to true
  // Display is governed by whether an summary|evidence configuration is defined in columnVisibilityModels
  const showSummary = true;
  const showEvidence = true;

  useEffect(() => {
    scroller.scrollTo('MappingSummary', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  }, [mappingContent]);

  return (
    <>
      <div className="titEtiqueta pt-12">
        <span>{title} Mapping</span>
      </div>
      <Element name="MappingSummary">
        {showSummary && mappingContent?.mappingItems && (
          <ManageGrid
            view={view}
            source={source}
            tab={tab}
            searchItems={mappingContent?.mappingItems}
            setSearchItems={setSearchItems}
            isEvidence={false}
            isMapping={true}
            isInfoSidebarOpen={isInfoSidebarOpen}
            setIsInfoSidebarOpen={setIsInfoSidebarOpen}
          />
        )}
      </Element>
      <Element name="MappingEvidence">
        {showEvidence && (
          <ManageGrid
            view={view}
            source={source}
            tab={tab}
            searchItems={mappingContent?.mappingItems}
            setSearchItems={setSearchItems}
            isEvidence={true}
            isMapping={true}
            setIsInfoSidebarOpen={setIsInfoSidebarOpen}
            isInfoSidebarOpen={isInfoSidebarOpen}
          />
        )}
      </Element>
    </>
  );
}
export default MappingResults;
