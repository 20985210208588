import React, { Fragment, useState, useEffect } from 'react';
import RenderInfoLogic from '../InfoSidebar/RenderInfoLogic';
import { SourceButton } from './Buttons.jsx';
import SelectGraphButton from './SelectGraphButton';
import RenderSourcesLogic from './RenderSourcesLogic.jsx';
// todo: rename this function
export function areSearchesMultiple(names, title, ident) {
  return names.length > 1 || (names.length === 1 && title === 'SummaryVDA' && ident === 'GENES');
}

function ManageGraphs({
  sectionTitle,
  tab,
  view,
  source,
  names, // todo: rename to searchItems
  searchItems,
  title, // todo: better title? ala sectionTitle
  Graph = null,
  removeFilter, // todo: not passed, refactor away
  setIsInfoSidebarOpen,
  setSearchItems,
}) {
  const [sourceOpen, setSourceOpen] = useState(false);

  let keySearch = '';

  if (searchItems.length > 0) {
    let prefix = '';
    if (title === 'DISEASES') {
      prefix = 'disease=';
      keySearch = `${prefix}${searchItems.map(item => `UMLS_${item.id}`).join(',')}`;
    } else if (title === 'GENES') {
      prefix = 'gene_ncbi_id=';
      keySearch = `${prefix}${searchItems.map(item => item.id).join(',')}`;
    } else if (title === 'VARIANTS') {
      prefix = 'variant=';
      keySearch = `${prefix}${searchItems.map(item => item.id).join(',')}`;
    }
  }

  // todo: document this chart selection logic
  const defaultChart =
    searchItems.length > 1 || (names.length === 1 && title === 'SummaryVDA' && title === 'GENES')
      ? 'HEATMAP'
      : 'LINE';
  const [selectedChart, setSelectedChart] = useState(defaultChart);

  // useEffect(() => {
  //   if (!(searchItems.length > 1 || (names.length === 1 && title === 'SummaryVDA' && title === 'GENES'))) {
  //     setSelectedChart('LINE');
  //   }
  // }, [searchItems]);

  useEffect(() => {
    if (searchItems.length > 1 || (names.length === 1 && title === 'SummaryVDA' && title === 'GENES')) {
      setSelectedChart('HEATMAP');
    } else {
      setSelectedChart('LINE');
    }
  }, [searchItems, names, title]);

  return (
    <Fragment>
      <div className="Cabtabla mt-3">
        {names && (
          <div className="row">
            <RenderInfoLogic
              searchItems={searchItems}
              setSearchItems={setSearchItems}
              setIsInfoSidebarOpen={setIsInfoSidebarOpen}
            />
            <div className="col-4 text-right pr-3">
              <div className=" justify-content-end">
                <SourceButton source={source} setSourceOpen={setSourceOpen} />
              </div>
              <div className="justify-content-end">
                <SelectGraphButton
                  selectedChart={selectedChart}
                  setSelectedChart={setSelectedChart}
                  names={names}
                  sectionTitle={sectionTitle}
                  title={title}
                  view={view}
                  setSearchItems={setSearchItems}
                />
              </div>
            </div>
            <div>{sourceOpen && <RenderSourcesLogic tab={tab} />}</div>
          </div>
        )}
      </div>

      {Graph && (
        <Graph
          source={source}
          tab={tab}
          keySearch={keySearch}
          view={view}
          names={names}
          selectedChart={selectedChart}
          setSearchItems={setSearchItems}
        />
      )}
    </Fragment>
  );
}

export default ManageGraphs;
