import CommonHeader from '../Components/Headers/CommonHeader';
import Footer from '../Components/Home/Footer';
import Biomarkers from '../Components/Biomarkers/Biomarkers';

export default function BiomarkersPage() {
  return (
    <>
      <CommonHeader />
      <Biomarkers />
      <Footer />
    </>
  );
}
