function getIdentifiersList(data, identifiers) {
  const identsString = [];

  for (let ident of identifiers) {
    const identsList = new Set(
      data?.map(row => {
        const prefix = ident.includes('UMLSCUI') ? 'UMLS_' : '';
        return `${prefix}${row[ident]}`;
      }),
    );
    identsString.push([...identsList].join(','));
  }

  return identsString;
}

function getSecondUrl(baseUrl, source, keySearch, identParam, identReqParam, pagingInfo, orderByParam) {
  let url2;

  if (Array.isArray(identParam)) {
    const searchParamsArray = identParam.map((param, index) => {
      return `${param}=${identReqParam[index]}`;
    });
    const searchParamsString = searchParamsArray.join('&');

    url2 = `${baseUrl}?source=${source}&${searchParamsString}&page_number=${pagingInfo.currentPage}&page_size=${100}&order_by=${orderByParam}&order=desc`;
  } else {
    url2 = `${baseUrl}?source=${source}&${keySearch}&${identParam}=${identReqParam}&page_number=${pagingInfo.currentPage}&page_size=${100}&order_by=${orderByParam}&order=desc`;
  }

  return url2;
}

function getRemainingPages(pageInfo) {
  const condition1 =
    pageInfo?.totalElements - pageInfo?.totalElementsInPage * (pageInfo?.currentPageNumber + 1);
  const condition2 = Math.floor(
    pageInfo?.totalElements / (pageInfo?.pageSize * (pageInfo?.currentPageNumber + 1)),
  );

  return condition1 === 0 ? 0 : condition2;
}

export { getIdentifiersList, getSecondUrl, getRemainingPages };
