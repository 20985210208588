import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { posts, generateContent } from './Posts';

const BlogPostDetails = ({ post }) => {
  if (!post) {
    return <div className="container">Post not found.</div>;
  }

  console.log(post.title, post.tags);

  return (
    <div className="container pt-4 pb-4">
      <Helmet>
        <title>{posts.title}</title>
        <meta name="description" content={posts.description} />
        <meta name="keywords" content={posts.tags?.join(', ')} />
        <meta property="og:title" content={posts.title} />
        <meta property="og:description" content={posts.description} />
        <meta property="og:image" content={post.image} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="post-preview">{generateContent(post)}</div>
    </div>
  );
};

export { BlogPostDetails };
