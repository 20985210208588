import React from 'react';
import { Helmet } from 'react-helmet-async';
import CommonHeader from '../Components/Headers/CommonHeader';
import PricePlan from '../Components/Pricings/PricePlanes';
import Footer from '../Components/Home/Footer';

function Plans() {
  return (
    <React.Fragment>
      <Helmet>
        <title>DISGENET: FIND YOUR PERFECT PLAN</title>
        <meta
          name="description"
          content="Accelerate Your Research With The Most Reliable & Extensive Database."
        />
        <link rel="canonical" href="/plans" />
      </Helmet>
      <CommonHeader />
      <PricePlan />
      <Footer />
    </React.Fragment>
  );
}

export default Plans;
