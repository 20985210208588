import { useState } from 'react';

import { userAppContext } from '../../Context/Context';

function UpgradePlan({ showUpgradeModal, setShowUpgradeModal }) {
  const [emailSentMessage, setEmailSentMessage] = useState('');
  const { user } = userAppContext();

  async function submitMessageHandler(event) {
    event.preventDefault();
    try {
      setEmailSentMessage('Your email was successfully sent!');
    } catch (e) {
      setEmailSentMessage(
        'There was an error with email submission. Please try again or contact us by email at info@disgenet.com',
      );
    }
  }
  const handleCloseUpgrade = () => {
    setShowUpgradeModal(false);
  };

  //TODO
  return (
    <>
      {showUpgradeModal && (
        <div className="upgrade-plan-container">
          <div className="border2-container p-4">
            <div className="d-flex justify-content-end">
              <button className="bi bi-x" onClick={handleCloseUpgrade}></button>
            </div>
            <div className="d-flex justify-content-center pb-3">
              <h2 className="p-3">Upgrade Your Plan</h2>
            </div>
            <form onSubmit={submitMessageHandler}>
              <div className={`wrap-input100  pb-3`}>
                <span className={`label-input100 ${'nameClassName'}`}>Name: {user['custom:name']}</span>
              </div>
              <div className="wrap-input100  pb-3">
                <span className={`label-input100 ${'emailClassName'}`}>Email: {user['email']}</span>
              </div>
              <div className="wrap-input100  pb-3">
                <span className={`label-input100 ${'titleClassName'}`}>Subject: Upgrade Plan to</span>
                <select>
                  <option value="Basic">Basic</option>
                  <option value="Standard">Standard</option>
                  <option value="Advanced">Advanced</option>
                  <option value="Premium">Premium</option>
                </select>
              </div>
              <div className="container-contact100-form-btn">
                <div className="wrap-contact100-form-btn">
                  <div className="contact100-form-bgbtn" />
                  <button type="submit" className="contact100-form-btn">
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default UpgradePlan;
