import React from 'react';

function Feature() {
  return (
    <div>
      <div className="row justify-content-center">
        {/* <FeatureItem
          imageUrl="https://via.placeholder.com/200"
          title="<strong>48M+ Associations</strong>"
          text="Access a curated collection of gene-disease links."
        /> */}
        <FeatureItem
          imageUrl="../../static/images/icons/iconsPink/040.svg"
          title="<strong>F-Score 92%</strong>"
          text="Leverage the most reliable and accurate data available in the field."
        />
        <FeatureItem
          imageUrl="../../static/images/icons/iconsPink/011.svg"
          title="<strong>30M+ Articles</strong>"
          text="Access information comparable to reading 30 million articles."
        />
        <FeatureItem
          imageUrl="../../static/images/icons/iconsPink/043.svg"
          title="<strong>5.5K+ Citations</strong>"
          text="Trusted by leading researchers and institutions globally."
        />
        {/* <FeatureItem
          imageUrl="https://via.placeholder.com/200"
          title="<strong>Quarterly Updates</strong>"
          text="tay ahead of the curve with the latest data releases every quarter."
        /> */}
      </div>
    </div>
  );
}

function FeatureItem({ imageUrl, title, text }) {
  return (
    <div className="benefits-content benefits-background">
      <div className="benefits-img">
        <img src={imageUrl} alt="Imagem" />
      </div>
      <div className="benefits-div">
        <p className="benefits-tilte" dangerouslySetInnerHTML={{ __html: title }} />
        <p className="benefits-text">{text}</p>
      </div>
    </div>
  );
}

export default Feature;
