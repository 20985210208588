import React from 'react';

export default function PathwayDiagramList({
  diagramTitleRef,
  geneSymbol,
  pathways,
  selectedPathway,
  onSelectPathway
}) {
  const selectPathway = (pathway) => {
    diagramTitleRef.current?.scrollIntoView({ behavior: 'smooth' });
    onSelectPathway(pathway);
  };

  return (
    <table className="table pathways-list">
      <thead>
        <tr>
          <th className="w-50" scope="col">
            Name
          </th>
          <th className="w-50" scope="col">
            Code
          </th>
        </tr>
      </thead>
      <tbody>
        {pathways.map((pathway) => {
          return (
            <tr
              className={`pathway-item ${
                pathway.identifier === selectedPathway.identifier && 'active'
              }`}
              key={pathway.identifier}
            >
              <td onClick={() => selectPathway(pathway)} scope="row">
                {pathway.name}
              </td>
              <td>
                <a
                  href={`https://reactome.org/PathwayBrowser/#/${pathway.identifier}&FLG=${geneSymbol}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {pathway.identifier} <i className="fa fa-external-link" aria-hidden="true" />
                </a>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
