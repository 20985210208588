import React from 'react';

const data = [
  {
    category: 'Genes',
    v23: 25869,
    v24: 25992,
    v241: 26090,
    v242: 2639,
    v243: 26484,
    TotalIncrease: 492,
    percentIncrease: 1.89,
  },
  // {
  //   category: "Total genes (considering variants)",
  //   v21: 29745,
  //   v22: 31597,
  //   v23: 32052,
  //   v24: 3242,
  //   TotalIncrease: 2397,
  //   percentIncrease: 8.1,
  // },
  {
    category: 'Diseases/traits',
    v23: 39242,
    v24: 39644,
    v241: 39797,
    v242: 39903,
    v243: 39922,
    TotalIncrease: 278,
    percentIncrease: 0.7,
  },
  {
    category: 'Variants',
    v23: 617724,
    v24: 616660,
    v241: 704086,
    v242: 719161,
    v243: 723970,
    TotalIncrease: 107310,
    percentIncrease: 17.4,
  },
  {
    category: 'Chemicals',
    v23: 3507,
    v24: 3530,
    v241: 3981,
    v242: 4032,
    v243: 4042,
    TotalIncrease: 512,
    percentIncrease: 14.5,
  },
  {
    category: 'GDAs',
    v23: 1729483,
    v24: 1770610,
    v241: 1793304,
    v242: 1934730,
    v243: 1943710,
    TotalIncrease: 173100,
    percentIncrease: 9.78,
  },
  {
    category: 'VDAs',
    v23: 1117115,
    v24: 1119130,
    v241: 1201698,
    v242: 1254874,
    v243: 1237183,
    TotalIncrease: 118053,
    percentIncrease: 10.55,
  },
  {
    category: 'Publications',
    v23: 1408123,
    v24: 1434206,
    v241: 1479286,
    v242: 1539056,
    v243: 1560237,
    TotalIncrease: 126031,
    percentIncrease: 8.79,
  },
];

function ReleasesTables() {
  return (
    <>
      <thead className="text-center">
        <tr>
          <th></th>
          <th colSpan="2" className="border-right">
            2023
          </th>
          <th colSpan="3" className="border-right">
            2024
          </th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <thead className="text-center">
        <tr>
          <th></th>
          <th className="border-right">V23</th>
          <th className="border-right">V24</th>
          <th className="border-right">V24.1</th>
          <th className="border-right">V24.2</th>
          <th className="border-right">V24.3</th>
          <th>Total increase (24.3 vs 24)</th>
          <th>% increase (24.3 vs 24)</th>
        </tr>
      </thead>
      <tbody className="text-center">
        {data.map((row, index) => (
          <tr key={index}>
            <td className="border-right">
              <strong>{row.category}</strong>
            </td>
            <td className="border-right">{row.v23}</td>
            <td className="border-right">{row.v24}</td>
            <td className="border-right">{row.v241}</td>
            <td className="border-right">{row.v242}</td>
            <td className="border-right">{row.v243}</td>
            <td>{row.TotalIncrease}</td>
            <td>{row.percentIncrease}</td>
          </tr>
        ))}
      </tbody>
    </>
  );
}

export default ReleasesTables;
