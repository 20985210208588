import React, { useState, useEffect } from 'react';

function Testimony() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const testimonials = [
    {
      text: 'When exploring the DNA methylation of Cri du Chat patients, DISGENET helped me find interesting connections between genes with DNA methylation changes in the promoter and disease phenotypes. This analysis was suggestive of the DNA methylation itself having a role in developing the characteristics of this understudied rare disease.',
      name: 'Petter Holland - ',
      company: 'PhD, Researcher at Oslo University Hospital, Norway',
      article: 'https://clinicalepigeneticsjournal.biomedcentral.com/articles/10.1186/s13148-022-01350-3',
    },
    {
      text: 'We have unraveled intricate relationships between genes, diseases, and other biological entities. Beyond validating our hypotheses, DISGENET has opened new avenues for exploration, unveiling statistical enrichments and offering insights into disease mechanisms until now unknown. This newfound understanding not only fuels our scientific curiosity but also holds promise for clinical applications and therapeutic interventions.',
      name: 'Maria F. Anglero - ',
      company: 'Research Assistant, Pennsylvania State University',
    },
    {
      text: 'We use DISGENET to search for variants that cause rare diseases. The most difficult part of variant searching is finding appropriate references and presenting evidence, and DISGENET’s large and well-structured database makes it easy to find.',
      name: 'June-Young Koh - ',
      company: 'Chief Research Officer & Head of RARE TFT, Inocras',
    },
    {
      text: 'DisGeNET has played a significant role in our efforts to identify genetic variants associated with autism from WES data. It helped us to focus our search on genes linked to neurodevelopmental disorders and autism comorbidities and prioritize variants using DisGeNET is GDA scores and integrate them into our scoring system, aiming at prioritizing autism-related variants for further investigation.',
      name: 'Nadav Golden, Bioinformatics - ',
      company:
        'Azrieli National Centre for Autism and Neurodevelopment Research, Ben-Gurion University of the Negev (BGU)',
    },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    }, 20000);

    return () => clearInterval(intervalId);
  }, [testimonials.length]);

  const handlePrevTestimony = () => {
    setCurrentIndex(prevIndex => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  const handleNextTestimony = () => {
    setCurrentIndex(prevIndex => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <>
      <div className="testimony">
        <button className="carousel-control prev" onClick={handlePrevTestimony}>
          &lt;
        </button>
        <blockquote>
          <div>
            <div>
              <span className="quote-left">&ldquo;</span>
              <h6 className="px-4">{testimonials[currentIndex].text}</h6>
            </div>
            <span className="quote-right">&rdquo;</span>

            <div className="testimony-info">
              <p>
                <strong>{testimonials[currentIndex].name}</strong> {testimonials[currentIndex].company}
              </p>
              {testimonials[currentIndex].article && (
                <a href={testimonials[currentIndex].article} target="_blank" rel="noopener noreferrer">
                  Article <i className="bi-box-arrow-in-up-right"></i>
                </a>
              )}
            </div>
          </div>
        </blockquote>
        <button className="carousel-control next" onClick={handleNextTestimony}>
          &gt;
        </button>
      </div>
    </>
  );
}

export default Testimony;
